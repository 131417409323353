import axios from "axios";
import { useHistory, useParams } from "react-router-dom";
import React, { useState, useEffect } from "react";
import useStateRef from "react-usestateref";
import moment from "moment";



// Mui meterial component import
import { Container, Grid } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
// import { Document, Page, pdfjs } from "react-pdf";

import './Report.css';
import HeaderMasterComponent from "../Header/HeaderMaster";
import vector from '../../assets/Doctor_web_Icon/Vector.png'
import BackIcon from "../../assets/Doctor_Images/Group 31.svg";



const ReportViewComponent = () => {

    let Var_History = useHistory();
    let { Id } = useParams();
    let { Time } = useParams();
    let { items } = useParams();
    const [, setVar_PatientReport, Var_PatientReport_Ref] = useStateRef([]);
    const [, setVar_PatientReportLink, Var_PatientReportLink_Ref] = useStateRef("");
    const [, setVar_PatientReportName, Var_PatientReportName_Ref] = useStateRef("");
    const [, setVar_PatientReportDate, Var_PatientReportDate_Ref] = useStateRef("");
    const [loading, setLoading] = useState(true);
    const [, setVar_prescriptionDetails] = useStateRef([]);
    const [, setVar_viewpdfFile] = useStateRef(null);




    const navigateTo = (path) => { Var_History.push("/dashboard/appointments" + path) };
    // initial function call start
    useEffect(() => {
        typeCheck()
    }, []);
    // initial function call End

    const typeCheck = () => {

        if (items == 3) {
            reportsByClinic()
        } else if (items == 2) {
            patientParticularReport()
        } else if (items == 1) {
            getPrescriptionReport()
        }

    }

    // Reports By Clinic Start
    const reportsByClinic = () => {

        setLoading(true);
        let data = {
            patientId: Id,
            doctorId: localStorage.getItem("Doctor_id"),
            clinicId: localStorage.getItem("Clinic_id"),
            frmreq: "clinic",
            dateBasedSorting: true,
            dateBasedSortingOrder: 'DESC',
            limit: 100,
            pageno: 1
        }
        axios
            .post("clinic/getReportsByClinic", data)
            .then((response) => {

                console.log("Reports By Clinic ", response.data.data[0].details)
                const filtereddata = response.data.data[0].details.filter(x => x.patient_id == Id && x.id == Time);
                console.log("Report View", filtereddata[0])
                setVar_PatientReportLink(filtereddata[0].report_filepath)
                setVar_PatientReportName(filtereddata[0].report_name)
                setVar_PatientReportDate(moment(filtereddata[0].uploaded_date).format("DD-MMM-YY"))
                setLoading(false);
            })
            .catch((error) => {
                setLoading(false);
                console.log(error);
            });
    }
    // Reports By Clinic End

    // Get Patient Particular Report start
    const patientParticularReport = () => {

        setLoading(true);
        let data = {
            book_date: localStorage.getItem("Pr_Date"),
            clinic_id: Number(localStorage.getItem("clinic_id")),
            doctor_id: Number(localStorage.getItem("doctor_id")),
            patient_id: Number(Id),
        }
        axios
            .post("doctor/getPatientParticularReport", data)
            .then((response) => {

                console.log("Patient Particular Report", response.data.data[0])
                setVar_PatientReport(response.data.data[0].patient_report.filter(x => x.test_time == Time))
                // setVar_PatientInformation(response.data.data[0].patient_info)
                setVar_PatientReportLink(Var_PatientReport_Ref.current[0].test_result)
                setVar_PatientReportDate(moment(Var_PatientReport_Ref.current[0].test_date).format("DD-MMM-YY"))
                setVar_PatientReportName(Var_PatientReport_Ref.current[0].test_name)

                console.log("perticular data", response.data.data[0].patient_report.filter(x => x.test_time == Time))
                console.log("perticular data list", Var_PatientReport_Ref.current[0].test_result)
                setLoading(false);
            })
            .catch((error) => {
                setLoading(false);
                console.log(error);
            });
        // setisOpenSuccessModel(!isOpenSuccessModel)
    }

    const getPrescriptionReport = () => {

        setLoading(true);
        let data = {
            doctorId: localStorage.getItem("doctor_id"),
            patientId: localStorage.getItem("patient_Id")
        }
        axios
            .post("doctor/getReportPrescriptionImage", data)
            .then((response) => {
                setVar_prescriptionDetails(response.data.data)
                let filteredData = response.data.data.filter(x => x.prescript_date == localStorage.getItem("Prescription_Date"))
                console.log("Prescription_Date", filteredData[0])
                setVar_PatientReportName(filteredData[0].prescript_file_name)
                setVar_PatientReportDate(moment(filteredData[0].prescript_date).format("YYYY-MM-DD"))
                setVar_PatientReportLink(filteredData[0].prescript_file_path)
                setLoading(false);
            })
            .catch((error) => {
                setLoading(false);
                console.log(error);
            });
    }

    const navigateToReport = (data) => {

        let patientId = localStorage.getItem("patient_Id")
        let bookingId = localStorage.getItem("booking_Id")
        let date = localStorage.getItem("Report_Date")
        navigateTo("/report/" + patientId + "/" + bookingId + "/" + date);
    }
    // Get Patient Particular Report End
    // moment(formattedDate).format("DD-MMM-YY")

    return (
        <div>
            {loading &&
                <div className="loader_body">
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                        <CircularProgress />
                    </Box>
                </div>
            }
            <div>
                <div>
                    {/* ================= Report sub header start ================= */}
                    <div>
                        <HeaderMasterComponent />
                    </div>
                    <div>

                        <div className="Navication-header">
                            <div>
                                <img src={BackIcon} onClick={() => navigateToReport()} /><label onClick={() => navigateToReport()}>Report</label>
                            </div>

                        </div>
                        {/* ================= Report sub header End ================= */}

                        <div className='workinghours_content'>

                            <Container>
                                <Grid container className='Historttitle'>
                                    <Grid item xs={6} align='end'>
                                        <p className='Reporttitle'>{Var_PatientReportName_Ref.current}</p>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <p className='Reporttime'>{Var_PatientReportDate_Ref.current}</p>
                                    </Grid>
                                </Grid>
                                <Grid container>
                                    <Grid item xs={12} align='center'>
                                        <div className='report_holder'>
                                            {Var_PatientReportLink_Ref.current.includes('mp4') &&
                                                <video src={Var_PatientReportLink_Ref.current != "" ? Var_PatientReportLink_Ref.current : vector} className="Reportimgs" controls autoplay />
                                            }
                                            {Var_PatientReportLink_Ref.current.includes('png') &&
                                                <img src={Var_PatientReportLink_Ref.current != "" ? Var_PatientReportLink_Ref.current : vector} className="Reportimgs" alt="" />
                                            }
                                            {Var_PatientReportLink_Ref.current.includes('jpg') &&
                                                <img src={Var_PatientReportLink_Ref.current != "" ? Var_PatientReportLink_Ref.current : vector} className="Reportimgs" alt="" />
                                            }
                                            {Var_PatientReportLink_Ref.current.includes('jpeg') &&
                                                <img src={Var_PatientReportLink_Ref.current != "" ? Var_PatientReportLink_Ref.current : vector} className="Reportimgs" alt="" />
                                            }
                                            {Var_PatientReportLink_Ref.current.includes('svg') &&
                                                <img src={Var_PatientReportLink_Ref.current != "" ? Var_PatientReportLink_Ref.current : vector} className="Reportimgs" alt="" />
                                            }

                                            {Var_PatientReportLink_Ref.current.includes('pdf') &&
                                                // <img src={Var_PatientReportLink_Ref.current != "" ? Var_PatientReportLink_Ref.current : vector} className="Reportimgs" />
                                                // <a href={Var_PatientReportLink_Ref.current != "" ? Var_PatientReportLink_Ref.current : vector}>Visit W3Schools.com!</a>
                                                // <iframe src={Var_PatientReportLink_Ref.current != "" ? Var_PatientReportLink_Ref.current : vector} ></iframe>
                                                <embed src={Var_PatientReportLink_Ref.current != "" ? Var_PatientReportLink_Ref.current : vector} type="application/pdf" height={800} width={500} />
                                                //    <div className="pdf-container">
                                                //             <Document file={Var_PatientReportLink_Ref.current != "" ? Var_PatientReportLink_Ref.current : vector} >
                                                //                 <Page pageNumber={1} />
                                                //             </Document>
                                                //         </div>
                                            }

                                            {/* <img src={Var_PatientReportLink_Ref.current} className='Reportimgs'></img>
                                        <iframe src={Var_PatientReportLink_Ref.current} className='Reportimgs'></iframe> */}
                                        </div>
                                    </Grid>
                                </Grid>
                            </Container>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}
export default ReportViewComponent;