// import React, { useState, useEffect } from "react";
// import { useHistory, useParams } from "react-router-dom";
// import Axios from "axios";
// import useStateRef from "react-usestateref";
// import { Container } from 'react-bootstrap';
// import Moment from 'moment';


// // Mui style importing start
// // Mui meterial component import
// import Grid from '@mui/material/Grid';
// import Card from '@mui/material/Card';
// import FormControl from "@mui/material/FormControl";
// import Select from "@mui/material/Select";
// import InputLabel from "@mui/material/InputLabel";
// import FormControlLabel from '@mui/material/FormControlLabel';
// import { MenuItem } from '@mui/material';
// import Button from '@mui/material/Button';
// import Dialog, { DialogProps } from '@mui/material/Dialog';
// import DialogContent from '@mui/material/DialogContent';
// import TextField from '@mui/material/TextField';
// import CircularProgress from '@mui/material/CircularProgress';
// import Box from '@mui/material/Box';
// import LinearProgress from '@mui/material/LinearProgress';

// import Walkin from '../../assets/Doctor_web_Icon/Walkin.svg'
// import successModelTickImg from '../../assets/Web_Doctor_Icon_new_theme/Success.svg'
// import Visit from '../../assets/Web_Doctor_Icon_new_theme/Member.svg'
// import Online from '../../assets/Web_Doctor_Icon_new_theme/Online.svg'
// import Fasttrack from '../../assets/Web_Doctor_Icon_new_theme/fast_track.svg'
// import InclinicOnline from '../../assets/Web_Doctor_Icon_new_theme/inclinic.svg'
// import Alert from "../../assets/Images/alert.svg";
// import './EditWorkingHours.css';

// import IOSSwitch from "../../helpers/Switch/Switch";
// import FooterMaster from '../Dashboard/FooterMaster'
// import { notification } from "antd";


// const EditWorkingHoursComponent = () => {
//     let { workingHoursId } = useParams();
//     let Var_History = useHistory();

//     const [isshowsuccess, setishiddensuccess] = useState(false);
//     const [fullWidth, setFullWidth] = React.useState(true);
//     const [Var_Id, setVar_Id, Var_Id_Ref] = useStateRef(null);
//     const [Var_ClinicId, setVar_ClinicId, Var_ClinicId_Ref] = useStateRef(null);
//     const [Var_DoctorId, setVar_DoctorId, Var_DoctorId_Ref] = useStateRef(null);
//     const [Var_FromTime, setVar_FromTime, Var_FromTime_Ref] = useStateRef(null);
//     const [Var_ToTime, setVar_ToTime, Var_ToTime_Ref] = useStateRef(null);
//     const [Var_SlotDuration, setVar_SlotDuration, Var_SlotDuration_Ref] = useStateRef(null);
//     const [Var_NoOfSlots, setVar_NoOfSlots, Var_NoOfSlots_Ref] = useStateRef(null);
//     const [Var_AppointTypeId, setVar_AppointTypeId, Var_AppointTypeId_Ref] = useStateRef(null);
//     const [Var_TotalCount, setVar_TotalCount, Var_TotalCount_Ref] = useStateRef(null);
//     const [Var_NextRowCount, setVar_NextRowCount, Var_NextRowCount_Ref] = useStateRef(null);
//     const [Var_AppointmentType, setVar_AppointmentType, Var_AppointmentType_Ref] = useStateRef(null);
//     const [Var_ServiceId, setVar_ServiceId, Var_ServiceId_Ref] = useStateRef(null);
//     const [Var_ServiceName, setVar_ServiceName, Var_ServiceName_Ref] = useStateRef(null);
//     const [Var_SpecialityName, setVar_SpecialityName, Var_SpecialityName_Ref] = useStateRef(null);
//     const [Var_SpecialityId, setVar_SpecialityId, Var_SpecialityId_Ref] = useStateRef(null);
//     const [Var_Days, setVar_Days, Var_Days_Ref] = useStateRef(null);
//     const [Var_CanEdit, setVar_CanEdit, Var_CanEdit_Ref] = useStateRef(null);
//     const [Var_ServiceList, setVar_ServiceList, Var_ServiceList_Ref] = useStateRef([]);
//     const [Var_AppointmentList, setVar_AppointmentList, Var_AppointmentList_Ref] = useStateRef([]);
//     const [Var_DoctorDetailShow, setVar_DoctorDetailShow, Var_DoctorDetailShow_Ref] = useStateRef(null);
//     const [loading, setLoading] = useState(true);

//     const [Var_SunValue, setVar_SunValue, Var_SunValue_Ref] = useStateRef(null);
//     const [Var_MonValue, setVar_MonValue, Var_MonValue_Ref] = useStateRef(null);
//     const [Var_TueValue, setVar_TueValue, Var_TueValue_Ref] = useStateRef(null);
//     const [Var_WedValue, setVar_WedValue, Var_WedValue_Ref] = useStateRef(null);
//     const [Var_ThuValue, setVar_ThuValue, Var_ThuValue_Ref] = useStateRef(null);
//     const [Var_FriValue, setVar_FriValue, Var_FriValue_Ref] = useStateRef(null);
//     const [Var_SatValue, setVar_SatValue, Var_SatValue_Ref] = useStateRef(null);
//     const [Var_AdvertisementMsg, setVar_AdvertisementMsg, Var_AdvertisementMsg_Ref] = useStateRef("");
//     const [Var_AdvertisementMsgHead, setVar_AdvertisementMsgHead, Var_AdvertisementMsgHead_Ref] = useStateRef("");
//     const [Var_PopupModelIcon, setVar_PopupModelIcon, Var_PopupModelIcon_Ref] = useStateRef("");
//     const [Var_doctorClinic, setVar_doctorClinic, Var_doctorClinic_Ref] = useStateRef(null);
//     const [Var_DoctorClinicSelect, setVar_DoctorClinicSelect, Var_DoctorClinicSelect_Ref] = useStateRef(null);

//     const [Var_doctorSpeciality, setVar_doctorSpeciality, Var_doctorSpeciality_Ref] = useStateRef(null);
//     const [Var_DoctorSpecialtySelect, setVar_DoctorSpecialtySelect, Var_DoctorSpecialtySelect_Ref] = useStateRef([]);
//     const [Var_SpecialtyName, setVar_SpecialtyName, Var_SpecialtyName_Ref] = useStateRef([]);
//     const [,setisisDisable,isDisable_Ref] = useStateRef(false);

//     // ================================== initial function call start ==================================
//     useEffect(() => {
//         // getClinicDoctor();
//         getIndividualWorkingHours();
//         getAppointmentType();
//         getdoctorSpeciality();
//         setisisDisable(false);
//         // getClinicWiseDoctor();
//     }, [])
//     // ================================== initial function call End ==================================

//     const handleOnClick = () => {
//         Var_History.push("/dashboard/workinghours/viewworkinghours")
//     };

//     // ================================== Get Individual working hours start ==================================
//     const getIndividualWorkingHours = () => {

//         setLoading(true);
//         var DoctorServiceBySpecilaity = {
//             doctorId: localStorage.getItem("Doctor_id"),
//             clinicId: localStorage.getItem("Clinic_id"),
//             limit: 100,
//             pageno: 1
//         }

//         Axios({ method: "POST", url: "doctor/getdocAppointmentSettings", data: DoctorServiceBySpecilaity, })
//             .then((response) => {
//                 debugger;
//                 var filteredSingleData = response.data.data[0].details.filter(x => x.id == workingHoursId);

//                 setVar_Id(filteredSingleData[0].id)
//                 setVar_ClinicId(filteredSingleData[0].clinicId)
//                 setVar_DoctorId(filteredSingleData[0].doctor_id)
//                 setVar_FromTime(filteredSingleData[0].from_time)
//                 setVar_ToTime(filteredSingleData[0].to_time)
//                 setVar_SlotDuration(filteredSingleData[0].slot_duration);
//                 setVar_NoOfSlots(filteredSingleData[0].no_of_slots)
//                 setVar_AppointTypeId(filteredSingleData[0].appointtype_id)
//                 setVar_TotalCount(filteredSingleData[0].total_count)
//                 setVar_NextRowCount(filteredSingleData[0].NextRowCount)
//                 setVar_AppointmentType(filteredSingleData[0].appointment_type)
//                 setVar_ServiceId(filteredSingleData[0].service_id)
//                 setVar_ServiceName(filteredSingleData[0].service_name)
//                 setVar_SpecialityName(filteredSingleData[0].speciality_name)
//                 setVar_SpecialtyName(filteredSingleData[0].speciality_name)
//                 setVar_SpecialityId(filteredSingleData[0].speciality_id)
//                 setVar_doctorSpeciality(filteredSingleData[0].speciality_id)
//                 // setVar_Days(filteredSingleData[0].days)
//                 setVar_CanEdit(filteredSingleData[0].can_edit)
//                 setLoading(false);

//                 for (let i = 0; i < filteredSingleData[0].day.length; i++) {
//                     if (filteredSingleData[0].day[i] == "1") {
//                         setVar_SunValue(filteredSingleData[0].day[i]);
//                     }
//                     if (filteredSingleData[0].day[i] == "2") {
//                         setVar_MonValue(filteredSingleData[0].day[i]);
//                     }
//                     if (filteredSingleData[0].day[i] == "3") {
//                         setVar_TueValue(filteredSingleData[0].day[i]);
//                     }
//                     if (filteredSingleData[0].day[i] == "4") {
//                         setVar_WedValue(filteredSingleData[0].day[i]);
//                     }
//                     if (filteredSingleData[0].day[i] == "5") {
//                         setVar_ThuValue(filteredSingleData[0].day[i]);
//                     }
//                     if (filteredSingleData[0].day[i] == "6") {
//                         setVar_FriValue(filteredSingleData[0].day[i]);
//                     }
//                     if (filteredSingleData[0].day[i] == "7") {
//                         setVar_SatValue(filteredSingleData[0].day[i]);
//                     }
//                 }
//                 getService();
//                 setLoading(false);

//             })
//             .catch((error) => {
//                 setLoading(false);
//                 console.log(error);
//             });


//     }

//     // ================================== Get individual working hours end ==================================

//     // ================================== Get Service Api Start =====================================
//     const getService = (id) => {
//         setLoading(true);
//         var data = {
//             doctor_id: localStorage.getItem("Doctor_id"),
//             speciality_id: id
//         };
//         Axios({ method: "POST", url: "doctor/getDoctorServiceBySpecilaity", data: data, })
//             .then((response) => {
//                 setLoading(false);
//                 {
//                     setVar_ServiceList(response.data.data)
//                     setLoading(false);

//                 }
//             })
//             .catch((error) => {
//                 setLoading(false);
//                 console.log(error);
//             });
//     }
//     // ================================== Get Service Api End =====================================

//     // ==================================  Get Appointement type start ==================================
//     const getAppointmentType = () => {
//         setLoading(true);

//         Axios({ method: "GET", url: "doctor/get_mas_appointment_type", })
//             .then((response) => {
//                 setLoading(false);
//                 {
//                     setVar_AppointmentList(response.data.data);
//                     setLoading(false);
//                 }
//             })
//             .catch((error) => {
//                 setLoading(false);
//                 console.log(error);
//             });
//     }
//     // ================================== Get Appoinment type end ==================================

//     // ================================== Form Change Get Values start ==================================

//     const getSpecialityValue = (e) => {

//         setVar_SpecialityName(e.target.value);
//     }
//     const getServiceValue = (e) => {
//         debugger;
//         var data = Var_ServiceList.filter(x => x.id == e.target.value);
//         setVar_ServiceId(e.target.value);
//         setVar_ServiceName(data[0].service);
//         setVar_SlotDuration(data[0].slot_duration)
//         calculateTimeSlots()
//     }

//     const getAppointmentTypeValue = (e) => {

//         var data = Var_AppointmentList.filter(x => x.id == e.target.value);
//         setVar_AppointmentType(data[0].appointment_type);
//         setVar_AppointTypeId(data[0].id)
//     }
//     const getFromTimeValue = (e) => {

//         setVar_FromTime(e.target.value);
//         calculateTimeSlots()
//     }
//     const getToTimeValue = (e) => {

//         setVar_ToTime(e.target.value);
//         calculateTimeSlots()
//     }


//     const okClose = () => {
//         setishiddensuccess(!isshowsuccess);
//         handleOnClick("/editworkinghours/viewworkinghours")
//     }





//     const changeSunValue = (e) => {

//         setVar_SunValue(e.target.checked == true ? "1" : "");
//     }
//     const changeMonValue = (e) => {

//         setVar_MonValue(e.target.checked == true ? "2" : "");
//     }
//     const changeThuValue = (e) => {

//         setVar_TueValue(e.target.checked == true ? "3" : "");
//     }
//     const changeWedValue = (e) => {

//         setVar_WedValue(e.target.checked == true ? "4" : "");
//     }
//     const changeTueValue = (e) => {

//         setVar_ThuValue(e.target.checked == true ? "5" : "");
//     }
//     const changeFriValue = (e) => {

//         setVar_FriValue(e.target.checked == true ? "6" : "");
//     }
//     const changeSatValue = (e) => {

//         setVar_SatValue(e.target.checked == true ? "7" : "");
//     }

//     // ================================== Form Change Get Values start ==================================

//     // ================================== Final Submit Update start ==================================

//     const UpdateWorkingHours = () => {
//         setisisDisable(true);
//         setLoading(true);
//         var EditList = {
//             id: Var_Id,
//             clinicId: Var_ClinicId,
//             serviceId: Var_ServiceId,
//             fromtime: Var_FromTime,
//             totime: Var_ToTime,
//             slotduration: Var_SlotDuration,
//             NoOfslots: Var_NoOfSlots,
//             appointmentType: Var_AppointTypeId_Ref.current,
//             days: [Var_SunValue ? Var_SunValue : 0, Var_MonValue ? Var_MonValue : 0, Var_TueValue ? Var_TueValue : 0, Var_WedValue ? Var_WedValue : 0, Var_ThuValue ? Var_ThuValue : 0, Var_FriValue ? Var_FriValue : 0, Var_SatValue ? Var_SatValue : 0],
//             isvip: Var_AppointmentType == "Fast Track" ? 1 : 0,
//             modifiedby: localStorage.getItem("Doctor_id"),
//             ipaddress: "192.168.1.45",
//             specialityId: Var_doctorSpeciality_Ref.current
//         }
//         if (Var_NoOfSlots != 0 && Var_ClinicId != "" && Var_ServiceId != "" && Var_FromTime != "" && Var_SlotDuration != "" && Var_ToTime != "" && Var_AppointTypeId_Ref.current != "" && Var_AppointmentType != "" && Var_SpecialityId != "") {
//             Axios({
//                 method: "PUT",
//                 url: "doctor/editdocAppointmentSettings",
//                 data: EditList,

//             })
//                 .then((response) => {
//                     setisisDisable(false);
//                     if (response.data.status == 1) {
//                         setVar_PopupModelIcon(successModelTickImg);
//                         setVar_AdvertisementMsgHead("SUCCESS!");
//                         setVar_AdvertisementMsg("Working Hours updated successfully!")
//                         setishiddensuccess(!isshowsuccess);
//                         setLoading(false);
//                     } else {
//                         setVar_PopupModelIcon(Alert);
//                         setVar_AdvertisementMsgHead("FAILED!");
//                         setVar_AdvertisementMsg(response.data.msg + "!")
//                         setishiddensuccess(!isshowsuccess);
//                         setLoading(false);
//                     }
//                 })
//         } else {
//             setisisDisable(false);
//             notification.error({ message: "Please select all flied" });
//             setLoading(false);
//         }


//     }

//     // ================================== Final Submit Update start ==================================


//     const checkAll = (e) => {
//         setVar_SunValue(e.target.checked == true ? "1" : "");
//         setVar_MonValue(e.target.checked == true ? "2" : "");
//         setVar_TueValue(e.target.checked == true ? "3" : "");
//         setVar_WedValue(e.target.checked == true ? "4" : "");
//         setVar_ThuValue(e.target.checked == true ? "5" : "");
//         setVar_FriValue(e.target.checked == true ? "6" : "");
//         setVar_SatValue(e.target.checked == true ? "7" : "");

//     }

//     // ============================get clinic Wise DOctor Api Start=============================

//     const getClinicWiseDoctor = () => {

//         setLoading(true);
//         var data = {
//             clinic_id: localStorage.getItem("Clinic_id")
//         };
//         Axios
//             .post("clinic/getClinicWiseDoctor", data)
//             .then((response) => {
//                 setLoading(false);

//                 var filteredData = response.data.data.filter(x => x.specialityid == localStorage.getItem("speciality_Id") && x.doctor_id == localStorage.getItem("Doctor_id"));
//                 setVar_DoctorDetailShow(filteredData[0].doctorName + ' ' + filteredData[0].specialityname);

//             })
//             .catch((error) => {
//                 setLoading(false);
//                 console.log(error);
//             });
//     }

//     // ============================get clinic Wise DOctor Api End=============================

//     const timeToMinutes = (time) => {
//         // Parse time in HH:mm format and convert to minutes
//         const [hours, minutes] = time.split(":").map(Number);
//         return hours * 60 + minutes;
//     }

//     const calculateTimeSlots = () => {

//         const fromMinutes = timeToMinutes(Var_FromTime_Ref.current);
//         const toMinutes = timeToMinutes(Var_ToTime_Ref.current);
//         var timeParts = Var_SlotDuration_Ref.current.split(":");
//         const slotDurationInMinutes = Number(timeParts[0]) * 60 + Number(timeParts[1]);
//         const durationInMinutes = toMinutes - fromMinutes;
//         const NoOfSlots = (Math.floor(durationInMinutes / slotDurationInMinutes));
//         if (NoOfSlots >= 0) {

//             setVar_NoOfSlots(NoOfSlots)
//         } else if (NoOfSlots < 0) {

//             setVar_NoOfSlots(0)
//         }

//         // setVar_NoOfSlots(Math.floor(durationInMinutes / slotDurationInMinutes));

//     }
//     // const getClinicDoctor = () => {
//     //     setLoading(true);
//     //     debugger;
//     //     var data = {
//     //         doctorId: localStorage.getItem("Doctor_id")
//     //     };
//     //     Axios
//     //         .post("doctor/getDoctorClinics", data)
//     //         .then((response) => {
//     //             debugger;
//     //             localStorage.setItem("Clinic_id", response.data.data[0].clinicId)
//     //             // setVar_getClinicWiseDoctor(response.data.data);
//     //             setVar_doctorClinic(response.data.data[0].clinicId)
//     //             console.log("response.data.data[0].clinicId", response.data.data[0].clinicId);
//     //             console.log("setVar_DoctorClinicSelect", response.data.data);
//     //             setVar_DoctorClinicSelect(response.data.data);
//     //             // localStorage.setItem("speciality_Id", response.data.data[0].specialityid);
//     //             // clinicTabPermissionById()
//     //             // checkThreeStepVerification(response.data.data[0].doctor_id)
//     //             setLoading(false);

//     //         })
//     //         .catch((error) => {
//     //             setLoading(false);
//     //             console.log(error);
//     //         });
//     // }
//     const doctorClinic = (e) => {
//         // alert(e.target.value);
//         setVar_doctorClinic(e.target.value);
//         // localStorage.setItem("Clinic_id", e.target.value);
//         // getAllAppointment();
//     }

//     const getdoctorSpeciality = () => {
//         setLoading(true);
//         debugger;
//         var data = {
//             // doctorId: localStorage.getItem("Doctor_ID")
//             doctorId: localStorage.getItem("Doctor_id")
//         };
//         Axios
//             .post("doctor/getDoctorDetails", data)
//             .then((response) => {

//                 debugger;
//                 // var filteredData = response.data.data.filter(x => x.specialityid == localStorage.getItem("speciality_Id") && x.doctor_id == localStorage.getItem("doctor_id"));
//                 // setVar_DoctorDetailShow(filteredData[0].doctorName + ' ' + filteredData[0].specialityname);
//                 // setVar_DoctorSpeciality(filteredData[0].specialityname)
//                 const doctorSpeciality = JSON.parse(response.data.data[0].speciality)
//                 // setVar_doctorSpeciality(doctorSpeciality[0].specialityId)
//                 // localStorage.setItem("speciality_Id", doctorSpeciality[0].specialityId)
//                 setVar_DoctorSpecialtySelect(JSON.parse(response.data.data[0].speciality));
//                 // JSON.parse(specialitiesString);
//                 console.log("fjkbvsfkjb", JSON.parse(response.data.data[0].speciality));
//                 // setVar_doctorSpeciality(response.data.data[0].speciality[0].specialityId);
//                 getService(doctorSpeciality[0].specialityId);

//                 setLoading(false);

//             })
//             .catch((error) => {
//                 setLoading(false);
//                 console.log(error);
//             });
//     }

//     const doctorSpeciality = (id, name) => {
//         debugger;
//         // alert(e.target.value);
//         console.log("acv", id, name);
//         // let Speciality = Var_DoctorSpecialtySelect.filter(x => x.specialityId == e.target.value);
//         setVar_SpecialtyName(name);
//         setVar_doctorSpeciality(id);
//         getService(id);
//         // localStorage.setItem("Clinic_id", e.target.value);
//         // getAllAppointment();
//     }


//     return (
//         <div>
//             {/* {loading &&
//                 <div className="loader_body">
//                     <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
//                         <CircularProgress />
//                     </Box>
//                 </div>
//             } */}
//             {loading &&
//                 <LinearProgress color="secondary" className="progressBar" sx={{ zIndex: '9999' }} />
//             }
//             {/* ================= WorkingHours sub header start ================= */}
//             <div className="sub_header">
//                 <div className="sub_header_body">
//                     <div className="back_navigation"
//                         onClick={() => handleOnClick()}
//                     >
//                         <i class="fa fa-angle-left" aria-hidden="true"></i>
//                         <span>WORKING HOURS</span>
//                     </div>
//                     <div className="flex_grow"></div>
//                     <div className="header_right_contents">
//                         {/* <div>
//                             <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
//                                 <Select
//                                     labelId="demo-select-small-label"
//                                     id="demo-select-small"
//                                     SelectProps={{
//                                         renderValue: (p) => p
//                                     }}
//                                     value={Var_DoctorDetailShow_Ref.current}
//                                     readOnly={true}
//                                     renderValue={(p) => p}

//                                 >
//                                 </Select>
//                             </FormControl>

//                         </div> */}
//                         <div className='drop dropdown_button'>
//                             {/* <FormControl fullWidth sx={{ m: 1, minWidth: 200 }}
//                                 size="small">
//                                 <Select size="small" className="selectoption"
//                                     // sx={{ m: 1, minHeight: 1, minWidth: 100 }}

//                                     id="demo-simple-select"
//                                     value={Var_doctorClinic}
//                                     onChange={(e) => {
//                                         doctorClinic(e)
//                                     }}  >
//                                     {Var_DoctorClinicSelect?.map((item) => (
//                                         <MenuItem
//                                             // key={item.specialityname}
//                                             value={item.clinicId}>
//                                             {item.clinicName}
//                                         </MenuItem>
//                                     ))}
//                                 </Select>
//                             </FormControl> */}
//                         </div>
//                         <div className="right_headers"></div>
//                     </div>
//                 </div>
//             </div>
//             {/* ================= WorkingHours sub header End ================= */}


//             {/* ================== Edit workimg hours start ================== */}
//             <div>
//                 <div className='workinghours_content'>
//                     <Container>
//                         <Container>

//                             <Grid container className='select_option'>
//                                 <Grid item xs='3' className='worckselectors'>
//                                     <div  >
//                                         <p className='headlabel headerlab'> Specialty</p>
//                                         <Grid container className=' Select_Label_work'>
//                                             <Grid item className='Column_'>
//                                                 <div className='drop dropdown_button'>
//                                                     <TextField id="outlined-basic" variant="outlined" sx={{ m: 1, minHeight: 8, minWidth: 250 }}
//                                                         InputProps={{
//                                                             readOnly: true,
//                                                         }}
//                                                         value={Var_SpecialityName}
//                                                         onChange={(e) => getSpecialityValue(e)}
//                                                     />
//                                                 </div>
//                                             </Grid>
//                                         </Grid>

//                                     </div>
//                                 </Grid>

//                                 <Grid item xs='3' className='worckselectors'>
//                                     <div className="Service_Size">
//                                         <p className='headlabel headerlab'> Service</p>
//                                         <Grid container className='Select_Label_work'>
//                                             <Grid item className='Column_'>
//                                                 <div className='drop dropdown_button'>
//                                                     <FormControl fullWidth>

//                                                         <InputLabel id="demo-simple-select-label">{Var_ServiceName != null ? "" : "Select Service  "}</InputLabel>

//                                                         <TextField
//                                                             SelectProps={{
//                                                                 renderValue: (p) => p
//                                                             }}
//                                                             value={Var_ServiceName}
//                                                             select
//                                                             fullWidth
//                                                             size="small"
//                                                             renderValue={(p) => p}
//                                                             sx={{ m: 1, minHeight: 15, minWidth: 250 }}
//                                                             InputProps={{
//                                                                 readOnly: true
//                                                             }}
//                                                             onChange={(e) => getServiceValue(e)} >
//                                                             {Var_ServiceList.map((item, i) => {
//                                                                 return (
//                                                                     <MenuItem key={i} value={item.id} serviceName={item.service}
//                                                                     > <img src={item.file_path} className="drop_img" />
//                                                                         {item.service}</MenuItem>
//                                                                 )
//                                                             })}
//                                                         </TextField>
//                                                     </FormControl>
//                                                 </div>
//                                             </Grid>
//                                         </Grid>
//                                     </div>
//                                 </Grid>

//                                 < Grid item xs='3' className='worckselectors'>
//                                     <div>
//                                         <p className='headlabel headerlabs'>Slot Duration</p>
//                                         <Grid container className='Select_Label Select_Label_work_'>
//                                             <Grid item xs='10' align="start" className='labtext'>
//                                                 <p style={{ color: "#aaaaaa", position: 'relative', bottom: '2px' }} >{Var_SlotDuration}</p>
//                                             </Grid>
//                                             <Grid item xs='1' className='ref_lab' align="start">
//                                                 <i className="fa fa-clock-o" aria-hidden="true"></i>
//                                             </Grid>
//                                         </Grid>
//                                     </div>
//                                 </Grid>


//                                 <Grid item xs='3' className='worckselectors'>
//                                     <div className="Appointment_Sizee">
//                                         <p className='headlabel headerlab'>Appointment Type</p>
//                                         <Grid container className='Select_Label_work'>
//                                             <Grid item className='Column_'>
//                                                 <div className='drop dropdown_button'>
//                                                     <FormControl fullWidth>
//                                                         <InputLabel id="demo-simple-select-label">{Var_AppointmentType != null ? "" : "Appointment Type"}</InputLabel>

//                                                         <TextField
//                                                             SelectProps={{
//                                                                 renderValue: (p) => p
//                                                             }}
//                                                             value={Var_AppointmentType}
//                                                             select
//                                                             fullWidth
//                                                             size="small"
//                                                             renderValue={(p) => p}
//                                                             sx={{ m: 1, minHeight: 15, minWidth: 250 }}
//                                                             InputProps={{
//                                                                 readOnly: false
//                                                             }}
//                                                             onChange={(e) => getAppointmentTypeValue(e)}

//                                                         >
//                                                             {Var_AppointmentList.map((item, i) => {
//                                                                 return (
//                                                                     <MenuItem key={i} value={item.id}
//                                                                     >

//                                                                         {
//                                                                             item.id == 1 && <> <img src={Visit} alt='a' className='specialtyimg' /><span className="appointment_types_wrkhrs">{item.appointment_type} </span></>
//                                                                         }

//                                                                         {
//                                                                             item.id == 2 && <> <img src={Online} alt='a' className='specialtyimg' /> <span className="appointment_types_wrkhrs">{item.appointment_type} </span> </>
//                                                                         }

//                                                                         {
//                                                                             item.id == 3 && <> <img src={Fasttrack} alt='a' className='specialtyimg' /> <span className="appointment_types_wrkhrs">{item.appointment_type} </span> </>
//                                                                         }

//                                                                         {

//                                                                             item.id == 4 &&
//                                                                             <>
//                                                                                 <img src={InclinicOnline} alt='a' className='specialtyimg' />
//                                                                                 <span className="appointment_types_wrkhrs">{item.appointment_type} </span>
//                                                                             </>

//                                                                         }
//                                                                         {/* <img className="drop_img" style={{ borderRadius: "5px" }} src={item.appointment_type == "In-Clinic" ? Visit : item.appointment_type == "Online" ? Online : item.appointment_type == "In-Clinic/Online" ? Walkin : item.appointment_type == "Fast Track" ? Fasttrack : ""}></img>
//                                                                         {item.appointment_type} */}

//                                                                     </MenuItem>
//                                                                 )
//                                                             })

//                                                             }

//                                                         </TextField>

//                                                     </FormControl>
//                                                 </div>
//                                             </Grid>
//                                         </Grid>
//                                     </div>
//                                 </Grid>
//                             </Grid>

//                             <Grid container className='select_option'>
//                                 <Grid item xs='3' className='worckselectors' >
//                                     <div className="From_Tym">
//                                         <p className='headlabel headerlabs'>From Time</p>
//                                         <Grid container>
//                                             <Grid item className='ref_labs' align="start">
//                                                 <input className='tymbox' type="time" id="appt" name="appt" readOnly
//                                                     value={Var_FromTime} onChange={(e) => getFromTimeValue(e)}
//                                                 />
//                                             </Grid>
//                                         </Grid>
//                                     </div>
//                                 </Grid>

//                                 <Grid item xs='3' className='worckselectors'>
//                                     <div className="From_Tym">
//                                         <p className='headlabel headerlabs'>To Time</p>
//                                         <Grid container >
//                                             <Grid item className='ref_lab' align="start" >
//                                                 <input className='tymbox' type="time" id="appt" name="appt" readOnly
//                                                     value={Var_ToTime} onChange={(e) => getToTimeValue(e)}
//                                                 />
//                                             </Grid>
//                                         </Grid>
//                                     </div>
//                                 </Grid>

//                                 <Grid item xs='3' className='worckselectors slotcount'>
//                                     <div className='slotcounts'>
//                                         <p className='slots_count'>No. of Slots <span style={{ display: 'flex', width: '85px', paddingTop: '20px', justifyContent: 'center' }}> {Var_NoOfSlots} </span>
//                                         </p>
//                                     </div>
//                                 </Grid>
//                             </Grid>

//                             <Grid container className='select_option'>
//                                 <Grid item xs='6' className='selectEvery'>
//                                     <div className='selectEverytog'>
//                                         <p className='headlabel headerlab'><span style={{ fontWeight: 'bold' }}>Applicable Days </span><span className='Everyday'>All</span></p>
//                                         <FormControlLabel className='every_days' control={<IOSSwitch sx={{ m: 1 }}
//                                             className='toggle'
//                                             onChange={(e) => checkAll(e)}
//                                             checked={Var_SunValue_Ref.current == '1' && Var_MonValue_Ref.current == '2' && Var_TueValue_Ref.current == '3' && Var_WedValue_Ref.current == '4' && Var_ThuValue_Ref.current == '5' && Var_FriValue_Ref.current == '6' && Var_SatValue_Ref.current == '7'}
//                                         />}
//                                             value={Var_Days}
//                                         />
//                                     </div>
//                                 </Grid>
//                             </Grid>
//                         </Container>

//                         <Container>
//                             <Grid container className='DayButs'>
//                                 <Grid xs={12} item className='DayButsad'>
//                                     <div className='TotalsingleDay'>
//                                         <div className='singleDay'>
//                                             <p className='days_text'>Sun</p>

//                                             <FormControlLabel control={<IOSSwitch onChange={(e) => changeSunValue(e)} checked={Var_SunValue_Ref.current == '1' ? true : false} sx={{ m: 1 }} className='toggle' />} />
//                                         </div>
//                                         <div className='singleDay'>
//                                             <p className='days_text'>Mon</p>
//                                             <FormControlLabel control={<IOSSwitch onChange={(e) => changeMonValue(e)} checked={Var_MonValue_Ref.current == '2' ? true : false} sx={{ m: 1 }} className='toggle' />} />
//                                         </div>
//                                         <div className='singleDay'>
//                                             <p className='days_text'>Tue</p>
//                                             <FormControlLabel control={<IOSSwitch onChange={(e) => changeThuValue(e)} checked={Var_TueValue_Ref.current == '3' ? true : false} sx={{ m: 1 }} className='toggle' />} />
//                                         </div>
//                                         <div className='singleDay'>
//                                             <p className='days_text'>Wed</p>
//                                             <FormControlLabel control={<IOSSwitch onChange={(e) => changeWedValue(e)} checked={Var_WedValue_Ref.current == '4' ? true : false} sx={{ m: 1 }} className='toggle' />} />
//                                         </div>
//                                         <div className='singleDay'>
//                                             <p className='days_text'>Thu</p>
//                                             <FormControlLabel control={<IOSSwitch onChange={(e) => changeTueValue(e)} checked={Var_ThuValue_Ref.current == '5' ? true : false} sx={{ m: 1 }} className='toggle' />} />
//                                         </div>
//                                         <div className='singleDay'>
//                                             <p className='days_text'>Fri</p>
//                                             <FormControlLabel control={<IOSSwitch onChange={(e) => changeFriValue(e)} checked={Var_FriValue_Ref.current == '6' ? true : false} sx={{ m: 1 }} className='toggle' />} />
//                                         </div>
//                                         <div className='singleDay'>
//                                             <p className='days_text'>Sat</p>
//                                             <FormControlLabel control={<IOSSwitch onChange={(e) => changeSatValue(e)} checked={Var_SatValue_Ref.current == '7' ? true : false} sx={{ m: 1 }} className='toggle' />} />
//                                         </div>
//                                     </div>
//                                 </Grid>
//                             </Grid>

//                             <Card className='detail_list'>
//                                 <Grid container>
//                                     <Grid item xs='10' align='center'>
//                                         <div className='Detailtypes'>
//                                             <div className='Detailcont'>
//                                                 <p className='small_txet'>Specialty</p>
//                                                 <p className='text_lab'>{Var_SpecialtyName_Ref.current}</p>
//                                             </div>
//                                             <div className='Detailcont'>
//                                                 <p className='small_txet'>Slot Duration <span style={{ fontSize: '13px' }}>(hrs)</span></p>
//                                                 <p className='text_lab'>{Var_SlotDuration}</p>
//                                             </div>
//                                             <div className='Detailcont'>
//                                                 <p className='small_txet'>No. of Slots</p>
//                                                 <p className='text_lab'>{Var_NoOfSlots}</p>
//                                             </div>
//                                             <div className='Detailcont'>
//                                                 <p className='small_txet'>Working Hours</p>
//                                                 <p className='text_lab' style={{ textTransform: 'lowercase' }}>
//                                                     {Moment(Var_FromTime, 'hh:mm A').format('hh:mm A')} to {Moment(Var_ToTime, 'hh:mm A').format('hh:mm A')}
//                                                 </p>
//                                             </div>
//                                         </div>
//                                         <div className='Detailtypes' style={{ marginTop: '7px' }}>
//                                             <div className='Detailcont'>
//                                                 <p className='small_txet'>Service</p>
//                                                 <p className='text_lab'>{Var_ServiceName}</p>
//                                             </div>
//                                             <div className='Detailcont'>
//                                                 <p className='small_txet' >Appointment Type</p>
//                                                 <p className='text_lab' >{Var_AppointmentType}

//                                                     <span>
//                                                         {
//                                                             Var_AppointmentType == "In-Clinic" && <img src={Visit} alt='a' className='specialtyimg' />
//                                                         }

//                                                         {
//                                                             Var_AppointmentType == "Online" && <img src={Online} alt='a' className='specialtyimg' />
//                                                         }

//                                                         {
//                                                             Var_AppointmentType == "Fast Track" && <img src={Fasttrack} alt='a' className='specialtyimg' />
//                                                         }

//                                                         {
//                                                             Var_AppointmentType == "In-Clinic/Online" && <img src={InclinicOnline} alt='a' className='specialtyimg' />
//                                                         }

//                                                         {/* <img className="image_size" src={Var_Appointmentlist == "In-Clinic" ? Visit : Var_Appointmentlist == "Online" ? Online : Var_Appointmentlist == "In-Clinic/Online" ? Walkin : Var_Appointmentlist == "Fast Track" ? Fasttrack : ""}></img> */}
//                                                     </span>

//                                                     {/* <span>
//                                                         <img className="image_size" src={Var_AppointmentType == "In-Clinic" ? Visit : Var_AppointmentType == "Online" ? Online : Var_AppointmentType == "In-Clinic/Online" ? Walkin : Var_AppointmentType == "Fast Track" ? Fasttrack : ""}></img>
//                                                     </span> */}
//                                                 </p>
//                                             </div>
//                                             <div className='Detailcont'>
//                                                 <p className='small_txet'>Working Days</p>
//                                                 <p className='text_lab'
//                                                     value={Var_Days}
//                                                 >
//                                                     {
//                                                         Var_SunValue && Var_MonValue && Var_TueValue && Var_WedValue && Var_ThuValue && Var_FriValue && Var_SatValue ? 'All Days' :
//                                                             // <>
//                                                             //     {
//                                                             //         Var_SunValue && 'Sun, '
//                                                             //     }

//                                                             //     {
//                                                             //         Var_MonValue && 'Mon, '
//                                                             //     }

//                                                             //     {
//                                                             //         Var_TueValue && 'Tue, '
//                                                             //     }

//                                                             //     {
//                                                             //         Var_WedValue && 'Wed, '
//                                                             //     }

//                                                             //     {
//                                                             //         Var_ThuValue && 'Thu, '
//                                                             //     }

//                                                             //     {
//                                                             //         Var_FriValue && 'Fri, '
//                                                             //     }

//                                                             //     {
//                                                             //         Var_SatValue && 'Sat '
//                                                             //     }

//                                                             // </>

//                                                             <>
//                                                                 {(Var_MonValue || Var_TueValue || Var_WedValue || Var_ThuValue || Var_FriValue || Var_SatValue)
//                                                                     && Var_SunValue ? 'Sun, ' :
//                                                                     Var_SunValue && 'Sun '
//                                                                 }

//                                                                 {
//                                                                     (Var_TueValue || Var_WedValue || Var_ThuValue || Var_FriValue || Var_SatValue)
//                                                                         && Var_MonValue ? 'Mon, ' :
//                                                                         Var_MonValue && 'Mon '
//                                                                 }

//                                                                 {(Var_WedValue || Var_ThuValue || Var_FriValue || Var_SatValue) && Var_TueValue ? 'Tue, ' :
//                                                                     Var_TueValue && 'Tue '
//                                                                 }

//                                                                 {(Var_ThuValue || Var_FriValue || Var_SatValue) && Var_WedValue ? 'Wed, ' :
//                                                                     Var_WedValue && 'Wed '
//                                                                 }

//                                                                 {(Var_FriValue || Var_SatValue) && Var_ThuValue ? 'Thu, ' :
//                                                                     Var_ThuValue && 'Thu '
//                                                                 }

//                                                                 {Var_SatValue && Var_FriValue ? 'Fri, ' :
//                                                                     Var_FriValue && 'Fri '
//                                                                 }

//                                                                 {
//                                                                     Var_SatValue && 'Sat '
//                                                                 }

//                                                             </>


//                                                     }

//                                                     {/* {Var_SunValue == '1' ? "Sun," : ""}
//                                                     {Var_MonValue == '2' ? "Mon," : ""}
//                                                     {Var_TueValue == '3' ? "Tue," : ""}
//                                                     {Var_WedValue == '4' ? "Wed," : ""}
//                                                     {Var_ThuValue == '5' ? "Thu," : ""}
//                                                     {Var_FriValue == '6' ? "Fri," : ""}
//                                                     {Var_SatValue == '7' ? "Sat" : ""} */}
//                                                 </p>
//                                             </div>
//                                             <div className='Detailcont'>
//                                             </div>
//                                         </div>
//                                     </Grid>

//                                     <Grid item xs='2' align='center'>
//                                         <p className='Action' style={{ marginTop: "10%" }} >Action</p>
//                                         <div className='Action_button'>
//                                             <Button className="botton_Add" 
//                                               disabled={isDisable_Ref.current}
//                                               onClick={() => UpdateWorkingHours()}> Update </Button>
//                                             {/* <Button className="botton_Cancel" > Cancel </Button> */}
//                                         </div>
//                                     </Grid>
//                                 </Grid>
//                             </Card>
//                         </Container>
//                     </Container>
//                 </div>



//                 {/* ================= Working hours Update Success Model Start =============== */}
//                 <Dialog
//                     fullWidth={fullWidth}
//                     open={isshowsuccess}
//                     onClose={setishiddensuccess}
//                     sx={{
//                         "& .MuiDialog-container": {
//                             "& .MuiPaper-root": {
//                                 width: "100%",
//                                 maxWidth: "300px",
//                                 borderRadius: "20px",
//                             },
//                         },
//                     }}
//                 >
//                     <DialogContent>
//                         <div className="success_model_body">
//                             <div className="icon">
//                                 <img src={Var_PopupModelIcon_Ref.current} />
//                             </div>
//                             <div className="title">{Var_AdvertisementMsgHead_Ref.current}</div>
//                             <div className="content">{Var_AdvertisementMsg_Ref.current}</div>
//                             <div className="segment_center">
//                                 <button className="botton_Cart_Create" onClick={() => okClose()}>OK</button>
//                             </div>
//                         </div>
//                     </DialogContent>
//                 </Dialog>
//                 {/* ================= Working hours Update Success Model End  ================= */}


//             </div>
//             {/* Edit working hours end  */}


//             {/* Footer content start */}
//             {localStorage.getItem("FooterStatus") == 'A' && <div className="layout_footer footer">
//                 <FooterMaster />
//             </div>
//             }

//             {/* footer content end */}

//         </div>
//     )
// }
// export default EditWorkingHoursComponent;


import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import Axios from "axios";
import useStateRef from "react-usestateref";
import { Container } from "react-bootstrap";
import Moment from "moment";

// Mui style importing start
// Mui meterial component import
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import FormControlLabel from "@mui/material/FormControlLabel";
import { MenuItem } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog, { DialogProps } from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import TextField from "@mui/material/TextField";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";

import Visit from "../../assets/Doctor_web_Icon/Visit.svg";
import Online from "../../assets/Doctor_web_Icon/Online.svg";
import Fasttrack from "../../assets/Doctor_web_Icon/Fasttrack.svg";
import InclinicOnline from "../../assets/Doctor_web_Icon/InclinicOnline.svg";
import Walkin from "../../assets/Doctor_web_Icon/Walkin.svg";
import successModelTickImg from "../../assets/Doctor_web_Icon/success.svg";
import Alert from "../../assets/Images/alert.svg";
import "./EditWorkingHours.css";
import HeaderMasterComponent from "../Header/HeaderMaster";

import IOSSwitch from "../../helpers/Switch/Switch";
import FooterMaster from "../Dashboard/FooterMaster";
import { notification } from "antd";
import BackIcon from "../../assets/Doctor_web_Icon/Group 31.svg";

const EditWorkingHoursComponent = () => {
    let { workingHoursId } = useParams();
    let Var_History = useHistory();

    const [isshowsuccess, setishiddensuccess] = useState(false);
    const [fullWidth, setFullWidth] = React.useState(true);
    const [Var_Id, setVar_Id, Var_Id_Ref] = useStateRef(null);
    const [Var_ClinicId, setVar_ClinicId, Var_ClinicId_Ref] = useStateRef(null);
    const [Var_DoctorId, setVar_DoctorId, Var_DoctorId_Ref] = useStateRef(null);
    const [Var_FromTime, setVar_FromTime, Var_FromTime_Ref] = useStateRef(null);
    const [Var_ToTime, setVar_ToTime, Var_ToTime_Ref] = useStateRef(null);
    const [Var_SlotDuration, setVar_SlotDuration, Var_SlotDuration_Ref] =
        useStateRef(null);
    const [Var_NoOfSlots, setVar_NoOfSlots, Var_NoOfSlots_Ref] =
        useStateRef(null);
    const [Var_AppointTypeId, setVar_AppointTypeId, Var_AppointTypeId_Ref] =
        useStateRef(null);
    const [Var_TotalCount, setVar_TotalCount, Var_TotalCount_Ref] =
        useStateRef(null);
    const [Var_NextRowCount, setVar_NextRowCount, Var_NextRowCount_Ref] =
        useStateRef(null);
    const [Var_AppointmentType, setVar_AppointmentType, Var_AppointmentType_Ref] =
        useStateRef(null);
    const [Var_ServiceId, setVar_ServiceId, Var_ServiceId_Ref] =
        useStateRef(null);
    const [Var_ServiceName, setVar_ServiceName, Var_ServiceName_Ref] =
        useStateRef(null);
    const [Var_SpecialityName, setVar_SpecialityName, Var_SpecialityName_Ref] =
        useStateRef(null);
    const [Var_SpecialityId, setVar_SpecialityId, Var_SpecialityId_Ref] =
        useStateRef(null);
    const [Var_Days, setVar_Days, Var_Days_Ref] = useStateRef(null);
    const [Var_CanEdit, setVar_CanEdit, Var_CanEdit_Ref] = useStateRef(null);
    const [Var_ServiceList, setVar_ServiceList, Var_ServiceList_Ref] =
        useStateRef([]);
    const [Var_AppointmentList, setVar_AppointmentList, Var_AppointmentList_Ref] =
        useStateRef([]);
    const [
        Var_DoctorDetailShow,
        setVar_DoctorDetailShow,
        Var_DoctorDetailShow_Ref,
    ] = useStateRef(null);
    const [loading, setLoading] = useState(true);
    const [verification, setVerification] = useState(
        Var_History.location.state
            ? Var_History.location.state.verification
                ? Var_History.location.state.verification
                : false
            : false
    );
    const [
        Var_AdvertisementMsg,
        setVar_AdvertisementMsg,
        Var_AdvertisementMsg_Ref,
    ] = useStateRef("");
    const [
        Var_AdvertisementMsgHead,
        setVar_AdvertisementMsgHead,
        Var_AdvertisementMsgHead_Ref,
    ] = useStateRef("");
    const [Var_PopupModelIcon, setVar_PopupModelIcon, Var_PopupModelIcon_Ref] =
        useStateRef("");
    const [Var_SunValue, setVar_SunValue, Var_SunValue_Ref] = useStateRef(null);
    const [Var_MonValue, setVar_MonValue, Var_MonValue_Ref] = useStateRef(null);
    const [Var_TueValue, setVar_TueValue, Var_TueValue_Ref] = useStateRef(null);
    const [Var_WedValue, setVar_WedValue, Var_WedValue_Ref] = useStateRef(null);
    const [Var_ThuValue, setVar_ThuValue, Var_ThuValue_Ref] = useStateRef(null);
    const [Var_FriValue, setVar_FriValue, Var_FriValue_Ref] = useStateRef(null);
    const [Var_SatValue, setVar_SatValue, Var_SatValue_Ref] = useStateRef(null);
    const [, setisisDisable, isDisable_Ref] = useStateRef(false);

    // ================================== initial function call start ==================================
    useEffect(() => {
        getIndividualWorkingHours();
        getAppointmentType();
        getClinicWiseDoctor();
        setisisDisable(false);
    }, []);
    // ================================== initial function call End ==================================

    const handleOnClick = () => {
        Var_History.push("/dashboard/workinghours/viewworkinghours");
    };

    // ================================== Get Individual working hours start ==================================
    const getIndividualWorkingHours = () => {
        setLoading(true);
        var DoctorServiceBySpecilaity = {
            doctorId: localStorage.getItem("Doctor_id"),
            clinicId: localStorage.getItem("Clinic_id"),
            limit: 100,
            pageno: 1,
        };

        Axios({
            method: "POST",
            url: "doctor/getdocAppointmentSettings",
            data: DoctorServiceBySpecilaity,
        })
            .then((response) => {
                var filteredSingleData = response.data.data[0].details.filter(
                    (x) => x.id == workingHoursId
                );

                setVar_Id(filteredSingleData[0].id);
                setVar_ClinicId(filteredSingleData[0].clinicId);
                setVar_DoctorId(filteredSingleData[0].doctor_id);
                setVar_FromTime(filteredSingleData[0].from_time);
                setVar_ToTime(filteredSingleData[0].to_time);
                setVar_SlotDuration(filteredSingleData[0].slot_duration);
                setVar_NoOfSlots(filteredSingleData[0].no_of_slots);
                setVar_AppointTypeId(filteredSingleData[0].appointtype_id);
                setVar_TotalCount(filteredSingleData[0].total_count);
                setVar_NextRowCount(filteredSingleData[0].NextRowCount);
                setVar_AppointmentType(filteredSingleData[0].appointment_type);
                setVar_ServiceId(filteredSingleData[0].service_id);
                setVar_ServiceName(filteredSingleData[0].service_name);
                setVar_SpecialityName(filteredSingleData[0].speciality_name);
                setVar_SpecialityId(filteredSingleData[0].speciality_id);
                // setVar_Days(filteredSingleData[0].days)
                setVar_CanEdit(filteredSingleData[0].can_edit);
                setLoading(false);

                for (let i = 0; i < filteredSingleData[0].day.length; i++) {
                    if (filteredSingleData[0].day[i] == "1") {
                        setVar_SunValue(filteredSingleData[0].day[i]);
                    }
                    if (filteredSingleData[0].day[i] == "2") {
                        setVar_MonValue(filteredSingleData[0].day[i]);
                    }
                    if (filteredSingleData[0].day[i] == "3") {
                        setVar_TueValue(filteredSingleData[0].day[i]);
                    }
                    if (filteredSingleData[0].day[i] == "4") {
                        setVar_WedValue(filteredSingleData[0].day[i]);
                    }
                    if (filteredSingleData[0].day[i] == "5") {
                        setVar_ThuValue(filteredSingleData[0].day[i]);
                    }
                    if (filteredSingleData[0].day[i] == "6") {
                        setVar_FriValue(filteredSingleData[0].day[i]);
                    }
                    if (filteredSingleData[0].day[i] == "7") {
                        setVar_SatValue(filteredSingleData[0].day[i]);
                    }
                }
                getService();
                setLoading(false);
            })
            .catch((error) => {
                setLoading(false);
                console.log(error);
            });
    };

    // ================================== Get individual working hours end ==================================

    // ================================== Get Service Api Start =====================================
    const getService = () => {
        setLoading(true);
        var data = {
            doctor_id: localStorage.getItem("Doctor_id"),
            speciality_id: Var_SpecialityId_Ref.current,
        };
        Axios({
            method: "POST",
            url: "doctor/getDoctorServiceBySpecilaity",
            data: data,
        })
            .then((response) => {
                setLoading(false);
                {
                    setVar_ServiceList(response.data.data);
                    setLoading(false);
                }
            })
            .catch((error) => {
                setLoading(false);
                console.log(error);
            });
    };
    // ================================== Get Service Api End =====================================

    // ==================================  Get Appointement type start ==================================
    const getAppointmentType = () => {
        setLoading(true);

        Axios({ method: "GET", url: "doctor/get_mas_appointment_type" })
            .then((response) => {
                setLoading(false);
                {
                    setVar_AppointmentList(response.data.data);
                    setLoading(false);
                }
            })
            .catch((error) => {
                setLoading(false);
                console.log(error);
            });
    };
    // ================================== Get Appoinment type end ==================================

    // ================================== Form Change Get Values start ==================================

    const getSpecialityValue = (e) => {
        setVar_SpecialityName(e.target.value);
    };
    const getServiceValue = (e) => {
        var data = Var_ServiceList.filter((x) => x.id == e.target.value);
        setVar_ServiceId(e.target.value);
        setVar_ServiceName(data[0].service);
        setVar_SlotDuration(data[0].slot_duration);
        calculateTimeSlots();
    };

    const getAppointmentTypeValue = (e) => {
        var data = Var_AppointmentList.filter((x) => x.id == e.target.value);
        setVar_AppointmentType(data[0].appointment_type);
        setVar_AppointTypeId(data[0].id);
    };
    const getFromTimeValue = (e) => {
        setVar_FromTime(e.target.value);
        calculateTimeSlots();
    };
    const getToTimeValue = (e) => {
        setVar_ToTime(e.target.value);
        calculateTimeSlots();
    };

    const okClose = () => {
        setishiddensuccess(!isshowsuccess);
        handleOnClick("/editworkinghours/viewworkinghours");
    };

    const changeSunValue = (e) => {
        setVar_SunValue(e.target.checked == true ? "1" : "");
    };
    const changeMonValue = (e) => {
        setVar_MonValue(e.target.checked == true ? "2" : "");
    };
    const changeThuValue = (e) => {
        setVar_TueValue(e.target.checked == true ? "3" : "");
    };
    const changeWedValue = (e) => {
        setVar_WedValue(e.target.checked == true ? "4" : "");
    };
    const changeTueValue = (e) => {
        setVar_ThuValue(e.target.checked == true ? "5" : "");
    };
    const changeFriValue = (e) => {
        setVar_FriValue(e.target.checked == true ? "6" : "");
    };
    const changeSatValue = (e) => {
        setVar_SatValue(e.target.checked == true ? "7" : "");
    };

    // ================================== Form Change Get Values start ==================================

    // ================================== Final Submit Update start ==================================

    const UpdateWorkingHours = () => {
        setisisDisable(true);
        setLoading(true);
        var EditList = {
            id: Var_Id,
            clinicId: Var_ClinicId,
            serviceId: Var_ServiceId,
            fromtime: Var_FromTime,
            totime: Var_ToTime,
            slotduration: Var_SlotDuration,
            NoOfslots: Var_NoOfSlots,
            appointmentType: Var_AppointTypeId_Ref.current,
            days: [
                Var_SunValue ? Var_SunValue : 0,
                Var_MonValue ? Var_MonValue : 0,
                Var_TueValue ? Var_TueValue : 0,
                Var_WedValue ? Var_WedValue : 0,
                Var_ThuValue ? Var_ThuValue : 0,
                Var_FriValue ? Var_FriValue : 0,
                Var_SatValue ? Var_SatValue : 0,
            ],
            isvip: Var_AppointmentType == "Fast Track" ? 1 : 0,
            modifiedby: localStorage.getItem("Doctor_id"),
            ipaddress: "192.168.1.45",
            specialityId: Var_SpecialityId,
        };
        if (
            Var_NoOfSlots != 0 &&
            Var_ClinicId != "" &&
            Var_ServiceId != "" &&
            Var_FromTime != "" &&
            Var_SlotDuration != "" &&
            Var_ToTime != "" &&
            Var_AppointTypeId_Ref.current != "" &&
            Var_AppointmentType != "" &&
            Var_SpecialityId != ""
        ) {
            Axios({
                method: "PUT",
                url: "doctor/editdocAppointmentSettings",
                data: EditList,
            }).then((response) => {
                setisisDisable(false);
                if (response.data.status == 1) {
                    setVar_PopupModelIcon(successModelTickImg);
                    setVar_AdvertisementMsgHead("SUCCESS!");
                    setVar_AdvertisementMsg(
                        "Congratulations! Your working hours have been updated successfully."
                    );
                    setishiddensuccess(true);
                    setLoading(false);
                    setTimeout(() => {
                        setishiddensuccess(false);
                        handleOnClick("/workinghours/viewworkinghours");
                    }, 3000);
                } else {
                    setVar_PopupModelIcon(Alert);
                    setVar_AdvertisementMsgHead("FAILED!");
                    setVar_AdvertisementMsg(response.data.msg + "!");
                    setishiddensuccess(true);
                    setLoading(false);
                    setTimeout(() => {
                        setishiddensuccess(false);
                    }, 3000);
                }
            });
        } else {
            notification.error({ message: "Please select all flied" });
            setLoading(false);
            setisisDisable(false);
        }
    };

    // ================================== Final Submit Update start ==================================

    const checkAll = (e) => {
        setVar_SunValue(e.target.checked == true ? "1" : "");
        setVar_MonValue(e.target.checked == true ? "2" : "");
        setVar_TueValue(e.target.checked == true ? "3" : "");
        setVar_WedValue(e.target.checked == true ? "4" : "");
        setVar_ThuValue(e.target.checked == true ? "5" : "");
        setVar_FriValue(e.target.checked == true ? "6" : "");
        setVar_SatValue(e.target.checked == true ? "7" : "");
    };

    // ============================get clinic Wise DOctor Api Start=============================

    const getClinicWiseDoctor = () => {
        setLoading(true);
        var data = {
            clinic_id: localStorage.getItem("Clinic_id"),
        };
        Axios.post("clinic/getClinicWiseDoctor", data)
            .then((response) => {
                setLoading(false);

                var filteredData = response.data.data.filter(
                    (x) =>
                        x.specialityid == localStorage.getItem("speciality_Id") &&
                        x.doctor_id == localStorage.getItem("doctor_id")
                );
                setVar_DoctorDetailShow(
                    filteredData[0].doctorName +
                    "," +
                    " " +
                    filteredData[0].specialityname
                );
            })
            .catch((error) => {
                setLoading(false);
                console.log(error);
            });
    };

    // ============================get clinic Wise DOctor Api End=============================

    const timeToMinutes = (time) => {
        // Parse time in HH:mm format and convert to minutes
        const [hours, minutes] = time.split(":").map(Number);
        return hours * 60 + minutes;
    };

    const calculateTimeSlots = () => {
        const fromMinutes = timeToMinutes(Var_FromTime_Ref.current);
        const toMinutes = timeToMinutes(Var_ToTime_Ref.current);
        var timeParts = Var_SlotDuration_Ref.current.split(":");
        const slotDurationInMinutes =
            Number(timeParts[0]) * 60 + Number(timeParts[1]);
        const durationInMinutes = toMinutes - fromMinutes;
        const NoOfSlots = Math.floor(durationInMinutes / slotDurationInMinutes);
        if (NoOfSlots >= 0) {
            setVar_NoOfSlots(NoOfSlots);
        } else if (NoOfSlots < 0) {
            setVar_NoOfSlots(0);
        }

        // setVar_NoOfSlots(Math.floor(durationInMinutes / slotDurationInMinutes));
    };

    return (
        <div>
            {loading && (
                <div className="loader_body">
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "100vh",
                        }}
                    >
                        <CircularProgress />
                    </Box>
                </div>
            )}
            <div>
                <div>
                    <HeaderMasterComponent />
                </div>
                {/* ================= WorkingHours sub header start ================= */}
                <div className="Navication-header">
                    <div>
                        <img
                            src={BackIcon}
                            onClick={() => handleOnClick("/workinghours/viewworkinghours")}
                        />
                        <label
                            onClick={() => handleOnClick("/workinghours/viewworkinghours")}
                        >
                            Working Hours
                        </label>{" "}
                        <i class="fa fa-angle-left" aria-hidden="true"></i>
                        <label>Edit Working Hours</label>
                    </div>
                </div>
                {/* ================= WorkingHours sub header End ================= */}

                {/* ================== Edit workimg hours start ================== */}
                <div className="workinghours_content">
                    <div className="add-hours-content">
                        <div style={{ marginLeft: "55px" }}>
                            <Grid container>
                                <Grid item xs="4" className="worckselectors">
                                    <div>
                                        <p className="headlabel headerlab"> Specialty</p>
                                        <Grid container className="Select_Label_work">
                                            <Grid item xs={12} md={12} className="Column_">
                                                <div className="drop dropdown_button">
                                                    <TextField
                                                        id="outlined-basic"
                                                        placeholder={Var_SpecialityName}
                                                        variant="outlined"
                                                        fullWidth
                                                        sx={{
                                                            m: 1,
                                                            "& .MuiInputBase-input::placeholder": {
                                                                color: "#000", // Set your desired placeholder color
                                                            }
                                                        }}
                                                        InputProps={{
                                                            readOnly: true,
                                                        }}
                                                    />

                                                </div>
                                            </Grid>
                                        </Grid>
                                    </div>
                                </Grid>

                                <Grid item xs="4">
                                    <div>
                                        <p className="headlabel headerlab"> Service</p>
                                        <Grid container className="Select_Label_work">
                                            <Grid item xs={12} md={12} className="Column_">
                                                <div className="drop dropdown_button">
                                                    <FormControl fullWidth>
                                                        <InputLabel id="demo-simple-select-label">
                                                            {Var_ServiceName != null
                                                                ? ""
                                                                : "Select Service  "}
                                                        </InputLabel>

                                                        <TextField
                                                            id="outlined-basic"
                                                            placeholder={Var_ServiceName}
                                                            variant="outlined"
                                                            fullWidth
                                                            renderValue={(p) => p}
                                                            sx={{ m: 1 }}
                                                            InputProps={{
                                                                readOnly: true,
                                                            }}
                                                            onChange={(e) => getServiceValue(e)}
                                                        >
                                                            {Var_ServiceList.map((item, i) => {
                                                                return (
                                                                    <MenuItem
                                                                        key={i}
                                                                        value={item.id}
                                                                        serviceName={item.service}
                                                                    >
                                                                        {" "}
                                                                        <img
                                                                            src={item.file_path}
                                                                            className="drop_img"
                                                                        />
                                                                        {item.service}
                                                                    </MenuItem>
                                                                );
                                                            })}
                                                        </TextField>
                                                    </FormControl>
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </div>
                                </Grid>
                            </Grid>

                            <Grid container>
                                {" "}
                                <Grid item xs="4">
                                    <div style={{ marginLeft: "7px" }}>
                                        <p className="headlabel headerlabs">
                                            Slot Duration <span className="fontSize10">(hrs)</span>
                                        </p>
                                        <Grid container className="Select_Label_work">
                                            <Grid
                                                container
                                                item
                                                xs={12}
                                                md={12}
                                                className="Select_Label Select_Label_work_"
                                            >
                                                <Grid item xs="10" align="start" className="labtext">
                                                    {Var_SlotDuration}
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </div>
                                </Grid>
                                <Grid item xs="4" className="worckselectors">
                                    <div className="Appointment_Sizee">
                                        <p className="headlabel headerlab">Appointment Type</p>
                                        <Grid container className="Select_Label_work">
                                            <Grid item xs={12} md={12} className="Column_">
                                                <div className="drop dropdown_button">
                                                    <FormControl fullWidth>
                                                        <InputLabel id="demo-simple-select-label">
                                                            {Var_AppointmentType != null
                                                                ? ""
                                                                : "Appointment Type"}
                                                        </InputLabel>

                                                        <TextField
                                                            SelectProps={{
                                                                renderValue: (p) => p,
                                                            }}
                                                            value={Var_AppointmentType}
                                                            fullWidth
                                                            renderValue={(p) => p}
                                                            sx={{ m: 1 }}
                                                            InputProps={{
                                                                readOnly: false,
                                                            }}
                                                            onChange={(e) => getAppointmentTypeValue(e)}
                                                        >
                                                            {Var_AppointmentList.map((item, i) => {
                                                                return (
                                                                    <MenuItem key={i} value={item.id}>
                                                                        {item.id == 1 && (
                                                                            <>
                                                                                {" "}
                                                                                <img
                                                                                    src={Visit}
                                                                                    alt="a"
                                                                                    className="specialtyimg"
                                                                                />{" "}
                                                                                <span className="appointment_types_wrkhrs">
                                                                                    {item.appointment_type}{" "}
                                                                                </span>
                                                                            </>
                                                                        )}

                                                                        {item.id == 2 && (
                                                                            <>
                                                                                {" "}
                                                                                <img
                                                                                    src={Online}
                                                                                    alt="a"
                                                                                    className="specialtyimg"
                                                                                />{" "}
                                                                                <span className="appointment_types_wrkhrs">
                                                                                    {item.appointment_type}{" "}
                                                                                </span>{" "}
                                                                            </>
                                                                        )}

                                                                        {item.id == 3 && (
                                                                            <>
                                                                                {" "}
                                                                                <img
                                                                                    src={Fasttrack}
                                                                                    alt="a"
                                                                                    className="specialtyimg"
                                                                                />{" "}
                                                                                <span className="appointment_types_wrkhrs">
                                                                                    {item.appointment_type}{" "}
                                                                                </span>{" "}
                                                                            </>
                                                                        )}

                                                                        {item.id == 4 && (
                                                                            <>
                                                                                {" "}
                                                                                <img
                                                                                    src={InclinicOnline}
                                                                                    alt="a"
                                                                                    className="specialtyimg"
                                                                                />{" "}
                                                                                <span className="appointment_types_wrkhrs">
                                                                                    {item.appointment_type}{" "}
                                                                                </span>{" "}
                                                                            </>
                                                                        )}
                                                                        {/* <img className="drop_img" style={{ borderRadius: "5px" }} src={item.appointment_type == "In-Clinic" ? Visit : item.appointment_type == "Online" ? Online : item.appointment_type == "In-Clinic/Online" ? Walkin : item.appointment_type == "Fast Track" ? Fasttrack : ""}></img>
                                                                        {item.appointment_type} */}
                                                                    </MenuItem>
                                                                );
                                                            })}
                                                        </TextField>
                                                    </FormControl>
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </div>
                                </Grid>
                            </Grid>

                            <Grid container>
                                <Grid item xs="4" className="worckselectors">
                                    <div className="From_Tym">
                                        <p className="headlabel headerlabs">From Time</p>
                                        <Grid container>
                                            <Grid item className="ref_labs" align="start">
                                                <input
                                                    className="tymbox"
                                                    type="time"
                                                    id="appt"
                                                    name="appt"
                                                    readOnly
                                                    value={Var_FromTime}
                                                    onChange={(e) => getFromTimeValue(e)}
                                                />
                                            </Grid>
                                        </Grid>
                                    </div>
                                </Grid>

                                <Grid item xs="4" className="worckselectors">
                                    <div className="From_Tym">
                                        <p className="headlabel headerlabs">To Time</p>
                                        <Grid container>
                                            <Grid item className="ref_lab" align="start">
                                                <input
                                                    className="tymbox"
                                                    type="time"
                                                    id="appt"
                                                    name="appt"
                                                    readOnly
                                                    value={Var_ToTime}
                                                    onChange={(e) => getToTimeValue(e)}
                                                />
                                            </Grid>
                                        </Grid>
                                    </div>
                                </Grid>

                                <Grid container className="select_option">
                                    <Grid item xs="3" className="worckselectors">
                                        <div style={{ marginLeft: "10px", marginTop: "6vh" }}>
                                            <label className="headlabel headerlabs">
                                                No. of Slots :
                                            </label>
                                            <label className="slot_clr">
                                                {Var_NoOfSlots != null ? Var_NoOfSlots : 0}
                                            </label>
                                        </div>
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid container className="select_option">
                                <Grid item xs="12" className="selectEvery">
                                    <div className="selectEverytog">
                                        <FormControlLabel
                                            className="every_days"
                                            control={
                                                <Checkbox
                                                    sx={{ m: 1 }}
                                                    onChange={(e) => checkAll(e)}
                                                    checked={
                                                        Var_SunValue_Ref.current == "1" &&
                                                        Var_MonValue_Ref.current == "2" &&
                                                        Var_TueValue_Ref.current == "3" &&
                                                        Var_WedValue_Ref.current == "4" &&
                                                        Var_ThuValue_Ref.current == "5" &&
                                                        Var_FriValue_Ref.current == "6" &&
                                                        Var_SatValue_Ref.current == "7"
                                                    }
                                                />
                                            }
                                            label="All"
                                            value={Var_Days}
                                        />
                                    </div>
                                </Grid>
                            </Grid>
                        </div>

                        <div>
                            <Grid container className="DayButs">
                                <Grid xs={12} item className="DayButsad">
                                    <div className="TotalsingleDay">
                                        <div className="singleDay">
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        onChange={(e) => changeSunValue(e)}
                                                        checked={
                                                            Var_SunValue_Ref.current == "1" ? true : false
                                                        }
                                                    />
                                                }
                                                label="Sun"
                                            />
                                        </div>
                                        <div className="singleDay">
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        onChange={(e) => changeMonValue(e)}
                                                        checked={
                                                            Var_MonValue_Ref.current == "2" ? true : false
                                                        }
                                                    />
                                                }
                                                label="Mon"
                                            />
                                        </div>
                                        <div className="singleDay">
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        onChange={(e) => changeThuValue(e)}
                                                        checked={
                                                            Var_TueValue_Ref.current == "3" ? true : false
                                                        }
                                                    />
                                                }
                                                label="Tue"
                                            />
                                        </div>
                                        <div className="singleDay">
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        onChange={(e) => changeWedValue(e)}
                                                        checked={
                                                            Var_WedValue_Ref.current == "4" ? true : false
                                                        }
                                                    />
                                                }
                                                label="Wed"
                                            />
                                        </div>
                                        <div className="singleDay">
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        onChange={(e) => changeTueValue(e)}
                                                        checked={
                                                            Var_ThuValue_Ref.current == "5" ? true : false
                                                        }
                                                    />
                                                }
                                                label="Thu"
                                            />
                                        </div>
                                        <div className="singleDay">
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        onChange={(e) => changeFriValue(e)}
                                                        checked={
                                                            Var_FriValue_Ref.current == "6" ? true : false
                                                        }
                                                    />
                                                }
                                                label="Fri"
                                            />
                                        </div>
                                        <div className="singleDay">
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        onChange={(e) => changeSatValue(e)}
                                                        checked={
                                                            Var_SatValue_Ref.current == "7" ? true : false
                                                        }
                                                    />
                                                }
                                                label="Sat"
                                            />
                                        </div>
                                    </div>
                                </Grid>
                            </Grid>
                            <div className="add-dvsion">
                                <Button
                                    className="botton_Add_hours"
                                    disabled={isDisable_Ref.current}
                                    onClick={() => UpdateWorkingHours()}
                                >
                                    {" "}
                                    Update{" "}
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>

                {/* ================= Working hours Update Success Model Start =============== */}
                {isshowsuccess && (
                    <div className="popup-main">
                        <div className="pushNotification_body">
                            <div className="pushNotification_content">
                                <p className="notification_text_head">
                                    <b>{Var_AdvertisementMsg_Ref.current}</b>
                                </p>
                            </div>
                            <div className="progress-prt">
                                <div className="progress-bar"></div>
                            </div>
                        </div>
                    </div>
                )}
                {/* ================= Working hours Update Success Model End  ================= */}
            </div>
            {/* Edit working hours end  */}
        </div>
    );
};
export default EditWorkingHoursComponent;
