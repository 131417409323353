// import { notification, Spin } from "antd";
// import { useHistory } from "react-router-dom";
// import React, { useState, useEffect } from "react";
// import axios from "axios";
// import useStateRef from "react-usestateref";
// import { Container } from 'react-bootstrap';
// import LinearProgress from '@mui/material/LinearProgress';

// // Mui meterial component import
// import Grid from '@mui/material/Grid';
// import Card from '@mui/material/Card';
// import FormControl from "@mui/material/FormControl";
// import Select from "@mui/material/Select";
// import InputLabel from "@mui/material/InputLabel";
// import FormControlLabel from '@mui/material/FormControlLabel';
// import { MenuItem } from '@mui/material';
// import Button from '@mui/material/Button';
// import Dialog, { DialogProps } from '@mui/material/Dialog';
// import DialogContent from '@mui/material/DialogContent';
// import TextField from '@mui/material/TextField';
// import CircularProgress from '@mui/material/CircularProgress';
// import Box from '@mui/material/Box';

// import IOSSwitch from "../../helpers/Switch/Switch";


// import './WorkingHoursMaster.css';
// import vector from '../../assets/Doctor_web_Icon/Vector.png'
// import successModelTickImg from '../../assets/Web_Doctor_Icon_new_theme/Success.svg'
// import Visit from '../../assets/Web_Doctor_Icon_new_theme/Member.svg'
// import Online from '../../assets/Web_Doctor_Icon_new_theme/Online.svg'
// import Fasttrack from '../../assets/Web_Doctor_Icon_new_theme/fast_track.svg'
// import InclinicOnline from '../../assets/Web_Doctor_Icon_new_theme/inclinic.svg'
// import Walkin from '../../assets/Doctor_web_Icon/Walkin.svg'
// import FooterMaster from '../Dashboard/FooterMaster';
// import Service from "../../helpers/Service";
// import Alert from "../../assets/Images/alert.svg";

// const WorkingHoursComponent = () => {
//     let Var_History = useHistory();

//     const [isshowsuccess, setishiddensuccess] = useState(false);
//     const [Var_Specialitydata, setVar_Specialitydata] = React.useState([]);
//     const [Var_Appoinmenetdata, setVar_Appoinmenetdata] = React.useState([]);
//     const [Var_Servicedata, setVar_Servicedata, Var_Servicedata_Ref] = useStateRef([]);
//     const [Var_SlotDuration, setVar_SlotDuration, Var_SlotDuration_Ref] = useStateRef("");
//     const [Var_ServicedataSelect, setVar_ServicedataSelect, Var_ServicedataSelect_Ref] = useStateRef("");
//     const [Var_AppointmentlistSelect, setVar_AppointmentlistSelect, Var_AppointmentlistSelect_Ref] = useStateRef("");
//     const [colleteralDocumentVendorType, setCollateralDocumentVendorType, CollateralDocumentVendorType_Ref] = useStateRef([]);
//     const [appointmentTypeLists, setappointmentTypeLists] = React.useState([]);
//     const [serviceDocumentVendorType, setserviceDocumentVendorType, setserviceDocumentVendorType_Ref] = useStateRef("");
//     const [Adddata, setVarAdddata] = React.useState("");
//     const [Var_speciality_id, setVar_speciality_id, Var_speciality_idref] = useStateRef("");
//     const [Var_Service_Id, setVar_Service_Id, Var_Service_Id_Ref] = useStateRef("");
//     const [Var_Slot_Duration, setVar_Slot_Duration, Var_Slot_Duration_Ref] = useStateRef("");
//     const [Varfromtimeisvalid, setVarfromtimeisvalid] = useState(false);
//     const [Var_Everyday, setVar_Everyday, Var_Everyday_Ref] = useStateRef(false); // defaultChecked
//     const [Var_Sunday, setVar_Sunday, Var_Sunday_Ref] = useStateRef(false); // defaultChecked
//     const [Var_Monday, setVar_Monday, Var_Monday_Ref] = useStateRef(false); // defaultChecked
//     const [Var_Tueday, setVar_Tueday, Var_Tueday_Ref] = useStateRef(false);; // defaultChecked
//     const [Var_Wedday, setVar_Wedday, Var_Wedday_Ref] = useStateRef(false);; // defaultChecked
//     const [Var_Thuday, setVar_Thuday, Var_Thuday_Ref] = useStateRef(false); // defaultChecked
//     const [Var_Friday, setVar_Friday, Var_Friday_Ref] = useStateRef(false); // defaultChecked
//     const [Var_Satday, setVar_Satday, Var_Satday_Ref] = useStateRef(false); // defaultChecked
//     const [Var_Specialty, setVar_Specialty] = useState(""); // defaultChecked
//     const [Var_SpecialtyId, setVar_SpecialtyId, Var_SpecialtyId_Ref] = useStateRef(0); // defaultChecked
//     const [Var_Appointmentlist, setVar_Appointmentlist, setVar_Appointmentlist_Ref] = useStateRef("");// defaultChecked
//     const [Var_Service, setVar_Service, Var_Service_Ref] = useStateRef(); // defaultChecked
//     const [Var_ServiceId, setVar_ServiceId, Var_ServiceId_Ref] = useStateRef(""); // defaultChecked
//     const [Var_WorkingDays, setVar_WorkingDays, Var_WorkingDays_Ref] = useStateRef([]);
//     const [fullWidth, setFullWidth] = React.useState(true);
//     const [Var_ToTime, setVar_ToTime, Var_ToTime_Ref] = useStateRef("");
//     const [Var_FromTime, setVar_FromTime, Var_FromTime_Ref] = useStateRef("");
//     const [Var_showFromTime, setVar_showFromTime, Var_showFromTime_Ref] = useStateRef("");
//     const [Var_showToTime, setVar_showToTime, Var_showToTime_Ref] = useStateRef("");
//     const [Var_NoOfSlots, setVar_NoOfSlots, Var_NoOfSlots_Ref] = useStateRef(0);
//     const [loading, setLoading] = useState(true);

//     const Var_WorkingHoursButtonName = localStorage.getItem("buttonName")
//     const [Var_DoctorDetailShow, setVar_DoctorDetailShow, Var_DoctorDetailShow_Ref] = useStateRef(null);
//     const [Var_DoctorSpeciality, setVar_DoctorSpeciality, Var_DoctorSpeciality_Ref] = useStateRef(null);
//     // Check for 3 step verification
//     const [verification, setVerification] = useState(Var_History.location.state ? (Var_History.location.state.verification ? Var_History.location.state.verification : false) : false);
//     const [Var_DoctorClinicSelect, setVar_DoctorClinicSelect, Var_DoctorClinicSelect_Ref] = useStateRef(null);
//     const [Var_doctorClinic, setVar_doctorClinic, Var_doctorClinic_Ref] = useStateRef(null);
//     const [Var_doctorSpeciality, setVar_doctorSpeciality, Var_doctorSpeciality_Ref] = useStateRef(null);
//     const [Var_DoctorSpecialtySelect, setVar_DoctorSpecialtySelect, Var_DoctorSpecialtySelect_Ref] = useStateRef([]);
//     const [Var_SpecialtyName, setVar_SpecialtyName, Var_SpecialtyName_Ref] = useStateRef([]);
//     const [Var_AdvertisementMsg, setVar_AdvertisementMsg, Var_AdvertisementMsg_Ref] = useStateRef("");
//     const [Var_AdvertisementMsgHead, setVar_AdvertisementMsgHead, Var_AdvertisementMsgHead_Ref] = useStateRef("");
//     const [Var_PopupModelIcon, setVar_PopupModelIcon, Var_PopupModelIcon_Ref] = useStateRef("");
//     const [,setisisDisable,isDisable_Ref] = useStateRef(false);

//     // initial function call start
//     useEffect(() => {
//         getAppointmenttype();
//         getEveryDays();
//         // getClinicWiseDoctor();
//         getspeciality();
//         // getClinicDoctor();
//         getdoctorSpeciality();
//         setisisDisable(false);
//     }, [])
//     // initial function call End


//     // navigattion to back start
//     const handleOnClick = (path) => {
//         Var_History.push("/dashboard" + path)
//     };
//     // navigattion to back End


//     // getEveryDays Function Call Start
//     const getEveryDays = () => {
//         if (Var_Sunday == true && Var_Monday == true && Var_Tueday == true && Var_Wedday == true && Var_Thuday == true && Var_Friday == true && Var_Satday == true) {
//             setVar_Everyday(true);
//         }
//     }
//     const Everyday = (e) => {
//         if (e.target.checked) {
//             setVar_Everyday(true);
//             setVar_Sunday(true);
//             setVar_Monday(true);
//             setVar_Tueday(true);
//             setVar_Wedday(true);
//             setVar_Thuday(true);
//             setVar_Friday(true);
//             setVar_Satday(true);
//         }

//         else {
//             setVar_Everyday(false);
//             setVar_Sunday(false);
//             setVar_Monday(false);
//             setVar_Tueday(false);
//             setVar_Wedday(false);
//             setVar_Thuday(false);
//             setVar_Friday(false);
//             setVar_Satday(false);

//         }
//     }
//     const Sunday = (e) => {
//         console.log(e.target.value)
//         if (e.target.checked) {
//             setVar_Sunday(true);

//         }
//         else {
//             setVar_Sunday(false);
//         }

//     }
//     const Monday = (e) => {
//         if (e.target.checked) {
//             setVar_Monday(true);
//         } else {
//             setVar_Monday(false);

//         }
//     }
//     const Tueday = (e) => {
//         if (e.target.checked) {
//             setVar_Tueday(true);
//         } else {
//             setVar_Tueday(false);

//         }
//     }
//     const Wedday = (e) => {
//         if (e.target.checked) {
//             setVar_Wedday(true);
//         } else {
//             setVar_Wedday(false);

//         }
//     }
//     const Thuday = (e) => {
//         if (e.target.checked) {
//             setVar_Thuday(true);
//         } else {
//             setVar_Thuday(false);

//         }
//     }
//     const Friday = (e) => {
//         if (e.target.checked) {
//             setVar_Friday(true);
//         } else {
//             setVar_Friday(false);

//         }
//     }
//     const Satday = (e) => {
//         if (e.target.checked) {
//             setVar_Satday(true);
//         } else {
//             setVar_Satday(false);

//         }
//     }
//     const okClose = () => {
//         setishiddensuccess(!isshowsuccess);
//         if (!verification) {
//             handleOnClick("/workinghours/viewworkinghours")
//         }
//         else {
//             Var_History.push("/dashboard")
//         }

//         setVarfromtimeisvalid(false);

//     }
//     // getEveryDays Function Call End
//     const dataclear = () => {
//         setVar_AppointmentlistSelect({
//             "id": 0,
//             "appointment_type": "Appointment Type"
//         });
//         setVar_ServicedataSelect({
//             "id": 0,
//             "doctor_id": "",
//             "service": "Select Service",
//             "cost": "",
//             "slot_duration": "",
//             "description": "",
//             "file_path": "",
//             "file_name": "",
//             "is_active": "",
//             "spec_id": ""
//         });
//         setVar_SpecialtyName("");
//         setVar_doctorSpeciality("");
//         setVar_SlotDuration("");
//         setVar_NoOfSlots("");
//         setVar_showFromTime("");
//         setVar_showToTime("");
//         setVar_Service("");
//         setVar_Appointmentlist("");
//         setVar_WorkingDays("");
//         setVar_FromTime("");
//         setVar_ToTime("");
//     }
//     // ==================================Get speciality APi Start====================================

//     const getspeciality = () => {
//         setLoading(true);
//         var data = {
//             doctor_id: localStorage.getItem("Doctor_id")
//         };
//         axios

//             ({
//                 method: "POST",
//                 url: "doctor/getSpecialitybyDoctor",
//                 data: data,
//             })

//             .then((response) => {
//                 {

//                     console.log(response.data.data);

//                     setVar_Specialitydata(response.data.data)
//                     setVar_speciality_id(response.data.data)
//                     setCollateralDocumentVendorType(response.data.data[0].id)
//                     setVar_Specialty(response.data.data[0].speciality);
//                     setVar_SpecialtyId(response.data.data[0].id);
//                     setLoading(false);


//                 }
//                 // getService();
//                 setLoading(false);
//             })
//             .catch((error) => {
//                 console.log(error);
//                 setLoading(false);
//             });
//     }
//     // ==================================Get speciality APi End====================================


//     // ==================================Get Service Api Start=====================================
//     const getService = (splId) => {
//         setLoading(true);
//         var data = {
//             doctor_id: localStorage.getItem("Doctor_id"),
//             speciality_id: splId
//         };
//         axios({
//             method: "POST",
//             url: "doctor/getDoctorServiceBySpecilaity",
//             data: data,
//         })
//             .then((response) => {

//                 {
//                     setVar_Servicedata(response.data.data.filter(x => x.is_active == 1));
//                     setVar_ServicedataSelect({
//                         "id": 0,
//                         "doctor_id": "",
//                         "service": "Select Service",
//                         "cost": "",
//                         "slot_duration": "",
//                         "description": "",
//                         "file_path": "",
//                         "file_name": "",
//                         "is_active": "",
//                         "spec_id": ""
//                     });
//                     setLoading(false);
//                 }
//             })
//             .catch((error) => {
//                 console.log(error);
//                 setLoading(false);
//             });
//     }
//     // ==================================Get Service Api End=====================================
//     const clearData = () => {
//         // setVar_SlotDuration('');
//         // setVar_SlotDuration("");
//         // setVar_NoOfSlots(0)
//     }

//     // ==================================Get appointmenttype Api Start===========================
//     const getAppointmenttype = () => {
//         setLoading(true);
//         axios({
//             method: "GET",
//             url: "doctor/get_mas_appointment_type",

//         })
//             .then((response) => {

//                 {
//                     console.log("Appointment", response.data.data)
//                     setVar_Appoinmenetdata(response.data.data)
//                     setVar_Appointmentlist(response.data.data.appointment_type)
//                     setVar_AppointmentlistSelect({
//                         "id": 0,
//                         "appointment_type": "Appointment Type",
//                     })
//                     const Var_EditWorkingListDetails = JSON.parse(localStorage.getItem("editWorking"));
//                     if (Var_EditWorkingListDetails != null) {
//                         setCollateralDocumentVendorType(Var_EditWorkingListDetails.speciality_id)
//                         setserviceDocumentVendorType(Var_EditWorkingListDetails.service_id)
//                         setVar_SlotDuration(Var_EditWorkingListDetails.slot_duration)
//                         setappointmentTypeLists(Var_EditWorkingListDetails.appointment_type)

//                         setVar_Specialty(Var_EditWorkingListDetails.speciality_name)
//                         setVar_Service(Var_EditWorkingListDetails.service_name)
//                         setVar_Appointmentlist(Var_EditWorkingListDetails.appointment_type)


//                         localStorage.removeItem("editWorking")
//                         setLoading(false);
//                     }
//                     setLoading(false);
//                 }
//             })
//             .catch((error) => {
//                 console.log(error);
//                 setLoading(false);
//             });
//     }
//     // ==================================Get appointmenttype Api End===========================


//     // ==================================ADD Working List Api Start============================

//     const Add = async () => {
//         setisisDisable(true);
//         try {
//             for (let i = 0; i < Var_Servicedata.length; i++) {
//                 if (Var_Servicedata[i].service == Var_Service_Ref.current) {
//                     setVar_Service_Id(Var_Servicedata[i].id);
//                     setVar_Slot_Duration(Var_Servicedata[i].slot_duration);
//                 }
//             }


//             let workingDays = [];
//             if (
//                 Var_Sunday_Ref.current &&
//                 Var_Monday_Ref.current &&
//                 Var_Tueday_Ref.current &&
//                 Var_Wedday_Ref.current &&
//                 Var_Thuday_Ref.current &&
//                 Var_Friday_Ref.current &&
//                 Var_Satday_Ref.current
//             ) {
//                 workingDays.push(1, 2, 3, 4, 5, 6, 7);
//             }
//             else {
//                 if (Var_Sunday_Ref.current) {
//                     workingDays.push(1);
//                 }

//                 if (Var_Monday_Ref.current) {
//                     workingDays.push(2);
//                 }

//                 if (Var_Tueday_Ref.current) {
//                     workingDays.push(3);
//                 }
//                 if (Var_Wedday_Ref.current) {
//                     workingDays.push(4);
//                 }
//                 if (Var_Thuday_Ref.current) {
//                     workingDays.push(5);
//                 }
//                 if (Var_Friday_Ref.current) {
//                     workingDays.push(6);
//                 }
//                 if (Var_Satday_Ref.current) {
//                     workingDays.push(7);
//                 }
//             }
//             debugger;

//             setLoading(true);
//             var Var_data = {
//                 clinicId: localStorage.getItem("Clinic_id"),
//                 serviceId: Var_ServiceId_Ref.current,
//                 fromtime: await Service.conert_12Hto_24HTime(Var_showFromTime_Ref.current),
//                 totime: await Service.conert_12Hto_24HTime(Var_showToTime_Ref.current),
//                 slotduration: Var_Slot_Duration_Ref.current,
//                 NoOfslots: Var_NoOfSlots_Ref.current,
//                 days: workingDays,
//                 doctorId: localStorage.getItem("Doctor_id"),
//                 appointmentType: appointmentTypeLists,
//                 createdby: localStorage.getItem("Doctor_id"),
//                 ipaddress: "192.168.1.45",
//                 // specialityId: localStorage.getItem("speciality_Id")
//                 specialityId: Var_doctorSpeciality_Ref.current
//             };

//             if (Var_NoOfSlots_Ref.current != 0 && Var_ServiceId_Ref.current != "" && Var_showFromTime_Ref.current != "" && Var_showToTime_Ref.current != "" && Var_Slot_Duration_Ref.current != "" && appointmentTypeLists != "" && workingDays != "") {
//                 axios({
//                     method: "POST",
//                     url: "doctor/insertdocAppointmentSettings",
//                     data: Var_data
//                 })
//                     .then((response) => {
//                         setisisDisable(false);
//                         setLoading(false);
//                         {
//                             console.log(response.data.data[0]);
//                             setVarAdddata(response.data.data);
//                             setLoading(false);
//                             if (response.data.status == 1) {
//                                 setVar_PopupModelIcon(successModelTickImg);
//                                 setVar_AdvertisementMsgHead("SUCCESS!");
//                                 setVar_AdvertisementMsg("Working Hours added successfully!")
//                                 setishiddensuccess(!isshowsuccess);
//                                 setLoading(false);
//                                 dataclear();
//                             } else {
//                                 setVar_PopupModelIcon(Alert);
//                                 setVar_AdvertisementMsgHead("FAILED!");
//                                 setVar_AdvertisementMsg(response.data.msg + "!")
//                                 setishiddensuccess(!isshowsuccess);
//                                 setLoading(false);
//                             }
//                         }
//                     })
//             } else {
//                 notification.error({ message: "Please select all flied" });
//                 setLoading(false);
//                 setisisDisable(false);
//             }
//         } catch (e) {
//             setisisDisable(false);
//             setLoading(false);
//             console.log(e);
//         }
//     }

//     // ==================================ADD Working List Api End==============================


//     // ========================= No Of Slot Showing in Working Hours Start=====================

//     const timeToMinutes = (time) => {
//         // Parse time in HH:mm format and convert to minutes
//         const [hours, minutes] = time.split(":").map(Number);
//         return hours * 60 + minutes;
//     }

//     const calculateTimeSlots = () => {
//         debugger
//         const fromMinutes = timeToMinutes(Var_FromTime_Ref.current);
//         const toMinutes = timeToMinutes(Var_ToTime_Ref.current);
//         var timeParts = Var_SlotDuration_Ref.current.split(":");
//         const slotDurationInMinutes = Number(timeParts[0]) * 60 + Number(timeParts[1]);
//         const durationInMinutes = toMinutes - fromMinutes;
//         if (Var_SlotDuration_Ref.current == "") {
//             debugger;
//             notification.error({ message: "Please select service first" })
//         } else {
//             debugger;
//             const NoOfSlots = (Math.floor(durationInMinutes / slotDurationInMinutes));
//             if (NoOfSlots >= 0) {
//                 debugger;
//                 setVar_NoOfSlots(NoOfSlots)
//             } else if (NoOfSlots < 0) {
//                 debugger;
//                 setVar_NoOfSlots(0)
//             }
//             console.log(NoOfSlots);
//         }


//     }


//     const handleFromTimeChange = (event) => {

//         setVar_FromTime(event.target.value);
//         console.log("Fromtime", event.target.value);
//         handleTimeConversion()
//     }
//     const handleTimeChange = (event) => {
//         if(Var_FromTime_Ref.current < event.target.value){
//         setVar_ToTime(event.target.value);
//         console.log("totime", event.target.value);
//         handleTimeConversion()
//         }else {
//             notification.error({ message: "Please select a To time greater than the From time." });
//             setVar_ToTime("");
//             setVar_NoOfSlots(0);
//         }
//     }


//     const convertTo12HourFormat = (fromtime, totime) => {
//         var timeComponentsfrom = fromtime.split(":");
//         var timeComponentsto = totime.split(":");
//         var hoursfrom = parseInt(timeComponentsfrom[0], 10);
//         var hoursto = parseInt(timeComponentsto[0], 10);
//         var minutesfrom = parseInt(timeComponentsfrom[1], 10);
//         var minutesto = parseInt(timeComponentsto[1], 10);

//         // Check if it's AM or PM
//         var periodfrom = hoursfrom >= 12 ? "PM" : "AM";
//         var periodto = hoursto >= 12 ? "PM" : "AM";

//         // Convert to 12-hour format
//         var hourIn12HrFormatfrom = hoursfrom % 12 || 12;
//         var hourIn12HrFormatto = hoursto % 12 || 12;

//         // Format the time with "am" or "pm" indicator
//         var formattedTimefrom = hourIn12HrFormatfrom + ":" + minutesfrom.toString().padStart(2, '0') + " " + periodfrom;
//         var formattedTimeto = hourIn12HrFormatto + ":" + minutesto.toString().padStart(2, '0') + " " + periodto;
//         setVar_showFromTime(formattedTimefrom)
//         setVar_showToTime(formattedTimeto)
//         // return formattedTimefrom,formattedTimeto;
//     }

//     // Example usage:
//     const getSpecialityValue = (event) => {
//         setVar_doctorSpeciality(event.target.value);
//         setVar_SpecialtyId(event.target.value);
//     }

//     const handleTimeConversion = () => {
//         var fromtime = Var_FromTime_Ref.current; // Assuming you have refs set correctly.
//         var totime = Var_ToTime_Ref.current;
//         convertTo12HourFormat(fromtime, totime);
//     };

//     // ========================= No Of Slot Showing in Working Hours End=====================


//     const getdoctorSpeciality = () => {
//         setLoading(true);
//         var data = {
//             // doctorId: localStorage.getItem("Doctor_ID")
//             doctorId: localStorage.getItem("Doctor_id")
//         };
//         axios
//             .post("doctor/getDoctorDetails", data)
//             .then((response) => {
//                 // var filteredData = response.data.data.filter(x => x.specialityid == localStorage.getItem("speciality_Id") && x.doctor_id == localStorage.getItem("doctor_id"));
//                 // setVar_DoctorDetailShow(filteredData[0].doctorName + ' ' + filteredData[0].specialityname);
//                 // setVar_DoctorSpeciality(filteredData[0].specialityname)
//                 const doctorSpeciality = JSON.parse(response.data.data[0].speciality)
//                 setVar_doctorSpeciality(doctorSpeciality[0].specialityId);
//                 setVar_SpecialtyName(doctorSpeciality[0].speciality);
//                 // localStorage.setItem("speciality_Id", doctorSpeciality[0].specialityId)
//                 setVar_DoctorSpecialtySelect(JSON.parse(response.data.data[0].speciality));
//                 // JSON.parse(specialitiesString);
//                 console.log("fjkbvsfkjb", JSON.parse(response.data.data[0].speciality));
//                 getService(doctorSpeciality[0].specialityId);
//                 doctorSpeciality(doctorSpeciality[0].specialityId,doctorSpeciality[0].speciality);
//                 setLoading(false);

//             })
//             .catch((error) => {
//                 setLoading(false);
//                 console.log(error);
//             });
//     }
//     // const getClinicDoctor = () => {
//     //     setLoading(true);
//     //     debugger;
//     //     var data = {
//     //         doctorId: localStorage.getItem("Doctor_id")
//     //     };
//     //     axios
//     //         .post("doctor/getDoctorClinics", data)
//     //         .then((response) => {
//     //             debugger;
//     //             localStorage.setItem("Clinic_id", response.data.data[0].clinicId)
//     //             // setVar_getClinicWiseDoctor(response.data.data);
//     //             setVar_doctorClinic(response.data.data[0].clinicId)
//     //             console.log("response.data.data[0].clinicId", response.data.data[0].clinicId);
//     //             console.log("setVar_DoctorClinicSelect", response.data.data);
//     //             // setVar_DoctorClinicSelect(response.data.data);
//     //             // localStorage.setItem("speciality_Id", response.data.data[0].specialityid);
//     //             // clinicTabPermissionById()
//     //             // checkThreeStepVerification(response.data.data[0].doctor_id)
//     //             setLoading(false);

//     //         })
//     //         .catch((error) => {
//     //             setLoading(false);
//     //             console.log(error);
//     //         });
//     // }
//     const doctorClinic = (e) => {
//         // alert(e.target.value);
//         setVar_doctorClinic(e.target.value);
//         // localStorage.setItem("Clinic_id", e.target.value);
//         // getAllAppointment();
//     }
//     const doctorSpeciality = (id, name) => {
//         console.log("acv", id, name);
//         // let Speciality = Var_DoctorSpecialtySelect.filter(x => x.specialityId == e.target.value);
//         setVar_SpecialtyName(name);
//         setVar_doctorSpeciality(id);
//         getService(id);
//         // localStorage.setItem("Clinic_id", e.target.value);
//         // getAllAppointment();
//     }



//     return (
//         <div>
//             {/* {loading &&
//                 <div className="loader_body">
//                     <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
//                         <CircularProgress />
//                     </Box>
//                 </div>
//             } */}
//             {loading &&
//                 <LinearProgress color="secondary" className="progressBar" sx={{ zIndex: '9999' }} />
//             }

//             {/* ================= WorkingHours sub header start ================= */}
//             <div className="sub_header">
//                 <div className="sub_header_body">
//                     <div className="back_navigation"
//                         onClick={() => { handleOnClick("") }}
//                     >
//                         <i class="fa fa-angle-left" aria-hidden="true"></i>
//                         <span>WORKING HOURS</span>
//                     </div>
//                     <div className="flex_grow"></div>
//                     <div className="header_right_contents">
//                         {/* <div>
//                             <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
//                                 <Select
//                                     labelId="demo-select-small-label"
//                                     id="demo-select-small"
//                                     SelectProps={{
//                                         renderValue: (p) => p
//                                     }}
//                                     value={Var_DoctorDetailShow_Ref.current}
//                                     readOnly={true}
//                                     renderValue={(p) => p}

//                                 >
//                                 </Select>
//                             </FormControl>
//                         </div> */}
//                         {/* <div className="workinghours_dropdown">
//                             <FormControl fullWidth sx={{ m: 1, minWidth: 200 }}
//                                 size="small">
//                                 <Select size="small" className="selectoption"
//                                     // sx={{ m: 1, minHeight: 1, minWidth: 100 }}

//                                     id="demo-simple-select"
//                                     value={Var_doctorClinic}
//                                     onChange={(e) => {
//                                         doctorClinic(e)
//                                     }}  >
//                                     {Var_DoctorClinicSelect?.map((item) => (
//                                         <MenuItem
//                                             // key={item.specialityname}
//                                             value={item.clinicId}>
//                                             {item.clinicName}
//                                         </MenuItem>
//                                     ))}
//                                 </Select>
//                             </FormControl>
//                         </div> */}
//                         <div>
//                             <button className="Workingview_btn"
//                                 onClick={() => handleOnClick("/workinghours/viewworkinghours")}
//                             >
//                                 <span>View</span>
//                             </button>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//             <div>
//                 <div className='workinghours_content'>

//                     {/* ================== WorkingHours sub header End ================== */}


//                     <Container>
//                         {/* ================= Working Hours Content Field Start ================= */}
//                         <Container>

//                             <Grid container className='select_option'>
//                                 <Grid item xs='3' className='worckselectors'>
//                                     <div  >
//                                         <p className='headlabel headerlab'> Specialty</p>
//                                         <Grid container className=' Select_Label_work'>
//                                             <Grid item className='Column_'>
//                                                 <div className='drop dropdown_button'>
//                                                     <FormControl fullWidth >
//                                                         <Select size="small" className="selectoption" sx={{ m: 1, minHeight: 15, minWidth: 250 }}
//                                                             // sx={{ m: 1, minHeight: 1, minWidth: 100 }}
//                                                             // sx={{ m: 1, minWidth: 200 }} size="small"
//                                                             id="demo-simple-select"
//                                                             value={Var_doctorSpeciality}
//                                                             onChange={(e) => {
//                                                                 const selectedSpecialityId = e.target.value; // Get the selected specialityId
//                                                                 const selectedSpecialityName = Var_DoctorSpecialtySelect_Ref.current?.find(item => item.specialityId === selectedSpecialityId)?.speciality;

//                                                                 doctorSpeciality(selectedSpecialityId, selectedSpecialityName);
//                                                                 // doctorSpeciality(e)
//                                                             }}

//                                                         // value={Var_SpecialtyId}
//                                                         // onChange={e => getSpecialityValue(e)}
//                                                         >
//                                                             {/* {Var_DoctorSpecialtySelect_Ref.current?.map((item) => (
//                                                                 <MenuItem
//                                                                     key={item.speciality}
//                                                                     value={item.specialityId}
//                                                                     name={item.speciality}>
//                                                                     {item.speciality}
//                                                                 </MenuItem>
//                                                             ))} */}
//                                                             {Var_Specialitydata.map((item) => (
//                                                                 <MenuItem
//                                                                     key={item.speciality}
//                                                                     value={item.id}
//                                                                 >
//                                                                     {item.speciality}
//                                                                 </MenuItem>
//                                                             ))}

//                                                         </Select>
//                                                     </FormControl>

//                                                     {/* <TextField id="outlined-basic" placeholder={Var_DoctorSpeciality_Ref.current} variant="outlined" sx={{ m: 1, minHeight: 8, minWidth: 250 }}
//                                                         InputProps={{
//                                                             readOnly: true,
//                                                         }}
//                                                     /> */}
//                                                     {/* <InputLabel id="demo-simple-select-label">Select Specialty</InputLabel> */}

//                                                     {/* value={Var_SpecialtyId}
//                                                             onChange={e => {
//                                                                 Var_Specialitydata.forEach((item) => {
//                                                                     if (e.target.value.id == item.id) {
//                                                                         setVar_SpecialtyId(item.id);
//                                                                         setVar_Specialty(item.speciality);
//                                                                     }
//                                                                 });
//                                                             }}
//                                                             renderValue={() => {
//                                                                 return Var_Specialty
//                                                             }}
//                                                          */}
//                                                     {/* {Var_Specialitydata.map((item) => (
//                                                                 <MenuItem
//                                                                     key={item.speciality}
//                                                                     value={item.id}
//                                                                 >

//                                                                 </MenuItem>
//                                                             ))}
//                                                        */}


//                                                 </div>
//                                             </Grid>
//                                         </Grid>

//                                     </div>
//                                 </Grid>

//                                 <Grid item xs='3' className='worckselectors'>
//                                     <div>
//                                         <p className='headlabel headerlab'> Service</p>
//                                         <Grid container className='Select_Label_work'>
//                                             <Grid item className='Column_'>
//                                                 <div className='drop dropdown_button'>
//                                                     <FormControl fullWidth>

//                                                         {/* <InputLabel id="demo-simple-select-label">Select Service</InputLabel> */}
//                                                         <Select size="small" className="selectoption" sx={{ m: 1, minHeight: 15, minWidth: 250 }}
//                                                             id="demo-simple-select"
//                                                             //value={serviceDocumentVendorType}
//                                                             value={Var_ServicedataSelect}
//                                                             onChange={e => {
//                                                                 setserviceDocumentVendorType(e.target.value.id);
//                                                                 setVar_ServiceId(e.target.value.id);
//                                                                 let servicelist = Var_Servicedata.filter(x => x.id == e.target.value.id);
//                                                                 setVar_Service(servicelist[0].service);
//                                                                 setVar_ServicedataSelect(e.target.value);
//                                                                 Var_Servicedata.forEach((item) => {
//                                                                     if (item.id == e.target.value.id) setVar_SlotDuration(item.slot_duration)
//                                                                 });
//                                                                 { calculateTimeSlots() }
//                                                             }}
//                                                             renderValue={(selected) => (
//                                                                 // <Container>
//                                                                     <p className="selectPval">
//                                                                         {selected.service}
//                                                                     </p>
//                                                                 // </Container>
//                                                             )}
//                                                         >
//                                                             <MenuItem key={0} value={{
//                                                                 "id": 0,
//                                                                 "doctor_id": "",
//                                                                 "service": "Select Service",
//                                                                 "cost": "",
//                                                                 "slot_duration": "",
//                                                                 "description": "",
//                                                                 "file_path": "",
//                                                                 "file_name": "",
//                                                                 "is_active": "",
//                                                                 "spec_id": ""
//                                                             }}>
//                                                             </MenuItem>
//                                                             {Var_Servicedata.map((item) => (
//                                                                 <MenuItem
//                                                                     key={item.service}
//                                                                     value={item}
//                                                                 >
//                                                                     {item.is_active == 1 &&
//                                                                         <Card className='specialtyimgRow'>
//                                                                             <Grid container>
//                                                                                 <Grid item xs='1' align="start">
//                                                                                     <img src={item.file_path == " " ? vector : item.file_path == "[object Object]" ? vector : item.file_path == "" ? vector : item.file_path} alt='a' className='specialtyimg' />
//                                                                                 </Grid><Grid item xs='1'></Grid>
//                                                                                 <Grid item xs='9' className='ref_lab' align="start">{item.service}</Grid>
//                                                                             </Grid>
//                                                                         </Card>
//                                                                     }
//                                                                 </MenuItem>
//                                                             ))}
//                                                         </Select>
//                                                     </FormControl>
//                                                 </div>
//                                             </Grid>
//                                         </Grid>
//                                     </div>
//                                 </Grid>

//                                 < Grid item xs='3' className='worckselectors'>
//                                     <div>
//                                         <p className='headlabel headerlabs'>Slot Duration <span style={{ fontSize: '11px' }}>(hrs)</span></p>
//                                         <Grid container className='Select_Label Select_Label_work_'>

//                                             <Grid item xs='10' align="start" className='labtext'>
//                                                 <div style={{ color: "#707070", position: 'relative', bottom: '2px' }}>{Var_SlotDuration}</div>
//                                             </Grid>
//                                             <Grid item xs='1' className='ref_lab' align="start">
//                                                 <i className="fa fa-clock-o" aria-hidden="true"></i>
//                                             </Grid>
//                                         </Grid>
//                                     </div>
//                                 </Grid>


//                                 <Grid item xs='3' className='worckselectors'>
//                                     <div>
//                                         <p className='headlabel headerlab'>Appointment Type</p>
//                                         <Grid container className='Select_Label_work'>
//                                             <Grid item className='Column_'>
//                                                 <div className='drop dropdown_button'>
//                                                     <FormControl fullWidth>
//                                                         {/* <InputLabel id="demo-simple-select-label">Appointment Type</InputLabel> */}
//                                                         <Select size="small" className="selectoption" sx={{ m: 1, minHeight: 15, minWidth: 250 }}
//                                                             id="demo-simple-select"
//                                                             value={Var_AppointmentlistSelect}
//                                                             // value={appointmentTypeLists}
//                                                             onChange={e => {
//                                                                 setappointmentTypeLists(e.target.value.id);
//                                                                 let item = Var_Appoinmenetdata.filter(x => x.id == e.target.value.id);


//                                                                 setVar_Appointmentlist(item[0].appointment_type);

//                                                                 setVar_AppointmentlistSelect(e.target.value)
//                                                             }}
//                                                             renderValue={(selected) => (
//                                                                 // <Container>
//                                                                     <p className="selectPval">
//                                                                         {selected.appointment_type}
//                                                                     </p>
//                                                                 // </Container>
//                                                             )}
//                                                         >
//                                                             <MenuItem key={0} value={{
//                                                                 "id": 0,
//                                                                 "appointment_type": "Appointment Type",
//                                                             }}>
//                                                             </MenuItem>
//                                                             {Var_Appoinmenetdata.map((item) => (
//                                                                 <MenuItem
//                                                                     key={item.appointment_type}
//                                                                     value={item}
//                                                                 >
//                                                                     <Card className='specialtyimgRow'>
//                                                                         <Grid container>
//                                                                             <Grid item xs='1' align="start">
//                                                                                 <div>
//                                                                                     {/* <img src={item.id == 1 ? Visit : item.id == 2 ? Online : item.id == 3 ? Fasttrack : Visit} alt='a' className='specialtyimg' /> */}

//                                                                                     {
//                                                                                         item.id == 1 && <img src={Visit} alt='a' className='specialtyimg' />
//                                                                                     }

//                                                                                     {
//                                                                                         item.id == 2 && <img src={Online} alt='a' className='specialtyimg' />
//                                                                                     }

//                                                                                     {
//                                                                                         item.id == 3 && <img src={Fasttrack} alt='a' className='specialtyimg' />
//                                                                                     }

//                                                                                     {
//                                                                                         item.id == 4 && <img src={InclinicOnline} alt='a' className='specialtyimg' />
//                                                                                     }
//                                                                                 </div>
//                                                                             </Grid><Grid item xs='3'></Grid>
//                                                                             <Grid item xs='7' className='ref_lab' align="start">{item.appointment_type}</Grid>
//                                                                         </Grid>
//                                                                     </Card>
//                                                                 </MenuItem>
//                                                             ))}
//                                                         </Select>
//                                                     </FormControl>
//                                                 </div>
//                                             </Grid>
//                                         </Grid>
//                                     </div>
//                                 </Grid>
//                             </Grid>

//                             <Grid container className='select_option'>
//                                 <Grid item xs='3' className='worckselectors' >
//                                     <div className="From_Tym">
//                                         <p className='headlabel headerlabs'>From Time</p>
//                                         <Grid container>
//                                             <Grid item className='ref_labs' align="start">
//                                                 <input className='tymbox' type="time" id="appt" name="appt" onChange={(e) => {
//                                                     handleFromTimeChange(e);
//                                                     setVarfromtimeisvalid(true);
//                                                     calculateTimeSlots();
//                                                 }} />
//                                             </Grid>
//                                         </Grid>
//                                     </div>
//                                 </Grid>

//                                 <Grid item xs='3' className='worckselectors'>
//                                     <div className="From_Tym">
//                                         <p className='headlabel headerlabs'>To Time</p>
//                                         <Grid container >
//                                             <Grid item className='ref_lab' align="start" onChange={() => {
//                                                 if (Varfromtimeisvalid == false);
//                                                 calculateTimeSlots()
//                                             }}>
//                                                 {/* <Grid item className='ref_lab' align="start" onClick={() => { if (Varfromtimeisvalid == false) notification.error({ message: "Set First From Time" }); }}> */}
//                                                 <input disabled={!Varfromtimeisvalid} className='tymbox' type="time" id="appt" name="appt" onChange={handleTimeChange} /> {/* onChange={(e) => setVar_ToTime(e)}*/}
//                                             </Grid>
//                                         </Grid>
//                                     </div>
//                                 </Grid>

//                                 <Grid item xs='3' className='worckselectors slotcount'>
//                                     <div className='slotcounts'>
//                                         <p className='slots_count' style={{ position: 'relative' }}>No. of Slots <span style={{ display: 'flex', width: '85px', paddingTop: '20px', justifyContent: 'center' }}> {Var_NoOfSlots != null ? Var_NoOfSlots : 0}</span></p>
//                                     </div>
//                                 </Grid>
//                             </Grid>

//                             <Grid container className='select_option'>
//                                 <Grid item xs='6' className='selectEvery'>
//                                     <div className='selectEverytog'>
//                                         <p className='headlabel headerlab'><span>Applicable Days </span><span className='Everyday'>All</span></p>
//                                         <FormControlLabel className='every_days' control={<IOSSwitch sx={{ m: 1 }}
//                                             checked={Var_Sunday && Var_Monday && Var_Tueday && Var_Wedday && Var_Thuday && Var_Friday && Var_Satday}
//                                             className='toggle' onClick={(e) => Everyday(e)} />} />
//                                     </div>
//                                 </Grid>
//                             </Grid>
//                         </Container>

//                         <Container>
//                             <Grid container className='DayButs'>
//                                 <Grid xs={12} item className='DayButsad'>
//                                     <div className='TotalsingleDay'>
//                                         <div className='singleDay'>
//                                             <p className='days_text'>Sun</p>
//                                             <FormControlLabel control={<IOSSwitch sx={{ m: 1 }} checked={Var_Sunday} className='toggle' onClick={(e) => Sunday(e)} />} />
//                                         </div>
//                                         <div className='singleDay'>
//                                             <p className='days_text'>Mon</p>
//                                             <FormControlLabel control={<IOSSwitch sx={{ m: 1 }} checked={Var_Monday} className='toggle' onClick={(e) => Monday(e)} />} />
//                                         </div>
//                                         <div className='singleDay'>
//                                             <p className='days_text'>Tue</p>
//                                             <FormControlLabel control={<IOSSwitch sx={{ m: 1 }} checked={Var_Tueday} className='toggle' onClick={(e) => Tueday(e)} />} />
//                                         </div>
//                                         <div className='singleDay'>
//                                             <p className='days_text'>Wed</p>
//                                             <FormControlLabel control={<IOSSwitch sx={{ m: 1 }} checked={Var_Wedday} className='toggle' onClick={(e) => Wedday(e)} />} />
//                                         </div>
//                                         <div className='singleDay'>
//                                             <p className='days_text'>Thu</p>
//                                             <FormControlLabel control={<IOSSwitch sx={{ m: 1 }} checked={Var_Thuday} className='toggle' onClick={(e) => Thuday(e)} />} />
//                                         </div>
//                                         <div className='singleDay'>
//                                             <p className='days_text'>Fri</p>
//                                             <FormControlLabel control={<IOSSwitch sx={{ m: 1 }} checked={Var_Friday} className='toggle' onClick={(e) => Friday(e)} />} />
//                                         </div>
//                                         <div className='singleDay'>
//                                             <p className='days_text'>Sat</p>
//                                             <FormControlLabel control={<IOSSwitch sx={{ m: 1 }} checked={Var_Satday} className='toggle' onClick={(e) => Satday(e)} />} />
//                                         </div>
//                                     </div>
//                                 </Grid>
//                             </Grid>

//                             <Card className='detail_list'>
//                                 <Grid container>
//                                     <Grid item xs='10' align='center' style={{ height: "95px" }}>
//                                         <div className='Detailtypes'>
//                                             <div className='Detailcont'>
//                                                 <p className='small_txet splClsH'>Specialty</p>
//                                                 <p className='text_lab splClsB'>{Var_SpecialtyName_Ref.current}</p>
//                                             </div>
//                                             <div className='Detailcont'>
//                                                 <p className='small_txet'>Slot Duration <span style={{ fontSize: '13px' }}>(hrs)</span></p>
//                                                 <p className='text_lab'>{Var_SlotDuration}</p>
//                                             </div>
//                                             <div className='Detailcont'>
//                                                 <p className='small_txet'>No. of Slots</p>
//                                                 <p className='text_lab'>{Var_NoOfSlots_Ref.current == 0 ? "" : Var_NoOfSlots_Ref.current}</p>
//                                             </div>
//                                             <div className='Detailcont'>
//                                                 <p className='small_txet'>Working Hours</p>
//                                                 <p className='text_lab' style={{ textTransform: 'lowercase' }}>{Var_showFromTime_Ref.current} {Var_showFromTime_Ref.current == "" ? "-" : "to"} {Var_showToTime_Ref.current}</p>
//                                             </div>
//                                         </div>
//                                         <div className='Detailtypes' style={{ marginTop: '7px' }}>
//                                             <div className='Detailcont'>
//                                                 <p className='small_txet splClsH'>Service</p>
//                                                 <p className='text_lab splClsB'>{Var_Service}</p>
//                                             </div>
//                                             <div className='Detailcont'>
//                                                 <p className='small_txet' >Appointment Type</p>
//                                                 <p className='text_lab'>{Var_Appointmentlist}

//                                                     <span>
//                                                         {
//                                                             Var_Appointmentlist == "In-Clinic" && <img src={Visit} alt='a' className='specialtyimg' />
//                                                         }

//                                                         {
//                                                             Var_Appointmentlist == "Online" && <img src={Online} alt='a' className='specialtyimg' />
//                                                         }

//                                                         {
//                                                             Var_Appointmentlist == "Fast Track" && <img src={Fasttrack} alt='a' className='specialtyimg' />
//                                                         }

//                                                         {

//                                                             Var_Appointmentlist == "In-Clinic/Online" && <img src={InclinicOnline} alt='a' className='specialtyimg' />
//                                                         }

//                                                         {/* <img className="image_size" src={Var_Appointmentlist == "In-Clinic" ? Visit : Var_Appointmentlist == "Online" ? Online : Var_Appointmentlist == "In-Clinic/Online" ? Walkin : Var_Appointmentlist == "Fast Track" ? Fasttrack : ""}></img> */}
//                                                     </span>
//                                                 </p>
//                                             </div>
//                                             <div className='Detailcont'>
//                                                 <p className='small_txet'>Working Days</p>
//                                                 <p className='text_lab'
//                                                     value={Var_WorkingDays.current}
//                                                     onChange={e => setVar_WorkingDays(e.target.value)} >
//                                                     {
//                                                         Var_Sunday && Var_Monday && Var_Tueday && Var_Wedday && Var_Thuday && Var_Friday && Var_Satday ? 'All Days' :
//                                                             // <>
//                                                             //     {
//                                                             //         Var_Sunday && 'Sun, '
//                                                             //     }

//                                                             //     {
//                                                             //         Var_Monday && 'Mon, '
//                                                             //     }

//                                                             //     {
//                                                             //         Var_Tueday && 'Tue, '
//                                                             //     }

//                                                             //     {
//                                                             //         Var_Wedday && 'Wed, '
//                                                             //     }

//                                                             //     {
//                                                             //         Var_Thuday && 'Thu, '
//                                                             //     }

//                                                             //     {
//                                                             //         Var_Friday && 'Fri, '
//                                                             //     }

//                                                             //     {
//                                                             //         Var_Satday && 'Sat '
//                                                             //     }

//                                                             // </>
//                                                             <>
//                                                                 { (Var_Monday || Var_Tueday || Var_Wedday || Var_Thuday || Var_Friday || Var_Satday)
//                                                                     && Var_Sunday ? 'Sun, ' :
//                                                                     Var_Sunday && 'Sun '
//                                                                 }

//                                                                 {
//                                                                     (Var_Tueday || Var_Wedday || Var_Thuday || Var_Friday || Var_Satday)
//                                                                     && Var_Monday ? 'Mon, ' :  
//                                                                      Var_Monday && 'Mon '
//                                                                 }

//                                                                 {  (Var_Wedday || Var_Thuday || Var_Friday || Var_Satday) && Var_Tueday ? 'Tue, ' :
//                                                                     Var_Tueday && 'Tue '
//                                                                 }

//                                                                 { (Var_Thuday || Var_Friday || Var_Satday) && Var_Wedday ? 'Wed, ' :
//                                                                     Var_Wedday && 'Wed '
//                                                                 }

//                                                                 { (Var_Friday || Var_Satday) &&  Var_Thuday ? 'Thu, ' :
//                                                                     Var_Thuday && 'Thu '
//                                                                 }

//                                                                 { Var_Satday && Var_Friday ? 'Fri, ' :
//                                                                     Var_Friday && 'Fri '
//                                                                 }

//                                                                 {
//                                                                     Var_Satday && 'Sat '
//                                                                 }

//                                                             </>
//                                                     }
//                                                 </p>
//                                             </div>
//                                             <div className='Detailcont'>
//                                             </div>
//                                         </div>
//                                     </Grid>

//                                     <Grid item xs='2' align='center'>
//                                         <p className='Action' style={{ marginTop: "10%" }} >Action</p>
//                                         <div className='Action_button'>
//                                             <Button
//                                                 className="botton_Add"
//                                                 disabled ={isDisable_Ref.current}
//                                                 onClick={() => {
//                                                     Add();
//                                                 }}
//                                             >
//                                                 Add
//                                             </Button>
//                                             {/* <Button
//                                                 className="botton_Cancel"
//                                             >
//                                                 Cancel
//                                             </Button> */}
//                                         </div>
//                                     </Grid>
//                                 </Grid>
//                             </Card>
//                         </Container>
//                     </Container>
//                 </div>
//             </div>
//             {/* ================= Working Hours Content Field End ================= */}

//             {localStorage.getItem("FooterStatus") == 'A' && <div className="footer">
//                 <FooterMaster />
//             </div>}

//             {/* =================  Working hours ADD Success Model Start =============== */}
//             <Dialog
//                 fullWidth={fullWidth}
//                 open={isshowsuccess}
//                 onClose={setishiddensuccess}
//                 sx={{
//                     "& .MuiDialog-container": {
//                         "& .MuiPaper-root": {
//                             width: "100%",
//                             maxWidth: "300px",
//                             borderRadius: "20px",
//                         },
//                     },
//                 }}
//             >
//                 <DialogContent>
//                     <div className="success_model_body">
//                         <div className="icon">
//                             <img src={Var_PopupModelIcon_Ref.current} />
//                         </div>
//                         <div className="title">{Var_AdvertisementMsgHead_Ref.current}</div>
//                         <div className="content">{Var_AdvertisementMsg_Ref.current}</div>
//                         <div className="segment_center">
//                             <button className="botton_Cart_Create" onClick={() => okClose()}>OK</button>
//                         </div>
//                     </div>
//                 </DialogContent>
//             </Dialog>
//             {/* ================= Working hours ADD Success Model End  ================= */}
//         </div>
//     )
// };
// export default WorkingHoursComponent;






import { notification, Spin } from "antd";
import { useHistory } from "react-router-dom";
import React, { useState, useEffect } from "react";
import axios from "axios";
import useStateRef from "react-usestateref";
import { Container } from "react-bootstrap";
// Mui meterial component import
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import FormControlLabel from "@mui/material/FormControlLabel";
import { MenuItem } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog, { DialogProps } from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import TextField from "@mui/material/TextField";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import IOSSwitch from "../../helpers/Switch/Switch";

import "./WorkingHoursMaster.css";
import vector from "../../assets/Doctor_web_Icon/Vector.png";
import successModelTickImg from "../../assets/Doctor_web_Icon/success.svg";
import Visit from "../../assets/Doctor_web_Icon/Visit.svg";
import Online from "../../assets/Doctor_web_Icon/Online.svg";
import Fasttrack from "../../assets/Doctor_web_Icon/Fasttrack.svg";
// import InclinicOnline from "../../assets/Doctor_web_Icon/InclinicOnline.svg";
import Walkin from "../../assets/Doctor_web_Icon/Walkin.svg";
import HeaderMasterComponent from "../Header/HeaderMaster";
import FooterMaster from "../Dashboard/FooterMaster";
import Service from "../../helpers/Service";
import Alert from "../../assets/Images/alert.svg";
import BackIcon from "../../assets/Doctor_web_Icon/Group 31.svg";
const WorkingHoursComponent = () => {
  let Var_History = useHistory();

  const [isshowsuccess, setishiddensuccess] = useState(false);
  const [Var_Specialitydata, setVar_Specialitydata] = React.useState([]);
  const [Var_Appoinmenetdata, setVar_Appoinmenetdata] = React.useState([]);
  const [Var_Servicedata, setVar_Servicedata, Var_Servicedata_Ref] =
    useStateRef([]);
  const [
    Var_ServicedataSelect,
    setVar_ServicedataSelect,
    Var_ServicedataSelect_Ref,
  ] = useStateRef("");
  const [
    Var_ServicedataList,
    setVar_ServicedataList,
    setVar_ServicedataList_Ref,
  ] = useStateRef("");
  const [
    Var_AppointmentlistSelect,
    setVar_AppointmentlistSelect,
    Var_AppointmentlistSelect_Ref,
  ] = useStateRef("");
  const [Var_SlotDuration, setVar_SlotDuration, Var_SlotDuration_Ref] =
    useStateRef("");
  const [
    colleteralDocumentVendorType,
    setCollateralDocumentVendorType,
    CollateralDocumentVendorType_Ref,
  ] = useStateRef([]);
  const [appointmentTypeLists, setappointmentTypeLists] = React.useState([]);
  const [
    serviceDocumentVendorType,
    setserviceDocumentVendorType,
    setserviceDocumentVendorType_Ref,
  ] = useStateRef("");
  const [Adddata, setVarAdddata] = React.useState("");
  const [Var_speciality_id, setVar_speciality_id, Var_speciality_idref] =
    useStateRef("");
  const [Var_Service_Id, setVar_Service_Id, Var_Service_Id_Ref] =
    useStateRef("");
  const [Var_Slot_Duration, setVar_Slot_Duration, Var_Slot_Duration_Ref] =
    useStateRef("");
  const [Varfromtimeisvalid, setVarfromtimeisvalid] = useState(false);
  const [Var_Everyday, setVar_Everyday, Var_Everyday_Ref] = useStateRef(false); // defaultChecked
  const [Var_Sunday, setVar_Sunday, Var_Sunday_Ref] = useStateRef(false); // defaultChecked
  const [Var_Monday, setVar_Monday, Var_Monday_Ref] = useStateRef(false); // defaultChecked
  const [Var_Tueday, setVar_Tueday, Var_Tueday_Ref] = useStateRef(false); // defaultChecked
  const [Var_Wedday, setVar_Wedday, Var_Wedday_Ref] = useStateRef(false); // defaultChecked
  const [Var_Thuday, setVar_Thuday, Var_Thuday_Ref] = useStateRef(false); // defaultChecked
  const [Var_Friday, setVar_Friday, Var_Friday_Ref] = useStateRef(false); // defaultChecked
  const [Var_Satday, setVar_Satday, Var_Satday_Ref] = useStateRef(false); // defaultChecked
  const [Var_Specialty, setVar_Specialty] = useState(""); // defaultChecked
  const [Var_SpecialtyId, setVar_SpecialtyId, Var_SpecialtyId_Ref] =
    useStateRef(0); // defaultChecked
  const [
    Var_Appointmentlist,
    setVar_Appointmentlist,
    setVar_Appointmentlist_Ref,
  ] = useStateRef(""); // defaultChecked
  const [Var_Service, setVar_Service, Var_Service_Ref] = useStateRef(""); // defaultChecked
  const [Var_ServiceId, setVar_ServiceId, Var_ServiceId_Ref] = useStateRef(""); // defaultChecked
  const [Var_WorkingDays, setVar_WorkingDays, Var_WorkingDays_Ref] =
    useStateRef([]);
  const [fullWidth, setFullWidth] = React.useState(true);
  const [Var_ToTime, setVar_ToTime, Var_ToTime_Ref] = useStateRef("");
  const [Var_FromTime, setVar_FromTime, Var_FromTime_Ref] = useStateRef("");
  const [Var_showFromTime, setVar_showFromTime, Var_showFromTime_Ref] =
    useStateRef("");
  const [Var_showToTime, setVar_showToTime, Var_showToTime_Ref] =
    useStateRef("");
  const [Var_NoOfSlots, setVar_NoOfSlots, Var_NoOfSlots_Ref] = useStateRef(0);
  const [loading, setLoading] = useState(true);
  const [
    Var_AdvertisementMsg,
    setVar_AdvertisementMsg,
    Var_AdvertisementMsg_Ref,
  ] = useStateRef("");
  const [
    Var_AdvertisementMsgHead,
    setVar_AdvertisementMsgHead,
    Var_AdvertisementMsgHead_Ref,
  ] = useStateRef("");
  const [Var_PopupModelIcon, setVar_PopupModelIcon, Var_PopupModelIcon_Ref] =
    useStateRef("");

  const Var_WorkingHoursButtonName = localStorage.getItem("buttonName");
  const [
    Var_DoctorDetailShow,
    setVar_DoctorDetailShow,
    Var_DoctorDetailShow_Ref,
  ] = useStateRef(null);
  const [
    Var_DoctorSpeciality,
    setVar_DoctorSpeciality,
    Var_DoctorSpeciality_Ref,
  ] = useStateRef(null);
  // Check for 3 step verification
  const [verification, setVerification] = useState(
    Var_History.location.state
      ? Var_History.location.state.verification
        ? Var_History.location.state.verification
        : false
      : false
  );
  const [, setisisDisable, isDisable_Ref] = useStateRef(false);

  const [Var_GetServiceList, setVar_GetServiceList, Var_GetServiceList_Ref] = useStateRef([]);
  const [Var_SpecialityValueId, setVar_SpecialityValueId] = useStateRef("");

  // initial function call start
  useEffect(() => {
    getAppointmenttype();
    getEveryDays();
    getClinicWiseDoctor();
    getspeciality();
    setisisDisable(false);
  }, []);
  // initial function call End

  // navigattion to back start
  const handleOnClick = (path) => {
    Var_History.push("/dashboard" + path);
  };
  // navigattion to back End
  // getEveryDays Function Call Start
  const getEveryDays = () => {
    if (
      Var_Sunday == true &&
      Var_Monday == true &&
      Var_Tueday == true &&
      Var_Wedday == true &&
      Var_Thuday == true &&
      Var_Friday == true &&
      Var_Satday == true
    ) {
      setVar_Everyday(true);
    }
  };
  const Everyday = (e) => {
    if (e.target.checked) {
      setVar_Everyday(true);
      setVar_Sunday(true);
      setVar_Monday(true);
      setVar_Tueday(true);
      setVar_Wedday(true);
      setVar_Thuday(true);
      setVar_Friday(true);
      setVar_Satday(true);
    } else {
      setVar_Everyday(false);
      setVar_Sunday(false);
      setVar_Monday(false);
      setVar_Tueday(false);
      setVar_Wedday(false);
      setVar_Thuday(false);
      setVar_Friday(false);
      setVar_Satday(false);
    }
  };
  const Sunday = (e) => {
    console.log(e.target.value);
    if (e.target.checked) {
      setVar_Sunday(true);
    } else {
      setVar_Sunday(false);
    }
  };
  const Monday = (e) => {
    if (e.target.checked) {
      setVar_Monday(true);
    } else {
      setVar_Monday(false);
    }
  };
  const Tueday = (e) => {
    if (e.target.checked) {
      setVar_Tueday(true);
    } else {
      setVar_Tueday(false);
    }
  };
  const Wedday = (e) => {
    if (e.target.checked) {
      setVar_Wedday(true);
    } else {
      setVar_Wedday(false);
    }
  };
  const Thuday = (e) => {
    if (e.target.checked) {
      setVar_Thuday(true);
    } else {
      setVar_Thuday(false);
    }
  };
  const Friday = (e) => {
    if (e.target.checked) {
      setVar_Friday(true);
    } else {
      setVar_Friday(false);
    }
  };
  const Satday = (e) => {
    if (e.target.checked) {
      setVar_Satday(true);
    } else {
      setVar_Satday(false);
    }
  };
  const okClose = () => {
    setishiddensuccess(!isshowsuccess);
    if (!verification) {
      handleOnClick("/workinghours/viewworkinghours");
    } else {
      Var_History.push("/dashboard");
    }

    setVarfromtimeisvalid(false);
  };
  // getEveryDays Function Call End

  // ==================================Get speciality APi Start====================================

  const getspeciality = () => {
    setLoading(true);
    var data = {
      doctor_id: localStorage.getItem("Doctor_id"),
    };
    axios({
      method: "POST",
      url: "doctor/getSpecialitybyDoctor",
      data: data,
    })
      .then((response) => {
        {
          console.log(response.data.data);

          setVar_Specialitydata(response.data.data);
          setVar_speciality_id(response.data.data);
          setCollateralDocumentVendorType(response.data.data[0].id);
          setVar_Specialty(response.data.data[0].speciality);
          setVar_SpecialtyId(response.data.data[0].id);

          setVar_GetServiceList(response.data.data);
          console.log("Var_GetServiceList", Var_GetServiceList_Ref.current);
          setVar_SpecialityValueId({ id: "", speciality: "Select speciality" })
          setLoading(false);
        }
        getService();
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };
  // ==================================Get speciality APi End====================================

  const dataclear = () => {
    setVar_AppointmentlistSelect({
      id: 0,
      appointment_type: "Appointment Type",
    });
    setVar_ServicedataSelect({
      id: 0,
      doctor_id: "",
      service: "Select Service",
      cost: "",
      slot_duration: "",
      description: "",
      file_path: "",
      file_name: "",
      is_active: "",
      spec_id: "",
    });
  };
  // ==================================Get Service Api Start=====================================
  const getService = () => {
    setLoading(true);
    var data = {
      doctor_id: localStorage.getItem("Doctor_id"),
      speciality_id: localStorage.getItem("speciality_Id"),
    };
    axios({
      method: "POST",
      url: "doctor/getDoctorServiceBySpecilaity",
      data: data,
    })
      .then((response) => {
        {
          setVar_Servicedata(
            response.data.data.filter((x) => x.is_active == 1)
          );
          setVar_ServicedataList(response.data.data);
          setVar_ServicedataSelect({
            id: 0,
            doctor_id: "",
            service: "Select Service",
            cost: "",
            slot_duration: "",
            description: "",
            file_path: "",
            file_name: "",
            is_active: "",
            spec_id: "",
          });
          setLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };
  // ==================================Get Service Api End=====================================
  const clearData = () => {
    // setVar_SlotDuration('');
    // setVar_SlotDuration("");
    // setVar_NoOfSlots(0)
  };

  // ==================================Get appointmenttype Api Start===========================
  const getAppointmenttype = () => {
    setLoading(true);
    axios({
      method: "GET",
      url: "doctor/get_mas_appointment_type",
    })
      .then((response) => {
        {
          console.log("Appointment", response.data.data);
          setVar_Appoinmenetdata(response.data.data);
          setVar_Appointmentlist(response.data.data.appointment_type);
          setVar_AppointmentlistSelect({
            id: 0,
            appointment_type: "Appointment Type",
          });

          const Var_EditWorkingListDetails = JSON.parse(
            localStorage.getItem("editWorking")
          );
          if (Var_EditWorkingListDetails != null) {
            setCollateralDocumentVendorType(
              Var_EditWorkingListDetails.speciality_id
            );
            setserviceDocumentVendorType(Var_EditWorkingListDetails.service_id);
            setVar_SlotDuration(Var_EditWorkingListDetails.slot_duration);
            setappointmentTypeLists(
              Var_EditWorkingListDetails.appointment_type
            );

            setVar_Specialty(Var_EditWorkingListDetails.speciality_name);
            setVar_Service(Var_EditWorkingListDetails.service_name);
            setVar_Appointmentlist(Var_EditWorkingListDetails.appointment_type);

            localStorage.removeItem("editWorking");
            setLoading(false);
          }
          setLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };
  // ==================================Get appointmenttype Api End===========================

  // ==================================ADD Working List Api Start============================
  const Add = async () => {
    setisisDisable(true);

    try {
      for (let i = 0; i < Var_Servicedata.length; i++) {
        if (Var_Servicedata[i].service == Var_Service_Ref.current) {
          setVar_Service_Id(Var_Servicedata[i].id);
          setVar_Slot_Duration(Var_Servicedata[i].slot_duration);
        }
      }

      let workingDays = [];
      if (
        Var_Sunday_Ref.current &&
        Var_Monday_Ref.current &&
        Var_Tueday_Ref.current &&
        Var_Wedday_Ref.current &&
        Var_Thuday_Ref.current &&
        Var_Friday_Ref.current &&
        Var_Satday_Ref.current
      ) {
        workingDays.push(1, 2, 3, 4, 5, 6, 7);
      } else {
        if (Var_Sunday_Ref.current) {
          workingDays.push(1);
        }

        if (Var_Monday_Ref.current) {
          workingDays.push(2);
        }

        if (Var_Tueday_Ref.current) {
          workingDays.push(3);
        }
        if (Var_Wedday_Ref.current) {
          workingDays.push(4);
        }
        if (Var_Thuday_Ref.current) {
          workingDays.push(5);
        }
        if (Var_Friday_Ref.current) {
          workingDays.push(6);
        }
        if (Var_Satday_Ref.current) {
          workingDays.push(7);
        }
      }
      debugger;

      setLoading(true);
      var Var_data = {
        clinicId: localStorage.getItem("Clinic_id"),
        serviceId: Var_ServiceId_Ref.current,
        fromtime: await Service.conert_12Hto_24HTime(
          Var_showFromTime_Ref.current
        ),
        totime: await Service.conert_12Hto_24HTime(Var_showToTime_Ref.current),
        slotduration: Var_Slot_Duration_Ref.current,
        NoOfslots: Var_NoOfSlots_Ref.current,
        days: workingDays,
        doctorId: localStorage.getItem("Doctor_id"),
        appointmentType: appointmentTypeLists,
        createdby: localStorage.getItem("Doctor_id"),
        ipaddress: "192.168.1.45",
        specialityId: Var_SpecialityValueId.id
      };

      if (
        Var_NoOfSlots_Ref.current != 0 &&
        Var_ServiceId_Ref.current != "" &&
        Var_showFromTime_Ref.current != "" &&
        Var_showToTime_Ref.current != "" &&
        Var_Slot_Duration_Ref.current != "" &&
        appointmentTypeLists != "" &&
        workingDays != ""
      ) {
        axios({
          method: "POST",
          url: "doctor/insertdocAppointmentSettings",
          data: Var_data,
        }).then((response) => {
          setisisDisable(false);
          setLoading(false);
          {
            console.log(response.data.data[0]);
            setVarAdddata(response.data.data);
            setLoading(false);
            if (response.data.status == 1) {
              setVar_PopupModelIcon(successModelTickImg);
              setVar_AdvertisementMsgHead("SUCCESS!");
              setVar_AdvertisementMsg(
                "Congratulations! Your working hours have been added successfully."
              );
              setishiddensuccess(true);
              setLoading(false);
              dataclear();
              setTimeout(() => {
                setishiddensuccess(false);
                handleOnClick("/workinghours/viewworkinghours");
              }, 3000);
            } else {
              setVar_PopupModelIcon(Alert);
              setVar_AdvertisementMsgHead("FAILED!");
              setVar_AdvertisementMsg(response.data.msg + "!");
              setishiddensuccess(true);
              setTimeout(() => {
                setishiddensuccess(false);
              }, 3000);
            }
          }
        });
      } else {
        notification.error({ message: "Please select all flied" });
        setLoading(false);
        setisisDisable(false);
      }
    } catch (e) {
      setLoading(false);
      console.log(e);
      setisisDisable(false);
    }
  };

  // ==================================ADD Working List Api End==============================

  // ========================= No Of Slot Showing in Working Hours Start=====================

  const timeToMinutes = (time) => {
    // Parse time in HH:mm format and convert to minutes
    const [hours, minutes] = time.split(":").map(Number);
    return hours * 60 + minutes;
  };

  const calculateTimeSlots = () => {
    debugger;
    const fromMinutes = timeToMinutes(Var_FromTime_Ref.current);
    const toMinutes = timeToMinutes(Var_ToTime_Ref.current);
    var timeParts = Var_SlotDuration_Ref.current.split(":");
    const slotDurationInMinutes =
      Number(timeParts[0]) * 60 + Number(timeParts[1]);
    const durationInMinutes = toMinutes - fromMinutes;
    if (Var_SlotDuration_Ref.current == "") {
      debugger;
      notification.error({ message: "Please select service first" });
    } else {
      debugger;
      const NoOfSlots = Math.floor(durationInMinutes / slotDurationInMinutes);
      if (NoOfSlots >= 0) {
        debugger;
        setVar_NoOfSlots(NoOfSlots);
      } else if (NoOfSlots < 0) {
        debugger;
        setVar_NoOfSlots(0);
      }
      console.log(NoOfSlots);
    }
  };

  const handleFromTimeChange = (event) => {
    setVar_FromTime(event.target.value);
    console.log("Fromtime", event.target.value);
    handleTimeConversion();
  };
  const handleTimeChange = (event) => {
    if (Var_FromTime_Ref.current < event.target.value) {
      setVar_ToTime(event.target.value);
      console.log("totime", event.target.value);
      handleTimeConversion();
    } else {
      notification.error({
        message: "Please select a To time greater than the From time.",
      });
      setVar_ToTime("");
      setVar_NoOfSlots(0);
    }
  };

  const convertTo12HourFormat = (fromtime, totime) => {
    var timeComponentsfrom = fromtime.split(":");
    var timeComponentsto = totime.split(":");
    var hoursfrom = parseInt(timeComponentsfrom[0], 10);
    var hoursto = parseInt(timeComponentsto[0], 10);
    var minutesfrom = parseInt(timeComponentsfrom[1], 10);
    var minutesto = parseInt(timeComponentsto[1], 10);

    // Check if it's AM or PM
    var periodfrom = hoursfrom >= 12 ? "PM" : "AM";
    var periodto = hoursto >= 12 ? "PM" : "AM";

    // Convert to 12-hour format
    var hourIn12HrFormatfrom = hoursfrom % 12 || 12;
    var hourIn12HrFormatto = hoursto % 12 || 12;

    // Format the time with "am" or "pm" indicator
    var formattedTimefrom =
      hourIn12HrFormatfrom +
      ":" +
      minutesfrom.toString().padStart(2, "0") +
      " " +
      periodfrom;
    var formattedTimeto =
      hourIn12HrFormatto +
      ":" +
      minutesto.toString().padStart(2, "0") +
      " " +
      periodto;
    setVar_showFromTime(formattedTimefrom);
    setVar_showToTime(formattedTimeto);
    // return formattedTimefrom,formattedTimeto;
  };

  // Example usage:

  const handleTimeConversion = () => {
    var fromtime = Var_FromTime_Ref.current; // Assuming you have refs set correctly.
    var totime = Var_ToTime_Ref.current;
    convertTo12HourFormat(fromtime, totime);
  };

  // ========================= No Of Slot Showing in Working Hours End=====================

  const getClinicWiseDoctor = () => {
    setLoading(true);
    var data = {
      clinic_id: localStorage.getItem("Clinic_id"),
    };
    axios
      .post("clinic/getClinicWiseDoctor", data)
      .then((response) => {
        var filteredData = response.data.data.filter(
          (x) =>
            x.specialityid == localStorage.getItem("speciality_Id") &&
            x.doctor_id == localStorage.getItem("Doctor_id")
        );
        setVar_DoctorDetailShow(
          filteredData[0].doctorName +
          "," +
          " " +
          filteredData[0].specialityname
        );
        setVar_DoctorSpeciality(filteredData[0].specialityname);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  };

  const getSpecialityValue = (event) => {
    setVar_SpecialityValueId(event.target.value);
  }

  return (
    <div>
      {loading && (
        <div className="loader_body">
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100vh",
            }}
          >
            <CircularProgress />
          </Box>
        </div>
      )}

      <div>
        <HeaderMasterComponent />
      </div>
      {/* ================= WorkingHours sub header start ================= */}
      <div>
        <div className="Navication-header">
          <div>
            <img
              src={BackIcon}
              onClick={() => handleOnClick("/workinghours/viewworkinghours")}
            />
            <label
              onClick={() => handleOnClick("/workinghours/viewworkinghours")}
            >
              Working Hours
            </label>{" "}
            <i class="fa fa-angle-left" aria-hidden="true"></i>
            <label> Add Working Hours</label>
          </div>
        </div>
        <div className="workinghours_content">
          {/* ================== WorkingHours sub header End ================== */}

          <div className="add-hours-content">
            {/* ================= Working Hours Content Field Start ================= */}
            <div style={{ marginLeft: "55px" }}>
              <Grid container style={{display:'flex', justifyContent:'center'}}>
                <Grid item xs="4" className="worckselectors">
                  <div>
                    <p className="headlabel headerlab"> Specialty</p>
                    <Grid container className="Select_Label_work">
                      <Grid item xs={12} md={12} className="Column_">
                        <div className="drop dropdown_button">
                          <FormControl fullWidth>
                            <Select
                              size="small"
                              className="selectoption_work"
                              fullWidth
                              sx={{ m: 1 }}
                              id="demo-simple-select"
                              value={Var_SpecialityValueId}
                              onChange={(e) => getSpecialityValue(e)}
                              renderValue={(Var_SpecialityValueId) => {
                                return Var_SpecialityValueId.speciality;
                              }}
                            >
                              <MenuItem
                                key={0}
                                value={{
                                  speciality: 'Select speciality',
                                  id: "",
                                }}
                              >
                              </MenuItem>
                              {Var_GetServiceList_Ref.current.map((item) => (
                                <MenuItem key={item.id} value={item}>
                                  <Container>
                                    <Card className="dropdown_card">
                                      <Grid container>
                                        <Grid item xs={9} className="ref_lab" align="start">
                                          <p className="dropdowntext">{item.speciality}</p>
                                        </Grid>
                                      </Grid>
                                    </Card>
                                  </Container>
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>

                        </div>
                      </Grid>
                    </Grid>
                  </div>
                </Grid>

                <Grid item xs="4" className="worckselectors">
                  <div>
                    <p className="headlabel headerlab"> Service</p>
                    <Grid container className="Select_Label_work">
                      <Grid item xs={12} md={12} className="Column_">
                        <div className="drop dropdown_button">
                          <FormControl fullWidth>
                            <Select
                              size="small"
                              className="selectoption_work"
                              fullWidth
                              sx={{ m: 1 }}
                              id="demo-simple-select"
                              value={Var_ServicedataSelect}
                              onChange={(e) => {
                                setserviceDocumentVendorType(e.target.value.id);
                                setVar_ServiceId(e.target.value.id);
                                let servicelist = Var_Servicedata.filter(
                                  (x) => x.id === e.target.value.id
                                );
                                setVar_Service(servicelist[0].service);
                                setVar_ServicedataSelect(e.target.value);
                                Var_Servicedata.forEach((item) => {
                                  if (item.id === e.target.value.id)
                                    setVar_SlotDuration(item.slot_duration);
                                });
                                calculateTimeSlots();
                              }}
                              renderValue={(selected) => (
                                <p className="selectPvals">{selected.service}</p>
                              )}
                            >
                              {/* Placeholder option */}
                              <MenuItem
                                key={0}
                                value={{
                                  id: 0,
                                  doctor_id: "",
                                  service: "Select Service",
                                  cost: "",
                                  slot_duration: "",
                                  description: "",
                                  file_path: "",
                                  file_name: "",
                                  is_active: "",
                                  spec_id: "",
                                }}
                              ></MenuItem>

                              {/* Render service items */}
                              {Var_Servicedata.map((item) => (
                                <MenuItem key={item.id} value={item}>
                                  {item.is_active !== "0" && (
                                    // <Card className="specialtyimgRow">
                                    //     <Grid container alignItems="center">
                                    //         <Grid item xs={2}>
                                    //             <img
                                    //                 src={item.file_path || vector}
                                    //                 alt="a"
                                    //                 className="specialtyimg"
                                    //             />
                                    //         </Grid>
                                    //         <Grid item xs={10} className="ref_lab">
                                    //             {item.service}
                                    //         </Grid>
                                    //     </Grid>
                                    // </Card>
                                    <div>{item.service}</div>
                                  )}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                </Grid>
              </Grid>

              <Grid container style={{display:'flex', justifyContent:'center'}}>
                <Grid item xs="4" className="worckselectors">
                  <div style={{ marginLeft: "10px" }}>
                    <p className="headlabel headerlabs">
                      Slot Duration <span className="fontSize10">(hrs)</span>
                    </p>
                    <Grid container className="Select_Label_work">
                      <Grid
                        container
                        item
                        xs={12}
                        md={12}
                        className="Select_Label Select_Label_work_"
                      >
                        <Grid item xs="10" align="start" className="labtext">
                          {Var_SlotDuration}
                        </Grid>
                        {/* <Grid item xs='1' className='ref_lab' align="End">
                                                <i className="fa fa-clock-o" aria-hidden="true"></i>
                                            </Grid> */}
                      </Grid>
                    </Grid>
                  </div>
                </Grid>

                <Grid item xs="4" className="worckselectors">
                  <div>
                    <p className="headlabel headerlab">Appointment Type</p>
                    <Grid container className="Select_Label_work">
                      <Grid item xs={12} md={12} className="Column_">
                        <div className="drop dropdown_button">
                          <FormControl fullWidth>
                            <Select
                              size="small"
                              className="selectoption_work"
                              fullWidth
                              sx={{ m: 1 }}
                              id="demo-simple-select"
                              value={Var_AppointmentlistSelect}
                              onChange={(e) => {
                                setappointmentTypeLists(e.target.value.id);
                                let item = Var_Appoinmenetdata.filter(
                                  (x) => x.id == e.target.value.id
                                );
                                setVar_Appointmentlist(
                                  item[0].appointment_type
                                );
                                setVar_AppointmentlistSelect(e.target.value);
                              }}
                              renderValue={(selected) => (
                                <p className="selectPvals">
                                  {selected.appointment_type}
                                </p>
                              )}
                            >
                              <MenuItem
                                key={0}
                                value={{
                                  id: 0,
                                  appointment_type: "Appointment Type",
                                }}
                              ></MenuItem>
                              {Var_Appoinmenetdata.map((item) => (
                                <MenuItem
                                  key={item.appointment_type}
                                  value={item}
                                >
                                  {/* <Card className='specialtyimgRow'> */}
                                  <Grid container>
                                    {/* <Grid item xs='1' align="start">
                                                                                <div>
                                                                                    {
                                                                                        item.id == 1 && <img src={Visit} alt='a' className='specialtyimg' />
                                                                                    }

                                                                                    {
                                                                                        item.id == 2 && <img src={Online} alt='a' className='specialtyimg' />
                                                                                    }

                                                                                    {
                                                                                        item.id == 3 && <img src={Fasttrack} alt='a' className='specialtyimg' />
                                                                                    }

                                                                                    {
                                                                                        item.id == 6 && <img src={InclinicOnline} alt='a' className='specialtyimg' />
                                                                                    }
                                                                                </div>
                                                                            </Grid><Grid item xs='3'></Grid> */}
                                    <Grid
                                      item
                                      xs="6"
                                      className="ref_lab"
                                      align="start"
                                    >
                                      General Clinic
                                    </Grid>
                                  </Grid>
                                  {/* </Card> */}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                </Grid>
              </Grid>

              <Grid container style={{display:'flex', justifyContent:'center'}}>
                <Grid item xs="4" className="worckselectors">
                  <div className="From_Tym">
                    <p className="headlabel headerlabs">From Time</p>
                    <Grid container>
                      <Grid item className="ref_labs" align="start">
                        <input
                          className="tymbox"
                          type="time"
                          id="appt"
                          name="appt"
                          onChange={(e) => {
                            handleFromTimeChange(e);
                            setVarfromtimeisvalid(true);
                            calculateTimeSlots();
                          }}
                        />
                      </Grid>
                    </Grid>
                  </div>
                </Grid>

                <Grid item xs="4" className="worckselectors">
                  <div className="From_Tym">
                    <p className="headlabel headerlabs">To Time</p>
                    <Grid container>
                      <Grid
                        item
                        className="ref_lab"
                        align="start"
                        onChange={() => {
                          if (Varfromtimeisvalid == false);
                          calculateTimeSlots();
                        }}
                      >
                        {/* <Grid item className='ref_lab' align="start" onClick={() => { if (Varfromtimeisvalid == false) notification.error({ message: "Set First From Time" }); }}> */}
                        <input
                          disabled={!Varfromtimeisvalid}
                          className="tymbox"
                          type="time"
                          id="appt"
                          name="appt"
                          onChange={handleTimeChange}
                        />{" "}
                        {/* onChange={(e) => setVar_ToTime(e)}*/}
                      </Grid>
                    </Grid>
                  </div>
                </Grid>
              </Grid>

              <Grid container style={{marginLeft:'16.5%'}}> 
                <Grid item xs="3" className="worckselectors">
                  <div style={{ marginLeft: "10px", marginTop: "6vh" }}>
                    <label className="headlabel headerlabs">
                      No. of Slots :
                    </label>
                    <label className="slot_clr">
                      {Var_NoOfSlots != null ? Var_NoOfSlots : 0}
                    </label>
                  </div>
                </Grid>
              </Grid>

              <Grid container style={{marginLeft:'16.5%'}}>
                <Grid item xs="6" className="selectEvery">
                  <div className="selectEverytog">
                    {/* <p className='headlabel headerlab'>Applicable Days </p> */}
                    <FormControlLabel
                      className="every_days"
                      control={
                        <Checkbox
                          sx={{ m: 1 }}
                          checked={
                            Var_Sunday &&
                            Var_Monday &&
                            Var_Tueday &&
                            Var_Wedday &&
                            Var_Thuday &&
                            Var_Friday &&
                            Var_Satday
                          }
                          onClick={(e) => Everyday(e)}
                        />
                      }
                      label="All"
                    />
                  </div>
                </Grid>
              </Grid>
            </div>

            <div>
              <Grid container className="DayButs" style={{marginLeft:'16.5%'}}>
                <Grid xs={12} item className="DayButsad">
                  <div className="TotalsingleDay">
                    <div className="singleDay">
                      {/* <p className='days_text'>Sun</p>
                                            <FormControlLabel control={<IOSSwitch sx={{ m: 1 }} checked={Var_Sunday} className='toggle' onClick={(e) => Sunday(e)} />} /> */}
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={Var_Sunday}
                            onClick={(e) => Sunday(e)}
                          />
                        }
                        label="Sun"
                      />
                    </div>
                    <div className="singleDay">
                      {/* <p className='days_text'>Mon</p>
                                            <FormControlLabel control={<IOSSwitch sx={{ m: 1 }} checked={Var_Monday} className='toggle' onClick={(e) => Monday(e)} />} /> */}
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={Var_Monday}
                            onClick={(e) => Monday(e)}
                          />
                        }
                        label="Mon"
                      />
                    </div>
                    <div className="singleDay">
                      {/* <p className='days_text'>Tue</p>
                                            <FormControlLabel control={<IOSSwitch sx={{ m: 1 }} checked={Var_Tueday} className='toggle' onClick={(e) => Tueday(e)} />} /> */}
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={Var_Tueday}
                            onClick={(e) => Tueday(e)}
                          />
                        }
                        label="Tue"
                      />
                    </div>
                    <div className="singleDay">
                      {/* <p className='days_text'>Wed</p>
                                            <FormControlLabel control={<IOSSwitch sx={{ m: 1 }} checked={Var_Wedday} className='toggle' onClick={(e) => Wedday(e)} />} /> */}
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={Var_Wedday}
                            onClick={(e) => Wedday(e)}
                          />
                        }
                        label="Wed"
                      />
                    </div>
                    <div className="singleDay">
                      {/* <p className='days_text'>Thu</p>
                                            <FormControlLabel control={<IOSSwitch sx={{ m: 1 }} checked={Var_Thuday} className='toggle' onClick={(e) => Thuday(e)} />} /> */}
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={Var_Thuday}
                            onClick={(e) => Thuday(e)}
                          />
                        }
                        label="Thu"
                      />
                    </div>
                    <div className="singleDay">
                      {/* <p className='days_text'>Fri</p>
                                            <FormControlLabel control={<IOSSwitch sx={{ m: 1 }} checked={Var_Friday} className='toggle' onClick={(e) => Friday(e)} />} /> */}
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={Var_Friday}
                            onClick={(e) => Friday(e)}
                          />
                        }
                        label="Fri"
                      />
                    </div>
                    <div className="singleDay">
                      {/* <p className='days_text'>Sat</p>
                                            <FormControlLabel control={<IOSSwitch sx={{ m: 1 }} checked={Var_Satday} className='toggle' onClick={(e) => Satday(e)} />} /> */}
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={Var_Satday}
                            onClick={(e) => Satday(e)}
                          />
                        }
                        label="Sat"
                      />
                    </div>
                  </div>
                </Grid>
              </Grid>
              <div className="add-dvsion" >
                <Button
                  className="botton_Add_hours"
                  disabled={isDisable_Ref.current}
                  onClick={() => {
                    Add();
                  }}
                >
                  Add
                </Button>
              </div>
              {/* <Card className='detail_list'>
                                <Grid container>
                                    <Grid item xs='10' align='center' style={{ height: "100px" }}>
                                        <div className='Detailtypes'>
                                            <div className='Detailcont'>
                                                <p className='small_txet splClsH'>Specialty</p>
                                                <p className='text_lab splClsB'>{Var_DoctorSpeciality_Ref.current}</p>
                                            </div>
                                            <div className='Detailcont'>
                                                <p className='small_txet'>Slot Duration <span className="fontSize10">(hrs)</span></p>
                                                <p className='text_lab'>{Var_SlotDuration}</p>
                                            </div>
                                            <div className='Detailcont'>
                                                <p className='small_txet'>No. of Slots</p>
                                                <p className='text_lab'>{Var_NoOfSlots_Ref.current == 0 ? "" : Var_NoOfSlots_Ref.current}</p>
                                            </div>
                                            <div className='Detailcont'>
                                                <p className='small_txet'>Working Hours</p>
                                                <p className='text_lab lowerCaseText'>{Var_showFromTime_Ref.current} {Var_showFromTime_Ref.current == "" ? "-" : "to"} {Var_showToTime_Ref.current}</p>
                                            </div>
                                        </div>
                                        <div className='Detailtypes' style={{ marginTop: "10px" }}>
                                            <div className='Detailcont'>
                                                <p className='small_txet splClsH'>Service</p>
                                                <p className='text_lab splClsB'>{Var_Service}</p>
                                            </div>
                                            <div className='Detailcont'>
                                                <p className='small_txet'>Appointment Type</p>
                                                <p className='text_lab'>{Var_Appointmentlist}

                                                    <span>
                                                        {
                                                            Var_Appointmentlist == "In-Clinic" && <img src={Visit} alt='a' className='specialtyimg' />
                                                        }

                                                        {
                                                            Var_Appointmentlist == "Online" && <img src={Online} alt='a' className='specialtyimg' />
                                                        }

                                                        {
                                                            Var_Appointmentlist == "Fast Track" && <img src={Fasttrack} alt='a' className='specialtyimg' />
                                                        }

                                                        {
                                                            Var_Appointmentlist == "In-Clinic/Online" && <img src={InclinicOnline} alt='a' className='specialtyimg' />
                                                        }
                                                    </span>
                                                </p>
                                            </div>
                                            <div className='Detailcont'>
                                                <p className='small_txet'>Working Days</p>
                                                <p className='text_lab'
                                                    value={Var_WorkingDays}
                                                    onChange={e => setVar_WorkingDays(e.target.value)} >
                                                    {
                                                        Var_Sunday && Var_Monday && Var_Tueday && Var_Wedday && Var_Thuday && Var_Friday && Var_Satday ? 'All Days' :
                                                            <>
                                                                {
                                                                    Var_Sunday && ' Sun, '
                                                                }

                                                                {
                                                                    Var_Monday && ' Mon, '
                                                                }

                                                                {
                                                                    Var_Tueday && ' Tue, '
                                                                }

                                                                {
                                                                    Var_Wedday && ' Wed, '
                                                                }

                                                                {
                                                                    Var_Thuday && ' Thu, '
                                                                }

                                                                {
                                                                    Var_Friday && ' Fri, '
                                                                }

                                                                {
                                                                    Var_Satday && ' Sat '
                                                                }

                                                            </>
                                                    }
                                                </p>
                                            </div>
                                            <div className='Detailcont'>
                                            </div>
                                        </div>
                                    </Grid>

                                    <Grid item xs='2' align='center'>
                                        <p className='Action' style={{ marginTop: "10%" }} >Action</p>
                                        <div className='Action_button' >
                                            <Button
                                                className="botton_Add"
                                                disabled={isDisable_Ref.current}
                                                onClick={() => {
                                                    Add();
                                                }}
                                            >
                                                Add
                                            </Button>
                                        </div>
                                    </Grid>
                                </Grid>
                            </Card> */}
            </div>
          </div>
        </div>
      </div>
      {/* ================= Working Hours Content Field End ================= */}

      {/* {
                localStorage.getItem("FooterStatus") == 'A' && <div className="footer">
                    <FooterMaster />
                </div>
            } */}

      {/* =================  Working hours ADD Success Model Start =============== */}
      {isshowsuccess && (
        <div className="popup-main">
          <div className="pushNotification_body">
            <div className="pushNotification_content">
              <p className="notification_text_head">
                <b>{Var_AdvertisementMsg_Ref.current}</b>
              </p>
            </div>
            <div className="progress-prt">
              <div className="progress-bar"></div>
            </div>
          </div>
        </div>
      )}
      {/* ================= Working hours ADD Success Model End  ================= */}
    </div>
  );
};
export default WorkingHoursComponent;
