// AUTHOR : Dinesh
// CR-DATE: 10-July-2023
// MOD-DATE: 19-July-2023
// DESCRIPTION: Collaterals Update work

import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import useStateRef from "react-usestateref";
import moment from "moment";
import Axios from "axios";
import LinearProgress from '@mui/material/LinearProgress';


// Mui meterial component import
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';




import './CollateralsMaster.css'
import FooterMaster from '../Dashboard/FooterMaster'


import PDFLOGO from "../../assets/Images/pdf_logo.png";
import ExampleVideo from "../../assets/videos/example_video.mp4";
import { notification, Spin } from "antd";

import Appoinmentsimg from '../../assets/icon/appointment.svg'
import Queueimg from '../../assets/icon/queue.svg'
import Availabilityimg from '../../assets/icon/manageSchedule.svg'
import Workinghoursimg from '../../assets/icon/workhours.svg'
import Walkinimg from '../../assets/icon/referral.svg'
import Cancelledimg from '../../assets/icon/cancelled.svg'
import Postimg from '../../assets/icon/post.svg'
import Manageserviceimg from '../../assets/icon/manageService.svg'

// import Appoinmentsimg from '../../assets/Images/Image 37@2x.png'
// import Queueimg from '../../assets/Images/Union 54@2x.png'
// import Availabilityimg from '../../assets/Images/Image 48@2x.png'
// import Workinghoursimg from '../../assets/Images/Image 44@2x.png'
// import Walkinimg from '../../assets/Images/Image 37-1@2x.png'
// import Cancelledimg from '../../assets/Images/Union 54-1@2x.png'
// import Postimg from '../../assets/Images/Image 37-2@2x.png'
// import Manageserviceimg from '../../assets/Images/Union 54-2@2x.png'
import { InputLabel, FormControlLabel, Select, MenuItem, Container, Card, Grid, TextField, Button, FormControl, DialogContent, Dialog, Radio } from '@mui/material';
import { Row, Col } from "react-bootstrap";

import closeicon from '../../assets/Web_Doctor_Icon_new_theme/close.svg';
// import Logo from '../../assets/Images/one logo new-01.svg'
import Logo from '../../assets/Web_Doctor_Icon_new_theme/logo_crop.svg'
import support from '../../assets/Web_Doctor_Icon_new_theme/Support.svg'
import Notification from '../../assets/Web_Doctor_Icon_new_theme/notification_active.svg'
import CollateralPlayIcon from '../../assets/Web_Doctor_Icon_new_theme/Collaterals_video.svg';

import Default_HomeImage from "../../assets/Web_Doctor_Icon_new_theme/home.svg";
import Active_HomeImage from "../../assets/Doctor_web_Icon/active_home_icon.svg";

import Default_Advertisement_Image from "../../assets/Web_Doctor_Icon_new_theme/advertisement.svg";
import Active_Advertisement_Image from "../../assets/Doctor_web_Icon/active_advertisement_icon.svg";

import Default_Deal_Image from "../../assets/Web_Doctor_Icon_new_theme/Deal.svg";
import Active_Deal_Image from "../../assets/Doctor_web_Icon/active_deal_icon.svg";

import Default_Collateral_Image from "../../assets/Web_Doctor_Icon_new_theme/Collaterals.svg";
import Active_Collateral_Image from "../../assets/Doctor_web_Icon/active_collatera_icon.png";

import Default_Revenue_Image from "../../assets/Web_Doctor_Icon_new_theme/Revenue.svg";
import Active_Revenue_Image from "../../assets/Doctor_web_Icon/active_revenue_icon.svg";


function CollateralsComponent() {
    let Var_History = useHistory();

    const [value, setValue] = React.useState(0);
    const [Var_tabValue, setVar_tabValue] = useState('video');
    const [Var_displayvideo, setVar_displayvideo] = useState('block');
    const [Var_displaydocument, setVar_displaydocument] = useState('none');
    const [Var_date, setVar_Date, Var_dateRef] = useStateRef("");
    const [Var_Date_Api, setVar_Date_Api, Var_Date_Api_Ref] = useStateRef("");
    const [Var_Explainer_Video, setVar_Explainer_Video, Var_Explainer_Video_Ref] = useStateRef("");
    const [Var_PermissionObject, setVar_PermissionObject, Var_PermissionObject_Ref] = useStateRef(null);
    var [isOpenVideoPopup, setisOpenVideoPopup] = useState(false);
    var [videoPopupTitle, setVideoPopupTitle] = useState("");
    var [videoListbyMenu, setVideoListbyMenu] = useState([]);
    var [menuVideo, setMenuVideo] = useState([]);
    var [currentVideo, setCurrentVideo] = useState("");
    var [currentSubmenuid, setCurrentSubmenuid] = useState("");
    const [Var_Profiledata, setVar_Profiledata] = React.useState([]);
    const [Var_DoctorDetailShow, setVar_DoctorDetailShow, Var_DoctorDetailShow_Ref] = useStateRef(null);
    const [loading, setLoading] = useState(true);
    const [activeLink, setActiveLink] = useState("/dashboard");

    // initial function call start
    useEffect(() => {
        // getClinicWiseDoctor()
        getDoctorProfile();
        setVar_Date(moment(new Date().toLocaleDateString('fr-CA')).format("DD-MMM-YY"));
        setVar_Date_Api(new Date().toLocaleDateString('fr-CA'));
        getAllCollateralsVideo();
        getMenulist()
    }, []);
    // initial function call end
    const menuNavigation = (url) => {
        sessionStorage.setItem("url", url);
        setActiveLink(url);
        console.log(url);
        Var_History.push(url);
    }

    const getClinicWiseDoctor = () => {
        debugger;
        // setLoading(true);
        var data = {
            clinic_id: localStorage.getItem("Clinic_id")
        };
        Axios
            .post("clinic/getClinicWiseDoctor", data)
            .then((response) => {
                debugger;
                var filteredData = response.data.data.filter(x => x.specialityid == localStorage.getItem("speciality_Id") && x.doctor_id == localStorage.getItem("Doctor_id"));
                setVar_DoctorDetailShow(filteredData[0].doctorName + ' ' + filteredData[0].specialityname);
                // setLoading(false);
            })
            .catch((error) => {
                // setLoading(false);
                console.log(error);
            });
    }


    // navigation to back start
    const handleOnClick = (path) => {
        Var_History.push("/dashboard" + path)
    };
    // navigation to back end


    //collaterals tab method start
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const testing = (Value) => {
        if (Value == 'video') {
            setVar_tabValue('video')
            setVar_displayvideo('block')
            setVar_displaydocument('none')
        } else if (Value == 'document') {
            setVar_displayvideo('none')
            setVar_displaydocument('block')
            setVar_tabValue('document')
        }
    }
    //collaterals tab method end


    //header date change method start
    const nextdate = () => {
        let date = new Date(Var_dateRef.current);
        date.setDate(date.getDate() + 1);
        let options = {
            day: '2-digit',
            month: 'short',
            year: '2-digit'
        };

        let formattedDate = date.toLocaleDateString('en-US', options);
        var monthsegment = moment(formattedDate).format("DD-MMM-YY")
        setVar_Date(monthsegment);
        setVar_Date_Api(date.toLocaleDateString('fr-CA'));
        // getAllAppointment();

    }
    const previousdate = () => {
        let date = new Date(Var_dateRef.current);
        date.setDate(date.getDate() - 1);
        let options = {
            day: '2-digit',
            month: 'short',
            year: '2-digit'
        };

        let formattedDate = date.toLocaleDateString('en-US', options);
        var monthsegment = moment(formattedDate).format("DD-MMM-YY")
        setVar_Date(monthsegment);
        setVar_Date_Api(date.toLocaleDateString('fr-CA'));
        // getAllAppointment();
    }
    //header date change method end

    //Get Collaterals  Details List start
    const getAllCollateralsVideo = () => {
        setLoading(true)
        var getCollateralsVideoDetails = {
            page_count: 0,
            Vendor_Id: 3,
        }
        Axios({
            method: "POST",
            url: "admin/getAllCollateralExplainerVideo",
            data: getCollateralsVideoDetails,
        })
            .then((response) => {
                setVar_Explainer_Video(response.data);
                setLoading(false)

            })
            .catch((error) => {
                setLoading(true)
                console.log(error);
            });
    }
    //Get Collaterals  Details List end

    const noPermission = (type) => {
        if (type == "appointments") {
            notification.error({ message: "No permission" })
        } else if (type == "queue") {
            notification.error({ message: "No permission" })

        } else if (type == "availability") {
            notification.error({ message: "No permission" })

        } else if (type == "workinghours") {
            notification.error({ message: "No permission" })
        }
        else if (type == "walkinbooking") {
            notification.error({ message: "No permission" })

        } else if (type == "cancelledappointments") {
            notification.error({ message: "No permission" })

        } else if (type == "post") {
            notification.error({ message: "No permission" })

        } else if (type == "manageservice") {
            notification.error({ message: "No permission" })
        }
    }

    const getMenulist = () => {
        setLoading(true)
        Axios({
            method: "POST",
            url: "admin/getCollateralVideosByVendor",
            data: {
                "vendorId": 3
            },
        })
            .then((response) => {
                if (response.data.status == 200) {
                    setVideoListbyMenu(response.data.data)
                    setLoading(false)
                }
                else {
                    setVideoListbyMenu([])
                    setLoading(false)
                }
            })
            .catch((error) => {
                setLoading(true)
                console.log(error);
            });
    }


    const handleMenuCardOnClick = (data) => {
        debugger
        if (videoListbyMenu.length != 0 && data != '') {
            var video = videoListbyMenu.filter((obj) => { return obj.menu_name.toLowerCase() == data.toLowerCase() })
            if (video.length > 0) {
                setMenuVideo(video)
                setisOpenVideoPopup(true)
                setCurrentVideo(video[0].video_path ? video[0].video_path : '')
                setCurrentSubmenuid(video[0].sub_menu_id)
                setVideoPopupTitle(video[0].menu_name)
            }
            else {
                setMenuVideo([])
            }

        }
        else {
            setMenuVideo([])
        }
    }

    const navClick = (data) => {
        setCurrentVideo(data.video_path ? data.video_path : '')
        setCurrentSubmenuid(data.sub_menu_id)
        setVideoPopupTitle(data.menu_name)
    }

    function getDoctorProfile() {
        setLoading(true)
        Axios({
            method: "POST",
            url: "doctor/getDoctorDetails",
            data: {
                doctorId: localStorage.getItem("Doctor_id")
            }
        }).then((response) => {
            debugger;
            setVar_Profiledata(response.data.data)
            setLoading(false);
        })
            .catch((error) => {
                setLoading(false);
                console.log(error);
            });
    }

    return (
        <div>
            {/* ================= Collaterals sub header start ================= */}
            {/* <div className="sub_header">
                <div className="sub_header_body">
                    <div className="back_navigation" onClick={() => handleOnClick("/")} >
                        <i className="fa fa-angle-left" aria-hidden="true"></i>
                        <span>Collaterals</span>
                    </div>
                    <div className="flex_grow"></div>
                    <div className="header_right_content">
                        <div>
                            <FormControl sx={{ m: 1, minWidth: 200 }} size="small">
                                <Select labelId="demo-select-small-label" id="demo-select-small" ></Select>
                            </FormControl>
                        </div>
                        <div>
                            <div className='page_head_date'>
                                <Button className='date_left_button' onClick={() => { previousdate() }}>
                                    <i className="fa fa-chevron-left date_left_button_icon" aria-hidden="true" />
                                </Button>
                                <Button className='date_button'>{Var_date}</Button>
                                <Button className='date_right_button' onClick={() => { nextdate() }}>
                                    <i className="fa fa-chevron-right date_right_button_icon" aria-hidden="true" />
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}

            <div className="home_header">
                <div className="header_row">
                    <Row className="row_content_">
                        <Col xs="2">
                            <div className="header_column1">
                                <img src={Logo} alt="log" className="logo_images" />
                            </div>
                        </Col>
                        <Col xs='1'>
                            <div className='fix_dropdown'>
                                {/* <div className='drop dropdown_button'>
                                    <FormControl fullWidth>
                                        <Select
                                            labelId="demo-select-small-label"
                                            id="demo-select-small"
                                            SelectProps={{
                                                renderValue: (p) => p
                                            }}
                                            value={Var_DoctorDetailShow_Ref.current}
                                            readOnly={true}
                                            renderValue={(p) => p}

                                        >
                                        </Select>
                                    </FormControl>
                                </div> */}
                            </div>
                        </Col>
                        {/* <Col xs='6'>
                        </Col> */}
                        <Col xs='9' className="header_right_part">
                            <div className="header_icons">
                                <img src={Notification} alt="log" className="support_images"
                                // onClick={() => routeNavigation("/notification")}
                                />
                                <div className="support-container">
                                    <img src={support} alt="log" className="support_images" />
                                    <img onClick={() => handleMenuCardOnClick("support")} src={CollateralPlayIcon} alt="log" className="video_support" />
                                </div>
                            </div>
                            <div>
                                {Var_Profiledata?.map((item, i) => (
                                    <div className="profile_text">
                                        <p className="profile_name_header">Welcome</p>
                                        <p className="profile_name_header username">{item.doctorName}</p>
                                    </div>
                                ))}
                            </div>
                            <div className="support-container">
                                {Var_Profiledata?.map((item, i) => (
                                    <img src={item.vendor_profile_path == null ? Logo : item.vendor_profile_path == "" ? Logo : item.vendor_profile_path == " " ? Logo : item.vendor_profile_path} className="support_profile_img"
                                    // onClick={() => routeNavigation("/profile")}
                                    />
                                ))}
                                <img src={CollateralPlayIcon} alt="log" onClick={() => handleMenuCardOnClick("Profile")} className="video_profile" />
                            </div>

                        </Col>
                    </Row>
                </div>
            </div>

            {/* ================= Collaterals sub header end ================= */}

            {/* ================= Collaterals list View start ================= */}
            {/* <div className='main_card_images collaterals_main_div'> */}
            <div className='main_card_images'>
                <Grid container className='first_row_card_images'>
                    <Grid item xs={6} md={3} align='center'>
                        <div className='card_image_content_collateral'

                        >
                            <div className="d-flex" style={{ justifyContent: 'center' }}>
                                <div>
                                    <img className='card_image card_image_appointment collateral_card_img' src={Appoinmentsimg} alt="Appoinmentsimg" />
                                </div>
                                <div style={{ marginLeft: '-20px' }}>
                                    <img src={CollateralPlayIcon} className="collateral_play_img" onClick={() => handleMenuCardOnClick("appointments")} />
                                </div>
                            </div>

                            <p className='home_card_title' style={{ marginRight: '20px' }}>Appointments</p>
                        </div>
                    </Grid>
                    <Grid item xs={6} md={3} align='center'>
                        <div className='card_image_content_collateral'

                        >
                            <div className="d-flex" style={{ justifyContent: 'center' }}>
                                <div>
                                    <img className='card_image collateral_card_img' src={Queueimg} alt="Queueimg" />
                                </div>
                                <div style={{ marginLeft: '-20px' }}>
                                    <img src={CollateralPlayIcon} className="collateral_play_img" onClick={() => handleMenuCardOnClick("queue")} />
                                </div>
                            </div>

                            <p className='home_card_title' style={{ marginRight: '20px' }}>Queue</p>
                        </div>
                    </Grid>
                    <Grid item xs={6} md={3} align='center'>
                        <div className='card_image_content_collateral'

                        >
                            <div className="d-flex" style={{ justifyContent: 'center' }}>
                                <div>
                                    <img className='card_image collateral_card_img' src={Availabilityimg} alt="Availabilityimg" />
                                </div>
                                <div style={{ marginLeft: '-20px' }}>
                                    <img src={CollateralPlayIcon} className="collateral_play_img" onClick={() => handleMenuCardOnClick("availability")} />
                                </div>
                            </div>

                            <p className='home_card_title' style={{ marginRight: '20px' }}>Manage Schedule</p>
                        </div>
                    </Grid>
                    <Grid item xs={6} md={3} align='center'>
                        <div className='card_image_content_collateral'

                        >
                            <div className="d-flex" style={{ justifyContent: 'center' }}>
                                <div>
                                    <img className='card_image collateral_card_img' src={Workinghoursimg} alt="Workinghoursimg" />
                                </div>
                                <div style={{ marginLeft: '-20px' }}>
                                    <img src={CollateralPlayIcon} className="collateral_play_img" onClick={() => handleMenuCardOnClick("working hours")} />
                                </div>
                            </div>

                            <p className='home_card_title' style={{ marginRight: '20px' }}>Working Hours</p>
                        </div>
                    </Grid>
                </Grid>
                <Grid container className='first_row_div_images'>
                    <Grid item xs={6} md={3} align='center'>
                        <div className='card_image_content_collateral'

                        >
                            <div className="d-flex" style={{ justifyContent: 'center' }}>
                                <div>
                                    <img className='card_image card_image_Walkin collateral_card_img' src={Walkinimg} alt="Walkinimg" />
                                </div>
                                <div style={{ marginLeft: '-20px' }}>
                                    <img src={CollateralPlayIcon} className="collateral_play_img" onClick={() => handleMenuCardOnClick("Referral")} />
                                </div>
                            </div>

                            <p className='home_card_title' style={{ marginRight: '20px' }}>Referral</p>
                        </div>
                    </Grid>
                    <Grid item xs={6} md={3} align='center'>
                        <div className='card_image_content_collateral'

                        >
                            <div className="d-flex" style={{ justifyContent: 'center' }}>
                                <div>
                                    <img className='card_image collateral_card_img' src={Cancelledimg} alt="Cancelledimg" />
                                </div>
                                <div style={{ marginLeft: '-20px' }}>
                                    <img src={CollateralPlayIcon} className="collateral_play_img" onClick={() => handleMenuCardOnClick("cancelled")} />
                                </div>
                            </div>

                            <p className='home_card_title' style={{ marginRight: '20px' }}>Canceled</p>
                        </div>
                    </Grid>
                    <Grid item xs={6} md={3} align='center'>
                        <div className='card_image_content_collateral'

                        >
                            <div className="d-flex" style={{ justifyContent: 'center' }}>
                                <div>
                                    <img className='card_image collateral_card_img' src={Postimg} alt="Postimg" />
                                </div>
                                <div style={{ marginLeft: '-20px' }}>
                                    <img src={CollateralPlayIcon} className="collateral_play_img" onClick={() => handleMenuCardOnClick("Post")} />
                                </div>
                            </div>

                            <p className='home_card_title' style={{ marginRight: '20px' }}>Post</p>
                        </div>
                    </Grid>
                    <Grid item xs={6} md={3} align='center'>
                        <div className='card_image_content_collateral'

                        >
                            <div className="d-flex" style={{ justifyContent: 'center' }}>
                                <div>
                                    <img className='card_image collateral_card_img' src={Manageserviceimg} alt="Manageserviceimg" />
                                </div>
                                <div style={{ marginLeft: '-20px' }}>
                                    <img src={CollateralPlayIcon} className="collateral_play_img" onClick={() => handleMenuCardOnClick("Manage Service")} />
                                </div>
                            </div>

                            <p className='home_card_title' style={{ marginRight: '20px' }}>Manage Services</p>
                        </div>
                    </Grid>
                </Grid>
            </div>
            {/* ================= Collaterals list View end ================= */}

            {/* ================= Collaterals footer start ================= */}
            <div className="footer">
                {/* <FooterMaster /> */}
                <Grid container className="footer_icon_row">
                    <Grid item xs={12} className='footer_column'>
                        <div onClick={() => menuNavigation('/dashboard')}>
                            <img src={Default_HomeImage} alt="icon" className="icon_footer" />
                            <p className={'menu_unactive'} >Home</p>
                        </div>
                        <div className={`${activeLink === '/dashboard/advertisementbooking' ? 'active-link' : 'unactive-link'}`}>
                            <img src={Default_Advertisement_Image} alt="icon" className="icon_footer PosZindex" />
                            <p className={'menu_unactive'}>Advertise</p>
                            <div style={{ marginLeft: '20px', marginTop: '28px' }}>
                                <img src={CollateralPlayIcon} className="video-icon4" onClick={() => handleMenuCardOnClick("Advertise")} />
                            </div>
                        </div>
                        <div className={` ${activeLink === '/dashboard/deals' ? 'active-link' : 'unactive-link'}`}>
                            <img src={Default_Deal_Image} alt="icon" className="icon_footer PosZindex" style={{ paddingLeft: '20px' }} />
                            <p className={'menu_unactive'} style={{ paddingLeft: '20px' }}>Deal</p>
                            <div style={{ marginLeft: '10px', marginTop: '28px' }}>
                                <img src={CollateralPlayIcon} className="video-icon4" onClick={() => handleMenuCardOnClick("Deals")} />
                            </div>
                        </div>
                        <div onClick={() => menuNavigation('/dashboard/collaterals')} className={` ${activeLink === '/dashboard' ? 'active-link' : 'unactive-link'}`}>
                            <img src={Default_Collateral_Image} alt="icon" className="icon_footer" />
                            <p className={'menu_unactive'}>Tutorials </p>
                        </div>
                        <div className={` ${activeLink === '/dashboard/revenue' ? 'active-link' : 'unactive-link'}`}>
                            <img src={Default_Revenue_Image} alt="icon" className="icon_footer PosZindex" />
                            <p className={'menu_unactive'}>Revenue</p>
                            <div style={{ marginLeft: '10px', marginTop: '28px' }}>
                                <img src={CollateralPlayIcon} className="video-icon4" onClick={() => handleMenuCardOnClick("Revenue")} />
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </div>
            {/* ================= Collaterals footer end ================= */}

            <Dialog
                fullWidth={true}
                alignment="center"
                size="md"
                open={isOpenVideoPopup}
                onClose={setisOpenVideoPopup}
                sx={{
                    "& .MuiDialog-container": {
                        "& .MuiPaper-root": {
                            width: "950px", minWidth: "950px", borderRadius: "20px", height: "560px",maxHeight: "calc(100% - 50px)"
                        },
                    },
                }}

            >
                <div className="dialog_header">
                    <div className="dialog_title" style={{ textTransform: 'uppercase' }}>{videoPopupTitle}</div>
                    <div className="flex_grow"></div>
                    <div onClick={() => setisOpenVideoPopup(false)}>
                        <img src={closeicon} />
                    </div>
                </div>
                <DialogContent>
                    <div>
                        <div style={{ textAlign: 'center' }}>
                            <video src={currentVideo} style={{ width: '100%', height: '430px' }} controls></video>
                            <div>
                                <ul className="collaterals_video_ul">
                                    {
                                        menuVideo.map((obj) => (
                                            <li className={currentSubmenuid == obj['sub_menu_id'] ? "active" : ""} onClick={() => { navClick(obj) }}> {obj['sub_menu_name']} </li>
                                        ))
                                    }
                                </ul>
                            </div>
                        </div>
                    </div>
                </DialogContent>
            </Dialog>
        </div>
    )
}

export default CollateralsComponent;