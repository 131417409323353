// import { useHistory } from "react-router-dom";
// import React, { useState, Component, useEffect } from "react";
// import Axios from "axios";
// import useStateRef from "react-usestateref";
// import InputLabel from "@mui/material/InputLabel";
// import TextField from "@mui/material/TextField";
// import Grid from "@mui/material/Grid";
// import Dialog, { DialogProps } from "@mui/material/Dialog";
// import DialogContent from "@mui/material/DialogContent";

// import LinearProgress from "@mui/material/LinearProgress";

// import "./Profile.css";
// import FooterMaster from "../Dashboard/FooterMaster";

// import successModelTickImg from "../../assets/Web_Doctor_Icon_new_theme/Success.svg";
// import vector from "../../assets/Doctor_web_Icon/Vector.png";
// import EmailIcon from "../../assets/icon/EmailIcon.svg";
// import WebSiteIcom from "../../assets/icon/websiteIcon.svg";
// import PhoneIcon from "../../assets/icon/PhoneIcon.svg";
// import LoactionIcon from "../../assets/icon/LoactionIcon.svg";
// import NationalityIcon from "../../assets/icon/Nationality.svg";
// import axios from "axios";
// import htmlToPdfmake from "html-to-pdfmake";
// import pdfMake from "pdfmake/build/pdfmake";
// import pdfFonts from "pdfmake/build/vfs_fonts";
// import CryptoJS from "crypto-js";
// pdfMake.vfs = pdfFonts.pdfMake.vfs;
// function ProfileComponent() {
//   let Var_History = useHistory();

//   const [Var_ShowImage, setVar_ShowImage] = React.useState(false);
//   const [Var_Profiledata, setVar_Profiledata] = React.useState([]);
//   const [, setVar_ProfileImg, Var_ProfileImg_Ref] =
//     useStateRef("");
//   const [, setVar_ProfileName, Var_ProfileName_Ref] =
//     useStateRef("");
//   const [, setVar_EmailData, Var_EmailData_Ref] = useStateRef("");
//   const [, setVar_WebsiteData, Var_WebsiteData_Ref] =
//     useStateRef("");
//   const [, setVar_MobileData, Var_MobileData_Ref] =
//     useStateRef("");
//   const [, setVar_AddressData, Var_AddressData_Ref] =
//     useStateRef("");
//   const [, setVar_NationalityData, Var_NationalityData_Ref] =
//     useStateRef("");
//   const [, setVar_CliniclistData, Var_CliniclistData_Ref] =
//     useStateRef("");
//   const [
//     Var_PracticeSinceData,
//     setVar_PracticeSinceData,
//     Var_PracticeSinceData_Ref,
//   ] = useStateRef("");
//   const [, setVar_SelfDescription, Var_SelfDescription_Ref] =
//     useStateRef("");
//   var [, setcontract_eny_data, contract_eny_dataRef] =
//     useStateRef({});
//   var [contract_orSignature, setcontract_orSignature, contract_orSignatureRef] =
//     useStateRef(0);
//   const [loading, setLoading] = useState(true);
//   const [vendorID, setVendorID] = useState("");
//   const [vendorTypeID, setvendorTypeID] = useState("");
//   const [Var_ProfileSuccessfully, setVar_ProfileSuccessfully] = useStateRef([]);
//   const [Var_ShowResults, setVar_ShowResults] = React.useState(true);
//   const [Var_IsShowSuccess, setVar_IsShowSuccess] = React.useState(false);

//   // initial function call start
//   useEffect(() => {
//     getDoctorProfile();
//   }, []);

//   const handleOnClick = (path) => {
//     Var_History.push("/dashboard" + path);
//   };

//   const isCloseProfileSuccessModel = () => {
//     setVar_IsShowSuccess(!Var_IsShowSuccess);
//   };

//   const uploadImage = () => {
//     setVar_ShowImage(!Var_ShowImage);
//   };
//   const uploadDocument = async (e) => {
//     debugger;
//     setLoading(true);
//     setVar_ProfileName(e.target.files[0].name);
//     const formData = new FormData();
//     formData.append('module_id', "1");
//     formData.append('file', e.target.files[0]);
//     formData.append('pathLocation', "PROFILE/");
//     Axios({
//       method: "POST",
//       url: "admin/awsS3FileUpload",
//       data: formData,
//     })
//       .then((response) => {
//         setVar_ProfileImg(response.data.filepath.Location);
//         setLoading(false);
//       })
//       .catch((error) => {
//         setLoading(false);
//       });
//   };
//   //File upload methods end

//   //Get Profile Details List start
//   const getDoctorProfile = () => {
//     setLoading(true);
//     let profileGetData = {
//       doctorId: localStorage.getItem("Doctor_id"),
//     };
//     Axios({
//       method: "POST",
//       url: "doctor/getDoctorDetails",
//       data: profileGetData,
//     })
//       .then((response) => {
//         setVar_Profiledata(response.data.data);
//         setVar_ProfileImg(response.data.data[0].vendor_profile_path);
//         setVar_EmailData(response.data.data[0].email);
//         setVar_WebsiteData(response.data.data[0].website);
//         setVar_MobileData(response.data.data[0].mobile);
//         setVar_AddressData(response.data.data[0].address);
//         setVar_NationalityData(response.data.data[0].nationality);
//         setVar_CliniclistData(response.data.data[0].clinic_list);
//         setVar_PracticeSinceData(response.data.data[0].practiceSince);
//         setVar_SelfDescription(response.data.data[0].selfDescription);
//         setVendorID(response.data.data[0].doctorId);
//         setvendorTypeID(response.data.data[0].vendor);
//         setLoading(false);
//       })
//       .catch((error) => {
//         setLoading(false);
//         console.log(error);
//       });
//   };
//   //Get Profile Details List end

//   //Edit Profile Details List start
//   const editDoctorProfile = () => {
//     setLoading(true);
//     let profileEditData = {
//       doctorId: localStorage.getItem("Doctor_id"),
//       practiceSince: Var_PracticeSinceData_Ref.current,
//       mobile: Var_MobileData_Ref.current,
//       email: Var_EmailData_Ref.current,
//       address: Var_AddressData_Ref.current,
//       file_name: Var_ProfileName_Ref.current,
//       file_path: Var_ProfileImg_Ref.current.split('?')[0],
//       selfDescription: Var_SelfDescription_Ref.current,
//       qualification: this.common.doctorDetails.qualification,
//       nationalityId: this.common.doctorDetails.nationality_id,
//       website: Var_WebsiteData_Ref.current,
//     };
//     Axios({
//       method: "PUT",
//       url: "doctor/editDoctorDetails",
//       data: profileEditData,
//     })
//       .then((response) => {
//         setVar_ShowImage(!Var_ShowImage);
//         setVar_IsShowSuccess(!Var_IsShowSuccess);
//         setVar_ProfileSuccessfully(response.data.msg);
//         getDoctorProfile();
//         setLoading(false);
//       })
//       .catch((error) => {
//         setLoading(false);
//         console.log(error);
//       });
//   };
//   //Edit Profile Details List end
//   async function getEncryptedData(id, vendorTypeID) {
//     try {
//       const response = await axios.post("admin/decryptedContent_data", {
//         id: id,
//         vendor_type: vendorTypeID,
//       });
//       setcontract_eny_data(response?.data?.data.UpdateVendor)
//       return response;
//     } catch (error) {
//       if (!error.response) {
//       } else {
//         // toast(error);
//       }
//     }
//   }
//   const Decrypted_Editor_content = (encryptedContent) => {
//     const fkey = CryptoJS.enc.Hex.parse(
//       "00112233445566778899aabbccddeeff00112233445566778899aabbccddeeff"
//     );
//     try {
//       const fiv = CryptoJS.enc.Hex.parse("00112233445566778899aabbccddeeff");
//       // Decrypt content
//       const decryptedBytes = CryptoJS.AES.decrypt(encryptedContent, fkey, {
//         iv: fiv,
//         mode: CryptoJS.mode.CBC,
//         padding: CryptoJS.pad.Pkcs7,
//       });
//       const decryptedContent = decryptedBytes.toString(CryptoJS.enc.Utf8);
//       return decryptedContent;
//     } catch (error) {
//       return error;
//     }
//   };
//   const formatDate = (date) => {
//     const day = date.getDate().toString().padStart(2, "0"); // Get day and pad with zero if needed
//     const monthNames = [
//       "Jan",
//       "Feb",
//       "Mar",
//       "Apr",
//       "May",
//       "Jun",
//       "Jul",
//       "Aug",
//       "Sep",
//       "Oct",
//       "Nov",
//       "Dec",
//     ];
//     const month = monthNames[date.getMonth()]; // Get month name from array
//     const year = date.getFullYear().toString().slice(-2); // Get last two digits of the year

//     return `${day}-${month}-${year}`; // Format the date
//   };
//   const pdfgenerate = async () => {
//     try {

//       const getEncypted = await getEncryptedData(vendorID, 'Doctor');
//       if (Object.keys(getEncypted?.data.data).length == 0 ) {
//         return;
//       }
//       // Convert decrypted content to PDFMake format
//       const decryptedContent =
//         contract_orSignatureRef.current === 0
//           ? getEncypted.data.data.contract_pdf_eData
//           : 
//             getEncypted.data.data.contract_pdf_eData;
//       const content = htmlToPdfmake(Decrypted_Editor_content(decryptedContent));

//       if (!content) {
//         throw new Error("HTML content is null or undefined");
//       }
//       const documentDefinition = {
//         content: [

//           ...content,

//         ],
//         styles: {
//           header: {
//             fontSize: 12,
//             bold: true,
//             margin: [40, 10, 40, 10],
//           },
//           footer: {
//             fontSize: 10,
//             margin: [40, 0, 40, 0],
//           },
//           main: {
//             margin: [40, 20, 40, 20],
//           },

//         },
//         header: function (currentPage, pageCount, pageSize) {
//           // Only display header on the first page
//           if (currentPage != 1) {
//             // Header content - a function can be used to dynamically create header content
//             const modified_on =
//               getEncypted != false && getEncypted?.data.data.modified_on
//                 ? new Date(getEncypted?.data.data.modified_on)
//                 : new Date();

//             return {
//               text: `Published Date :${formatDate(modified_on)}`,
//               alignment: "left",
//               style: "header",
//               color: "#510F30",
//               margin: [30, 40, 0, 0],
//             };
//           } else {
//             return{
//               text: '',
//               margin: [30, 10, 0, 0],
//             }
//             // return null; // Return null to exclude the header on subsequent pages or when adminCrtRef.current is true
//           }
//         },
//         footer: function (currentPage, pageCount, pageSize) {
//           // Footer content - a function can be used to dynamically create footer content
//           const imageBlob = getEncypted?.data.data.profile_imageBlob;
//           const signature_image = getEncypted != false && getEncypted?.data.data.convertblob;
//           const vendorName = contract_eny_dataRef.current;
//           const modified_on = getEncypted?.data.data.modified_on
//             ? new Date(getEncypted?.data.data.modified_on)
//             : new Date();

//             return {
//               margin: [40, 40, 40, 40],
//               columns: [
//                 {
//                   stack: [
//                     {
//                       text: `Service Provider Signature`,
//                       alignment: "left",
//                       style: "footer",
//                       color: "#510F30",
//                     },
//                     {
//                       table: {
//                         body: [
//                           [
//                             {
//                               image: imageBlob,
//                               width: 50,
//                               height: 50,
//                               margin: [0, 10, 10, 0],
//                             },
//                             {
//                               text:  localStorage.getItem("Doctor_name"),
//                               alignment: 'left',
//                               color: '#510F30',

//                               margin: [0, 20, 10, 0],
//                             },
//                             [
//                               {
//                                 image: signature_image,
//                                 width: 60,
//                                 height: 60,
//                                 alignment: 'left',

//                               },
//                               {
//                                 text: formatDate(modified_on),
//                                 alignment: 'left',
//                                 color: '#510F30',


//                               }
//                             ]

//                           ]
//                         ]
//                       },
//                       layout: {
//                         hLineWidth: function (i, node) {
//                           return 0;
//                         },
//                         vLineWidth: function (i, node) {
//                           return 0;
//                         },
//                         paddingLeft: function (i, node) {
//                           return 4;
//                         },
//                         paddingRight: function (i, node) {
//                           return 4;
//                         },
//                         paddingTop: function (i, node) {
//                           return 4;
//                         },
//                         paddingBottom: function (i, node) {
//                           return 4;
//                         },

//                       }
//                     },
//                     {
//                       text: `Page ${currentPage}/${pageCount}`,
//                       alignment: "right",
//                       color: "#510F30",
//                       style: "footerText",
//                     },
//                   ],
//                 },
//               ],
//             };

//         },
//         styles: {
//           footerText: {
//             fontSize: 10,
//           },
//           footer: {
//             fontSize: 10,
//             bold: true,
//           },
//         },
//         pageMargins: [40, 70, 40, 180], // Page margins (left, top, right, bottom)
//       };


//       pdfMake.createPdf(documentDefinition).open();
//        // Create the PDF and get it as a Blob


//     } catch (error) {
//       console.log(error);
//     }
//   };

//   return (
//     <div>
//       {loading && (
//         <LinearProgress
//           color="secondary"
//           className="progressBar"
//           sx={{ zIndex: "9999" }}
//         />
//       )}
//       {/* ================= Profile sub header start ================= */}
//       <div className="sub_header">
//         <div className="sub_header_body">
//           <div className="back_navigation" onClick={() => handleOnClick("/")}>
//             <i className="fa fa-angle-left" aria-hidden="true"></i>
//             <span>PROFILE</span>
//           </div>
//           <div className="flex_grow"></div>
//           <div className="header_right_content">
//             <h5
//               onClick={() => setVar_ShowResults(!Var_ShowResults)}
//               className="doctor_profile"
//             >
//               {Var_ShowResults ? (
//                 <p className="profile_edit" onClick={() => uploadImage()}>
//                   Edit
//                 </p>
//               ) : (
//                 <p className="profile_edit" onClick={() => editDoctorProfile()}>
//                   Save
//                 </p>
//               )}
//             </h5>
//           </div>
//         </div>
//       </div>
//       {/* ================= Profile sub header end ================= */}

//       {/* ================= Profile details View  start ================= */}
//       <div className="content_view profileBlock">
//         {/* ================= Profile details start ================= */}
//         <div>
//           {Var_Profiledata?.map((responseData, i) => (
//             <Grid container key={i}>
//               <Grid
//                 item
//                 xs={12}
//                 md={5}
//                 lg={5}
//                 className="profile_pic_container"
//               >
//                 <img
//                   src={
//                     Var_ProfileImg_Ref.current != ""
//                       ? Var_ProfileImg_Ref.current
//                       : vector
//                   }
//                   className="profile_pic"
//               alt=""  />
//                 <span hidden={!Var_ShowImage}>
//                   <i className="fa fa-plus-circle" aria-hidden="true">
//                     <input
//                       type="file"
//                       className="profile_upload"
//                       onChange={(e) => uploadDocument(e)}
//                       sx={{ minWidth: "-webkit-fill-available" }}
//                     />
//                   </i>
//                 </span>
//               </Grid>
//               <Grid
//                 item
//                 xs={12}
//                 md={7}
//                 lg={7}
//                 className="profile_fields_container"
//               >
//                 <Grid container>
//                   <Grid item xs={1}></Grid>
//                   <Grid item xs={11}>
//                     <h5 className="profile_doctor_name">
//                       {responseData.doctorName}
//                     </h5>
//                   </Grid>
//                 </Grid>
//                 <Grid container>
//                   <Grid item xs={1} className="profile_sub_header">
//                     <img src={EmailIcon} className="profile_sub_img" alt=""></img>
//                   </Grid>
//                   <Grid item xs={5}>
//                     <InputLabel className="input_label">Email</InputLabel>
//                     <TextField
//                       className="profile_input"
//                       defaultValue={responseData.email}
//                       InputProps={{
//                         readOnly: Var_ShowResults,
//                       }}
//                       variant="standard"
//                       onChange={(e) => setVar_EmailData(e.target.value)}
//                     />
//                   </Grid>
//                   <Grid item xs={6}>
//                     <InputLabel className="input_label">Clinic</InputLabel>
//                     <div className="clinic_text">
//                       {responseData.clinic_list
//                         .split(",")
//                         ?.map((clinic, index) => (
//                           <p key={index}>{clinic}</p>
//                         ))}
//                     </div>
//                   </Grid>
//                 </Grid>
//                 <Grid container>
//                   <Grid item xs={1} className="profile_sub_header">
//                     <img src={WebSiteIcom} className="profile_sub_img" alt=""></img>
//                   </Grid>
//                   <Grid item xs={5}>
//                     <InputLabel className="input_label">Website</InputLabel>
//                     <TextField
//                       className="profile_input"
//                       defaultValue={responseData.website}
//                       InputProps={{
//                         readOnly: true,
//                       }}
//                       variant="standard"
//                       onChange={(e) => setVar_WebsiteData(e.target.value)}
//                     />
//                   </Grid>
//                   <Grid item xs={6}>
//                     <InputLabel className="input_label">Pharmacy</InputLabel>
//                     <div className="pharmacytext">
//                       {JSON.parse(responseData.pharma_list)?.map(
//                         (pharmacy, index) => (
//                           <p key={index}>{pharmacy.clinic}</p>
//                         )
//                       )}
//                     </div>
//                   </Grid>
//                 </Grid>
//                 <Grid container>
//                   <Grid item xs={1} className="profile_sub_header">
//                     <img src={PhoneIcon} className="profile_sub_img" alt=""></img>
//                   </Grid>
//                   <Grid item xs={5}>
//                     <InputLabel className="input_label">Mobile</InputLabel>
//                     <TextField
//                       className="profile_input"
//                       defaultValue={responseData.mobile}
//                       inputProps={{
//                         maxLength: 10,
//                         readOnly: true,
//                       }}
//                       variant="standard"
//                       onChange={(e) => setVar_MobileData(e.target.value)}
//                     />
//                   </Grid>
//                   <Grid item xs={6}>
//                     <InputLabel className="input_label">Specialty</InputLabel>
//                     <div className="specialtytext">
//                       {JSON.parse(responseData.speciality)?.map(
//                         (item, index) => (
//                           <p key={index}>{item.speciality}</p>
//                         )
//                       )}
//                     </div>
//                   </Grid>
//                 </Grid>
//                 <Grid container>
//                   <Grid item xs={1} className="profile_sub_header">
//                     <img src={LoactionIcon} className="profile_sub_img" alt=""></img>
//                   </Grid>
//                   <Grid item xs={5}>
//                     <InputLabel className="input_label">Address</InputLabel>
//                     <TextField
//                       className="profile_input"
//                       defaultValue={responseData.address}
//                       InputProps={{
//                         readOnly: Var_ShowResults,
//                       }}
//                       variant="standard"
//                       onChange={(e) => setVar_AddressData(e.target.value)}
//                     />
//                   </Grid>
//                   <Grid item xs={6}>
//                     <InputLabel className="input_label">
//                       Self Description
//                     </InputLabel>
//                     {/* <TextField
//                       style={{ paddingLeft: "0px !important" }}
//                       className="profile_input"
//                       defaultValue={responseData.selfDescription}
//                       InputProps={{
//                         readOnly: true,
//                       }}
//                       variant="standard"
//                       onChange={(e) => setVar_SelfDescription(e.target.value)}
//                     /> */}
//                     <label>
//                     {responseData.selfDescription}
//                     </label>
//                   </Grid>
//                 </Grid>
//                 <Grid container>
//                   <Grid item xs={1} className="profile_sub_header">
//                     <img
//                       src={NationalityIcon}
//                       className="profile_sub_img"
//                       alt=""  ></img>
//                   </Grid>
//                   <Grid item xs={11}>
//                     <InputLabel className="input_label">Nationality</InputLabel>
//                     <TextField
//                       className="profile_input"
//                       defaultValue={responseData.nationality}
//                       InputProps={{
//                         readOnly: true,
//                       }}
//                       variant="standard"
//                       onChange={(e) => setVar_NationalityData(e.target.value)}
//                     />
//                   </Grid>
//                 </Grid>
//                 <Grid item xs={11} className="">
//                   <div className="">
//                     <InputLabel className="input_label terms_Condition" onClick={() => {
//                       pdfgenerate();
//                     }}
//                       style={{ cursor: 'pointer', marginLeft: '68px', color: '#F49C7A', textDecoration: 'underline', }}
//                     >Service Provider Terms & Conditions</InputLabel>

//                   </div>

//                 </Grid>

//               </Grid>
//             </Grid>
//           ))}
//         </div>
//         {/* ================= Profile details end ================= */}

//         {/* ======================== Profile Success Model start======================================= */}
//         <Dialog
//           fullWidth={true}
//           open={Var_IsShowSuccess}
//           onClose={setVar_IsShowSuccess}
//           sx={{
//             "& .MuiDialog-container": {
//               "& .MuiPaper-root": {
//                 width: "100%",
//                 maxWidth: "300px",
//                 borderRadius: "20px",
//               },
//             },
//           }}
//         >
//           <DialogContent>
//             <div className="success_model_body">
//               <div className="icon">
//                 <img src={successModelTickImg}alt="" />
//               </div>
//               <div className="title">success!</div>
//               <div className="content">{Var_ProfileSuccessfully}</div>
//               <div className="segment_center">
//                 <button
//                   className="botton_Cart_Create"
//                   onClick={() => isCloseProfileSuccessModel()}
//                 >
//                   OK
//                 </button>
//               </div>
//             </div>
//           </DialogContent>
//         </Dialog>
//         {/* ======================== Profile Success Model end======================================= */}
//       </div>
//       {/* ================= Profile details View  end ================= */}

//       {/* ================= Profile footer start ================= */}
//       <div className="footer">
//         <FooterMaster />
//       </div>
//       {/* ================= Profile footer end ================= */}
//     </div>
//   );
// }
// export default ProfileComponent;



import { useHistory } from "react-router-dom";
import React, { useState, useEffect } from "react";
import Axios from "axios";
import useStateRef from "react-usestateref";
import { notification } from "antd";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
// Mui meterial component import
import InputLabel from "@mui/material/InputLabel";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

import "./Profile.css";
import HeaderMasterComponent from "../Header/HeaderMaster";
import FooterMaster from "../Dashboard/FooterMaster";

import alertModelTickImg from "../../assets/Images/alert.svg";
import successModelTickImg from "../../assets/Doctor_web_Icon/success.svg";
import vector from "../../assets/Doctor_web_Icon/Vector.png";
import add_icon from "../../assets/Doctor_web_Icon/Add.svg";
import EmailIcon from "../../assets/icon/EmailIcon.svg";
import WebSiteIcom from "../../assets/icon/websiteIcon.svg";
import PhoneIcon from "../../assets/icon/PhoneIcon.svg";
import LoactionIcon from "../../assets/icon/LoactionIcon.svg";
import logoutIcon from "../../assets/Doctor_web_Icon/Group 23875.svg";
import BackIcon from "../../assets/Doctor_web_Icon/Group 31.svg";
import Card from "@mui/material/Card";
import { MenuItem, Container } from '@mui/material';
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";

function ProfileComponent() {
  let Var_History = useHistory();

  const [Var_ShowImage, setVar_ShowImage] = React.useState(false);
  const [Var_Profiledata, setVar_Profiledata, Var_Profiledata_Ref] =
    useStateRef([]);
  const [, setVar_ProfileImg, Var_ProfileImg_Ref] = useStateRef("");
  const [, setVar_SinceData, Var_SinceData_Ref] = useStateRef("");
  const [, setVar_EmailData, Var_EmailData_Ref] = useStateRef("");
  const [, setVar_Quali, Var_Qauli_Ref] = useStateRef("");
  const [, setVar_nation, Var_Nation_Ref] = useStateRef("");
  const [, setVar_Self, Var_Self_Ref] = useStateRef("");
  const [, setVar_WebsiteData, Var_WebsiteData_Ref] = useStateRef("");
  const [, setVar_MobileData, Var_MobileData_Ref] = useStateRef("");
  const [, setVar_AddressData, Var_AddressData_Ref] = useStateRef("");
  const [, setDOB, Var_DOB] = useStateRef("");
  const [, setVar_Gender, Var_Gender] = useStateRef("");
  const [loading, setLoading] = useState(true);

  const [Var_ProfileSuccessfully, setVar_ProfileSuccessfully] = useStateRef("");
  const [Var_ShowResults, setVar_ShowResults] = React.useState(true);
  const [Var_IsShowSuccess, setVar_IsShowSuccess] = React.useState(false);
  const [Var_IsShowSuccessmodal, setVar_IsShowSuccessmodal] =
    React.useState(false);
  const [Var_IsShowDeletePostModel, setVar_IsShowDeletePostModel] =
    React.useState(false);
  const [Var_PharmacyDetials, setVar_PharmacyDetials, Var_PharmacyDetials_Ref] =
    useStateRef([]);
  const [Var_pharmdataSelect, setVar_pharmdataSelect, Var_pharmdataSelect_ref] =
    useStateRef("");
  const [Var_pharmacyId, setVar_pharmacyId, Var_pharmacyId_Ref] = useStateRef(
    []
  );
  const [Var_GetServiceList, setVar_GetServiceList, Var_GetServiceList_Ref] = useStateRef([]);
  const [Var_SpecialityValueId, setVar_SpecialityValueId] = useStateRef("");
  const [isshowWebAccess, setisshowWebAccess] = useStateRef(false);
  const [checkboxValues, setcheckboxValues] = useStateRef({
    appointment_access :false,
    service_access : false,
    workinghrs_access : false,
    revenue_access : false
  });

  // initial function call start
  useEffect(() => {
    getDoctorProfile();
    getSpeciality();
    getWebAccessDetials();
  }, []);
  // initial function call end

  const logoutModal = () => {
    setVar_IsShowDeletePostModel(!Var_IsShowDeletePostModel);
  };

  const logout = (path) => {
    Var_History.push(path);
  };

  const localDataClear = () => {
    localStorage.clear("LoginData");
    localStorage.clear("clinic_name");
    localStorage.clear("clinic_id");
    localStorage.clear("clinic_vendor_type_id");
    localStorage.clear("doctor_id");
    localStorage.clear("speciality_Id");
  };

  // navigation to back start
  const handleOnClick = (path) => {
    Var_History.push("/dashboard" + path);
  };
  // navigation to back end

  // profile model controls start
  const isCloseProfileSuccessModel = () => {
    setVar_IsShowSuccess(!Var_IsShowSuccess);
  };
  // profile model controls end

  const successClose = () => {
    setVar_IsShowSuccess(!Var_IsShowSuccess);
  };

  const noDeletePostModel = (data) => {
    setVar_IsShowDeletePostModel(!Var_IsShowDeletePostModel);
  };

  const yesDeletePostModel = (path) => {
    localDataClear();
    setVar_IsShowDeletePostModel(!Var_IsShowDeletePostModel);
    setVar_IsShowSuccessmodal(!Var_IsShowSuccessmodal);
  };
  const logoutsuccess = (path) => {
    setVar_ProfileSuccessfully("Logout successful.");
    setVar_IsShowSuccess(true);
    setTimeout(() => {
      setVar_IsShowSuccess(false);
      localDataClear();
      logout(path);
    }, 3000);
  };

  //File upload methods start
  const uploadImage = () => {
    setVar_ShowImage(!Var_ShowImage);
    setVar_ShowResults(!Var_ShowResults);
  };
  const getVar_MobileData = (value) => {
    const numericOnly = value.replace(/\D/g, "");
    setVar_MobileData(numericOnly);
  };
  const uploadDocument = async (e) => {
    setLoading(true);
    const formData = new FormData();
    formData.append("module_id", "1");
    formData.append("file", e.target.files[0]);
    formData.append("pathLocation", "PROFILE/");
    Axios({
      method: "POST",
      url: "admin/awsS3FileUpload",
      data: formData,
    })
      .then((response) => {
        setVar_ProfileImg(response.data.filepath.Location);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };
  //File upload methods end

  const defaultPharmacy = {
    vendor_name: "Select Pharmacy",
    pharmacyId: "",
  };


  const walkinMobileNumbetValidation = (event) => {
    debugger
    let numericonly = event.target.value.replace(/[^0-9.]/g, '');
    if (/^\d{0,10}$/.test(event.target.value)) {

      setVar_MobileData(numericonly);
    }
  };
//Get Web Access details
const getWebAccessDetials = () =>{
  setLoading(true);
  const requestData = {
    doctorId: localStorage.getItem("Doctor_id"),
    clinicId: localStorage.getItem("Clinic_id"),
  }
  Axios({
    method : "POST",
    url:"doctor/clinicTabPermissionbyId",
    data:requestData
  }).then((response)=>{
    setLoading(false);
    setisshowWebAccess(false);
     setcheckboxValues({
      appointment_access:response.data.data[0].appointment_access == 1 ? true:false,
      service_access:response.data.data[0].service_access == 1 ? true:false,
      revenue_access:response.data.data[0].revenue_access == 1 ? true:false,
      workinghrs_access:response.data.data[0].workinghrs_access == 1 ? true:false,
     })
  }).catch((error)=>{
    setLoading(false);
    setisshowWebAccess(false);
console.log(error);
  })
}

  //Get Profile Details List start
  const getDoctorProfile = () => {
    setLoading(true);
    let profileGetData = {
      doctorId: localStorage.getItem("Doctor_id"),
    };
    Axios({
      method: "POST",
      url: "doctor/getDoctorDetails",
      data: profileGetData,
    })
      .then((response) => {
        debugger;
        setVar_Profiledata(response.data.data);
        console.log("profile detail;s", Var_Profiledata);
        setVar_ProfileImg(response.data.data[0].vendor_profile_path);
        setVar_SinceData(response.data.data[0].practiceSince);
        setVar_EmailData(response.data.data[0].email);
        setVar_nation(response.data.data[0].nationality);
        setVar_Self(response.data.data[0].selfDescription);
        setVar_Quali(response.data.data[0].qualification);
        setVar_WebsiteData(response.data.data[0].website);
        setVar_MobileData(response.data.data[0].mobile);
        setDOB(response.data.data[0].dob);
        setVar_Gender(response.data.data[0].gender);
        setVar_AddressData(response.data.data[0].address);
        const pharmaListData = response.data.data[0].pharma_list
          ? JSON.parse(response.data.data[0].pharma_list)
          : [];
        setVar_pharmdataSelect(
          pharmaListData.length > 0 ? pharmaListData[0] : defaultPharmacy
        );
        setLoading(false);
        if (pharmaListData.length > 0) {
          localStorage.setItem("pharmacy_id", pharmaListData[0].pharmacyId);
        } else {
          localStorage.setItem("pharmacy_id", "");
          getPharmacyDetials();
        }
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  };
  //Get Profile Details List end

  //Edit Profile Details List start
  const editDoctorProfile = () => {
    if (Var_AddressData_Ref.current == "") {
      notification.error({ message: "Enter Address" });
    } else if (Var_MobileData_Ref.current == "") {
      notification.error({ message: "Enter Mobile Number" });
    } else {
      setVar_ShowResults(!Var_ShowResults);
      setLoading(true);
      let profileEditData = {
        doctorId: localStorage.getItem("Doctor_id"),
        practiceSince: Var_SinceData_Ref.current,
        mobile: Var_MobileData_Ref.current,
        email: Var_EmailData_Ref.current,
        address: Var_AddressData_Ref.current,
        file_name: "testing",
        file_path: Var_ProfileImg_Ref.current?.split("?")[0],
        selfDescription: Var_Self_Ref.current,
        qualification: Var_Qauli_Ref.current,
        nationalityId: Var_Nation_Ref.current,
        website: Var_WebsiteData_Ref.current,
        dob: Var_DOB.current
      };

      Axios({
        method: "PUT",
        url: "doctor/editDoctorDetails",
        data: profileEditData,
      })
        .then((response) => {
          setVar_ShowImage(!Var_ShowImage);
          setVar_ProfileSuccessfully("Profile updated successfully.");
          setVar_IsShowSuccess(true);
          setTimeout(() => {
            setVar_IsShowSuccess(false);
            getDoctorProfile();
            handleOnClick("");
          }, 3000);
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          console.log(error);
        });
    }
  };
  //Edit Profile Details List end

  const getPharmacyDetials = () => {
    const payloadData = {
      Doctorid: localStorage.getItem("clinic_id"),
    };
    Axios({
      method: "POST",
      url: "doctor/getPharmadetails",
      data: payloadData,
    })
      .then((response) => {
        setVar_PharmacyDetials(response.data.data[0]);
        setVar_pharmdataSelect({
          vendor_name: "Select Pharmacy",
          pharmacyId: "",
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getPharmacyValue = (event) => {
    setVar_pharmdataSelect(event.target.value);
    setVar_pharmacyId(event.target.value.pharmacy_id);
  };
  const getSpeciality = () => {
    setLoading(true);
    let data = {
      doctor_id: localStorage.getItem("Doctor_id"),
    };
    Axios({
      method: "POST",
      url: "doctor/getSpecialitybyDoctor",
      data: data,
    }).then((response) => {
      setVar_GetServiceList(response.data.data);
      console.log("Var_GetServiceList", Var_GetServiceList_Ref.current)
      setVar_SpecialityValueId({ id: response.data.data[0].id, speciality: response.data.data[0].speciality })
      setLoading(false);

    })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  }

const editWebPremissions = () =>{
  setLoading(true);
  const requestData = {
    doctorId: localStorage.getItem("Doctor_id"),
    clinicId: localStorage.getItem("Clinic_id"),
    referralAccess: 0,
    prescriptionAccess: 0,
    reportAccess: 0,
    historyAccess: 0,
    availabilityAccess:0,
    postAccess:0,
    adAccess: 0,
    dealAccess:0,
    serviceAccess: checkboxValues.service_access == true ? 1 : 0,
    workinghrsAccess: checkboxValues.workinghrs_access == true ? 1 : 0,
    revenueAccess: checkboxValues.revenue_access == true ? 1 : 0,
    appointmentAccess:checkboxValues.appointment_access == true ? 1 :0,
  }
  console.log(requestData);
  console.log(checkboxValues);
  Axios({
    method :"POST",
    url:"doctor/updateClinicPermissions",
    data :requestData
  }).then((response) =>{
  setLoading(false);
  if(response.data.status == 1) {
    setVar_ProfileSuccessfully("Web Access updated successfully.");
    setVar_IsShowSuccess(true);
    getWebAccessDetials();
    setTimeout(()=>{
      setVar_IsShowSuccess(false);
    },3000)
  }else{
    setVar_ProfileSuccessfully(response.data.msg+".");
    setVar_IsShowSuccess(true);
    setTimeout(()=>{
    setVar_IsShowSuccess(false);
    },3000)
  }
  }).catch((error)=>{
  setLoading(false);
console.log(error);
  })
  
}

  const getSpecialityValue = (event) => {
    setVar_SpecialityValueId(event.target.value);
  }
const openWebAccess = () =>{
  setisshowWebAccess(!isshowWebAccess);
}
 const getCheckBoxValue = (event,value) =>{
  setcheckboxValues((prev)=>({
    ...prev,
    [value]:event.target.checked,
  }));
 }
  const insertDOB = (event) => {
    setDOB(event.target.value);
  }
  const formatDate = (dateString) => {
    const options = { year: '2-digit', month: 'short', day: '2-digit' };
    const date = new Date(dateString);
    return date.toLocaleDateString('en-GB', options).replace(',', '');
  }

  return (
    <div>
      {loading && (
        <div className="loader_body">
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100vh",
            }}
          >
            <CircularProgress />
          </Box>
        </div>
      )}

      <div>
        <HeaderMasterComponent />
      </div>
      {/* ================= Profile sub header start ================= */}
      {/* <div className="sub_header">
        <div className="sub_header_body">
          <div className="back_navigation" onClick={() => handleOnClick("/")}>
            <i className="fa fa-angle-left" aria-hidden="true"></i>
            <span>PROFILE</span>
          </div>
          <div className="flex_grow"></div>
          <div className="header_right_content">
            <h5>
              {Var_ShowResults ? (
                <p className="profile_edit" onClick={() => uploadImage()}>
                  Edit
                </p>
              ) : (
                <p className="profile_edit" onClick={() => editDoctorProfile()}>
                  Save
                </p>
              )}
            </h5>
          </div>
        </div>
      </div> */}
      <div>
        <div className="Navication-header">
          <div>
            <img src={BackIcon} onClick={() => handleOnClick("/")} />
            <label onClick={() => handleOnClick("/")}>Profile</label>
          </div>
        </div>
        {/* ================= Profile sub header end ================= */}

        {/* ================= Profile details View  start ================= */}
        <div className="workinghours_content">
          {/* ================= Profile details start ================= */}

          <div className="d-flex justify-content-center">
            {Var_Profiledata?.map((responseData, i) => (
              <Grid container key={i} className="profile_border_details">
                {/* <Grid item xs={3} className="logout_details">
                  <div
                    className="logout_button_details"
                    onClick={() => logoutsuccess("/")}
                  >
                    Logout
                    <img
                      style={{ paddingLeft: "5px", height: "17px" }}
                      src={logoutIcon}
                      alt=""
                    ></img>
                  </div>
                </Grid> */}

                <Grid container>
                  <Grid item xs={12} className="img_contain">
                    <div className="profile_upload_added">
                      <div>
                        <label htmlFor="file-upload" style={{ cursor: "pointer" }}>
                          {/* Profile Picture */}
                          <img
                            src={
                              Var_ProfileImg_Ref.current !== ""
                                ? Var_ProfileImg_Ref.current
                                : vector
                            }
                            className="profile_pic_new"
                            alt="Profile"
                          />
                          {/* Add Icon */}
                          <img
                            src={add_icon}
                            className="profile_upload_select"
                            aria-hidden="true"
                          />
                        </label>
                        <input
                          id="file-upload"
                          type="file"
                          className="profile_upload"
                          onChange={(e) => uploadDocument(e)}
                        />
                      </div>
                    </div>

                    <Grid item className="vendor_name_details">
                      <span className="profile_doctor_name_new">
                        {responseData.doctorName}
                      </span>
                      {/* <div>(Hospital Administrator)</div> */}
                    </Grid>
                  </Grid>
                </Grid>

                <Grid container className="speciality_email_details">
                  <Grid xs={4} className="coloumn_2details">
                    <div className="speciality_name_details">
                      Specialty<span className="star_details">*</span>
                    </div>
                    {/* <TextField
                      className="textfield_details"
                      id="outlined-basic"
                      placeholder="Email Address"
                      variant="outlined"
                      value={Var_EmailData_Ref.current}
                      // onChange={(e) => setVar_EmailData(e.target.value)}
                      readonly={true}
                      sx={{ m: 1, minHeight: 8, minWidth: 250 }}
                    /> */}

                    <Select
                      labelId="demo-select-small-label"
                      id="demo-select-small"
                      sx={{
                        padding: "8.5px 14px",
                        height: "40px",
                        minHeight: 15,
                        minWidth: "-webkit-fill-available",
                      }}
                      readOnly={true}
                      value={Var_SpecialityValueId || ''}  // Ensure value is an empty string when nothing is selected
                      onChange={(e) => getSpecialityValue(e)}
                      renderValue={(selectedValue) => {
                        if (!selectedValue || !selectedValue.speciality) {
                          return 'Select Speciality';
                        }
                        return selectedValue.speciality;
                      }}
                    >
                      {/* Placeholder option with an empty value */}
                      <MenuItem value="">
                        Select Speciality
                      </MenuItem>

                      {/* Map over the list of items */}
                      {Var_GetServiceList_Ref.current.map((item) => (
                        <MenuItem key={item.id} value={item}>
                          <Container>
                            <Card className="dropdown_card">
                              <Grid container>
                                <Grid item xs={9} className="ref_lab" align="start">
                                  <p className="dropdowntext">{item.speciality}</p>
                                </Grid>
                              </Grid>
                            </Card>
                          </Container>
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>

                  <Grid xs={4} className="coloumn_2details">
                    <div className="speciality_name_details">
                      Email Address<span className="star_details">*</span>
                    </div>
                    <TextField
                      className="textfield_details"
                      id="outlined-basic"
                      placeholder="Email Address"
                      variant="outlined"
                      value={Var_EmailData_Ref.current}
                      // onChange={(e) => setVar_EmailData(e.target.value)}
                      readonly={true}
                      sx={{ m: 1, minHeight: 8, minWidth: 250 }}
                    />
                  </Grid>

                  {/* {Var_Profiledata_Ref.current[0].pharma_list == null && (
                    <Grid item xs={3}>
                      <InputLabel
                        id="demo-select-small-label"
                        style={{ color: "black", fontWeight: "500" }}
                      >
                        Pharmacy
                      </InputLabel>
                      <FormControl fullWidth>
                        <Select
                          value={Var_pharmdataSelect}
                          size="small"
                          sx={{
                            height: "50px",
                            minWidth: "-webkit-fill-available",
                            borderRadius: "5px",
                          }}
                          onChange={(e) => getPharmacyValue(e)}
                          placeholder="Select Pharmacy"
                          renderValue={(selected) => (
                            <p className="selectPval">{selected.vendor_name}</p>
                          )}
                        >
                          <MenuItem
                            key={0}
                            value={{
                              vendor_type_id: "",
                              vendor_name: "Select Pharmacy",
                              vendor_profile_path: "",
                              pharmacy_id: "",
                            }}
                          ></MenuItem>
                          {Var_PharmacyDetials_Ref.current.map((item) => {
                            return (
                              <MenuItem key={item.pharmacy_id} value={item}>
                                {item.vendor_name}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </Grid>
                  )} */}

                  {/* {Var_Profiledata_Ref.current[0].pharma_list != null && (
                    <Grid item xs={4}>
                      <InputLabel
                        className="demo_special_name"
                        id="demo-select-small-label"
                        style={{ marginBottom: "10px" }}
                      >
                        Pharmacy
                      </InputLabel>
                      <TextField
                        className="Pharmacy_select_profile"
                        id="outlined-basic"
                        placeholder={
                          Var_pharmdataSelect_ref.current.vendor_name
                        }
                        size="small"
                        sx={{ minWidth: "-webkit-fill-available" }}
                        InputProps={{
                          readOnly: true,
                        }}
                      />
                    </Grid>
                  )} */}
                </Grid>

                <Grid container style={{ justifyContent: "space-around" }}>
                  <Grid xs={4} className="coloumn_2details">
                    <div className="speciality_name_details">
                      Date of Birth<span className="star_details">*</span>
                    </div>
                    <TextField
                      className="textfield_details"
                      id="outlined-basic"
                      placeholder="Date of Birth"
                      variant="outlined"
                      value={Var_DOB.current}
                      readonly={true}
                      sx={{ m: 1, minHeight: 8, minWidth: 250 }}
                    />
                  </Grid>

                  <Grid xs={4} className="coloumn_2details">
                    <div className="speciality_name_details">
                      Contact Number<span className="star_details">*</span>
                    </div>
                    <TextField
                      className="textfield_details"
                      id="outlined-basic"
                      placeholder="Contact Number"
                      variant="outlined"
                      value={Var_MobileData_Ref.current}

                      onChange={(e) => walkinMobileNumbetValidation(e)}
                      sx={{ m: 1, minHeight: 8, minWidth: 250 }}
                    />
                  </Grid>
                </Grid>

                <Grid container style={{ justifyContent: "space-around" }}>
                  <Grid xs={4} className="coloumn_2details">
                    <div className="speciality_name_details">
                      Gender<span className="star_details">*</span>
                    </div>
                    <TextField
                      className="textfield_details"
                      id="outlined-basic"
                      placeholder="Contact Number"
                      variant="outlined"
                      value={Var_Gender.current}
                      readonly={true}
                      sx={{ m: 1, minHeight: 8, minWidth: 250 }}
                    />
                  </Grid>

                  <Grid xs={4} className="coloumn_2details">
                    <div className="speciality_name_details">
                      Location<span className="star_details">*</span>
                    </div>
                    <TextField
                      className="textfield_details"
                      id="outlined-basic"
                      placeholder="Location"
                      variant="outlined"
                      value={Var_AddressData_Ref.current}
                      onChange={(e) => setVar_AddressData(e.target.value)}
                      sx={{ m: 1, minHeight: 8, minWidth: 250 }}
                    />
                  </Grid>
                </Grid>
                {/* <Grid container>
                  
                  <Grid xs={4} className="coloumn_1details"></Grid>
                </Grid> */}
                <Grid container className="save_details_new">
                  <Grid ClassName="save_profile_details">
                    <div
                      className="save_button_details"
                      onClick={() => editDoctorProfile()}
                    >
                      Save
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            ))}
            {/* </div>

        <div> */}
          </div>
        <div className="d-flex justify-content-center" style={{margin:"4rem"}}>
          <Grid container  className="profile_border_details">
          <div className="web_access_header" >
            <label>Web Access</label>
            {!isshowWebAccess &&
            <i className="fa fa-angle-down" aria-hidden="true" style={{cursor:"pointer"}} onClick={()=>openWebAccess()}></i>}
            {isshowWebAccess &&
            <i className="fa fa-angle-up" aria-hidden="true" style={{cursor:"pointer"}} onClick={()=>openWebAccess()}></i>}
          </div>
          {isshowWebAccess &&
          <>
            < Grid container style={{ justifyContent: "space-around" }}>
                <Grid item xs={4}>
                <div className="access_check_box">
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={checkboxValues.appointment_access}
                            onClick={(e) => getCheckBoxValue(e,"appointment_access")}
                          />
                        }
                        label="Appointments"
                        sx={{color:'Black'}}
                      />
                    </div>
                  </Grid>
                <Grid item xs={4}>
                <div className="access_check_box">
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={checkboxValues.workinghrs_access}
                            onClick={(e) => getCheckBoxValue(e,"workinghrs_access")}
                          />
                        }
                        label="Working Hours"
                        sx={{color:'Black'}}
                      />
                    </div>
                  </Grid>
            </Grid>
            <Grid container style={{ justifyContent: "space-around" }}>
                  <Grid item xs={4}>
                  <div className="access_check_box">
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={checkboxValues.service_access}
                            onClick={(e) => getCheckBoxValue(e,"service_access")}
                          />
                        }
                        label="Manage Service"
                        sx={{color:'Black'}}
                      />
                    </div></Grid>
                  <Grid item xs={4}>
                  <div className="access_check_box">
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={checkboxValues.revenue_access}
                            onClick={(e) => getCheckBoxValue(e,"revenue_access")}
                          />
                        }
                        label="Revenue"
                        sx={{color:'Black'}}
                      />
                    </div>
                    </Grid>
            </Grid>
            <Grid container className="save_details_new">
                  <Grid ClassName="save_profile_details">
                    <div
                      className="save_button_details"
                      onClick={() => editWebPremissions()}
                    >
                      Save
                    </div>
                  </Grid>
                </Grid>
            </>}
            </Grid>
        </div>
          {/* ================= Profile details end ================= */}

          {Var_IsShowSuccess && (
            <div className="popup-main">
              <div className="pushNotification_body">
                <div className="pushNotification_content">
                  <p className="notification_text_head">
                    <b>{Var_ProfileSuccessfully}</b>
                  </p>
                </div>
                <div className="progress-prt">
                  <div className="progress-bar"></div>
                </div>
              </div>
            </div>
          )}

          {/* ======================== Profile Success Model start======================================= */}
          {/* <Dialog
          fullWidth={true}
          open={Var_IsShowSuccess}
          onClose={setVar_IsShowSuccess}
          sx={{
            "& .MuiDialog-container": {
              "& .MuiPaper-root": {
                width: "100%",
                maxWidth: "300px",
                borderRadius: "20px",
              },
            },
          }}
        >
          <DialogContent>
            <div className="success_model_body">
              <div className="icon">
                <img src={successModelTickImg} alt="" />
              </div>
              <div className="title">success!</div>
              <div className="content">{Var_ProfileSuccessfully}</div>
              <div className="segment_center">
                <button
                  className="botton_Cart_Create"
                  onClick={() => isCloseProfileSuccessModel()}
                >
                  OK
                </button>
              </div>
            </div>
          </DialogContent>
        </Dialog> */}
          {/* ================================= */}
          {/* <Dialog
          fullWidth={true}
          open={Var_IsShowSuccess}
          onClose={setVar_IsShowSuccess}
          sx={{
            "& .MuiDialog-container": {
              "& .MuiPaper-root": {
                width: "100%",
                maxWidth: "300px",
                borderRadius: "20px",
              },
            },
          }}
        >
          <DialogContent>
            <div className="success_model_body">
              <div className="icon">
                <img src={successModelTickImg} alt="" />
              </div>
              <div className="title">success!</div>
              <div className="content">{Var_ProfileSuccessfully}</div>
              <div className="segment_center">
                <button
                  className="botton_Cart_Create"
                  onClick={() => successClose("/")}
                >
                  OK
                </button>
              </div>
            </div>
          </DialogContent>
        </Dialog> */}
          {/* ======================== Profile Success Model end======================================= */}

          {/* ===========================================sdfgdhtrfg======================== */}
          {/* <Dialog
          open={Var_IsShowDeletePostModel}
          onClose={setVar_IsShowDeletePostModel}
          sx={{
            "& .MuiDialog-container": {
              "& .MuiPaper-root": {
                width: "100%",
                maxWidth: "300px",
                borderRadius: "20px",
              },
            },
          }}
        >
          <DialogContent>
            <div className="success_model_body">
              <div className="icon">
                <img src={alertModelTickImg} alt="" />
              </div>
              <div className="title">alert!</div>
              <div className="content">Do you want to logout?</div>
              <div className="alert_btn_center">
                <button
                  className="alert_no_btn"
                  onClick={() => noDeletePostModel()}
                >
                  No
                </button>
                <button
                  className="alert_yes_btn"
                  onClick={() => yesDeletePostModel("/")}
                >
                  Yes
                </button>
              </div>
            </div>
          </DialogContent>
        </Dialog> */}

          {/* <Dialog
          fullWidth={true}
          open={Var_IsShowSuccessmodal}
          onClose={setVar_IsShowSuccessmodal}
          sx={{
            "& .MuiDialog-container": {
              "& .MuiPaper-root": {
                width: "100%",
                maxWidth: "300px",
                borderRadius: "20px",
              },
            },
          }}
        >
          <DialogContent>
            <div className="success_model_body">
              <div className="icon">
                <img src={successModelTickImg} alt="" />
              </div>
              <div className="title">success!</div>
              <div className="content">Logout successful!</div>
              <div className="segment_center">
                <button
                  className="botton_Cart_Create"
                  onClick={() => logoutsuccess("/")}
                >
                  OK
                </button>
              </div>
            </div>
          </DialogContent>
        </Dialog> */}

          {/* =============================================jyhgfdsr========================== */}
        </div>
      </div>
      {/* ================= Profile details View  end ================= */}

      {/* ================= Profile footer start ================= */}
      {/* <div className="footer">
        <FooterMaster />
      </div> */}
      {/* ================= Profile footer end ================= */}
    </div>
  );
}
export default ProfileComponent;
