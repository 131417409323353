// import React, { useState, useEffect } from "react";
// import { useHistory } from "react-router-dom";
// import Axios from "axios";
// import useStateRef from "react-usestateref";
// import LinearProgress from '@mui/material/LinearProgress';
// // Mui meterial component import
// import Grid from '@mui/material/Grid';
// import Card from '@mui/material/Card';
// import FormControl from "@mui/material/FormControl";
// import { MenuItem } from '@mui/material';
// import Button from '@mui/material/Button';
// import Select from "@mui/material/Select";
// import Dialog, { DialogProps } from '@mui/material/Dialog';
// import DialogContent from '@mui/material/DialogContent';
// import CircularProgress from '@mui/material/CircularProgress';
// import Box from '@mui/material/Box';


// import './ViewWorkingHours.css';
// import './WorkingHoursMaster.css';
// import FooterMaster from '../Dashboard/FooterMaster'
// import successModelTickImg from '../../assets/Web_Doctor_Icon_new_theme/Success.svg'
// import Visit from '../../assets/Web_Doctor_Icon_new_theme/Member.svg'
// import Online from '../../assets/Web_Doctor_Icon_new_theme/Online.svg'
// import Fasttrack from '../../assets/Web_Doctor_Icon_new_theme/fast_track.svg'
// import InclinicOnline from '../../assets/Web_Doctor_Icon_new_theme/inclinic.svg'
// import Walkin from '../../assets/Doctor_web_Icon/Walkin.svg'
// import alertimg from '../../assets/Web_Doctor_Icon_new_theme/alert.svg';
// import Service from "../../helpers/Service";
// import NotFound from "../../assets/Web_Doctor_Icon_new_theme/NotFound.svg"


// const ViewWorkingHoursComponent = () => {
//     let Var_History = useHistory();
//     let interval;
//     const [Var_WorkingDetailsData, setVar_WorkingDetailsData, Var_WorkingDetailsData_Ref] = useStateRef([]);
//     const [Var_DeleteWorkingList, setVar_DeleteWorkingList] = useStateRef([]);
//     const [Var_DaysCount, setVar_DaysCount] = useStateRef([]);
//     const [isshowsuccess, setishiddensuccess] = useState(false);
//     const [isshowalert, setisshowalert] = useState(false);
//     const [Var_DeletId, setVar_DeletId, Var_DeletId_Ref] = useStateRef("");
//     const [fullWidth, setFullWidth] = React.useState(true);
//     const [Var_Everyday, setVar_Everyday, Var_Everyday_Ref] = useStateRef(false); // defaultChecked
//     const [Var_Sunday, setVar_Sunday, Var_Sunday_Ref] = useStateRef(false); // defaultChecked
//     const [Var_Monday, setVar_Monday, Var_Monday_Ref] = useStateRef(false); // defaultChecked
//     const [Var_Tueday, setVar_Tueday, Var_Tueday_Ref] = useStateRef(false);; // defaultChecked
//     const [Var_Wedday, setVar_Wedday, Var_Wedday_Ref] = useStateRef(false);; // defaultChecked
//     const [Var_Thuday, setVar_Thuday, Var_Thuday_Ref] = useStateRef(false); // defaultChecked
//     const [Var_Friday, setVar_Friday, Var_Friday_Ref] = useStateRef(false); // defaultChecked
//     const [Var_Satday, setVar_Satday, Var_Satday_Ref] = useStateRef(false); // defaultChecked

//     const [Var_ServiceId, setVar_ServiceId, Var_ServiceId_Ref] = useStateRef();
//     const [Var_showFromTime, setVar_showFromTime, Var_showFromTime_Ref] = useStateRef("");
//     const [Var_showToTime, setVar_showToTime, Var_showToTime_Ref] = useStateRef("");
//     const [Var_Slot_Duration, setVar_Slot_Duration, Var_Slot_Duration_Ref] = useStateRef("");
//     const [appointmentTypeLists, setappointmentTypeLists] = React.useState([]);
//     const [Var_SpecialtyId, setVar_SpecialtyId, Var_SpecialtyId_Ref] = useStateRef(0);
//     const [Var_Servicedata, setVar_Servicedata, Var_Servicedata_Ref] = useStateRef([]);
//     const [Var_Service_Id, setVar_Service_Id, Var_Service_Id_Ref] = useStateRef("");
//     const [Var_DoctorDetailShow, setVar_DoctorDetailShow, Var_DoctorDetailShow_Ref] = useStateRef(null);
//     const [loading, setLoading] = useState(true);
//     const [isDisable, setisisDisable] = useState(false);
//     // initial function call start
//     useEffect(() => {
//         getworkingDetails();
//         setisisDisable(false);

//         return () => {
//             clearInterval(interval); // Cleanup the interval on component unmount
//           };

//         // getClinicWiseDoctor();
//     }, [])
//     // initial function call End

//     // navigattion to back start
//     const handleOnClick = (path) => {
//         Var_History.push("/dashboard" + path)
//     };
//     // navigattion to back End


//     // initial function Close start
//     const okClose = () => {
//         setishiddensuccess(!isshowsuccess);
//     }
//     // initial function Close End


//     // initial function ALert Close start
//     const noAlertClose = () => {
//         setisshowalert(!isshowalert);
//     }
//     // initial function ALert Close End


//     // initial Delete Function start
//     const deleteWorkinglists = (id) => {

//         setVar_DeletId(id)
//         setisshowalert(!isshowalert);
//     }
//     // initial Delete Function End


//     // initial Edit Function start
//     const editWorkinglists = (data) => {

//         handleOnClick("/workinghours/editworkinghours/" + data.id);
//     }
//     // initial Edit Function End

//     // ============================Get Working Details List Api Start==================================
//     const getworkingDetails = () => {

//         setLoading(true);
//         var DoctorServiceBySpecilaity = {
//             doctorId: localStorage.getItem("Doctor_id"),
//             clinicId: localStorage.getItem("Clinic_id"),
//             limit: 100,
//             pageno: 1
//         }

//         Axios({
//             method: "POST",
//             url: "doctor/getdocAppointmentSettings",
//             data: DoctorServiceBySpecilaity,
//         })
//             .then((response) => {


//                 let _response = response.data.data[0].details;
//                 setLoading(false);
//                 if (_response && _response.length > 0) {
//                     _response.forEach((item, index) => {
//                         if (item.day && item.day.length > 0) {
//                             let days = "";
//                             if (
//                                 item.day.includes("1") &&
//                                 item.day.includes("2") &&
//                                 item.day.includes("3") &&
//                                 item.day.includes("4") &&
//                                 item.day.includes("5") &&
//                                 item.day.includes("6") &&
//                                 item.day.includes("7")

//                             ) {

//                                 days = "All Days"
//                                 console.log(item.day);
//                             }
//                             else {

//                                 if (item.day.includes("1")) days = days + (days.length > 0 ? "," : "") + ' Sun';
//                                 if (item.day.includes("2")) days = days + (days.length > 0 ? "," : "") + ' Mon';
//                                 if (item.day.includes("3")) days = days + (days.length > 0 ? "," : "") + ' Tue';
//                                 if (item.day.includes("4")) days = days + (days.length > 0 ? "," : "") + ' Wed';
//                                 if (item.day.includes("5")) days = days + (days.length > 0 ? "," : "") + ' Thu';
//                                 if (item.day.includes("6")) days = days + (days.length > 0 ? "," : "") + ' Fri';
//                                 if (item.day.includes("7")) days = days + (days.length > 0 ? "," : "") + ' Sat';

//                             }
//                             item.days = days;
//                         }
//                         setLoading(false);
//                     });
//                     setLoading(false);
//                 }

//                 setVar_WorkingDetailsData(_response);

//                 countValidation();
//                 setLoading(false);

//                 clearInterval(interval);
//                 interval = setInterval(() => {
//                     getworkingDetails();
//                 }, 120000); // 1000ms interval

//             })
//             .catch((error) => {
//                 setLoading(false);
//                 console.log(error);
//             });
//     }

//     const countValidation = () => {
//         let workIngDays = [];
//         for (let i = 0; i < Var_WorkingDetailsData_Ref.current.length; i++) {
//             workIngDays.push(Var_WorkingDetailsData_Ref.current[i].days);
//         }
//         if (
//             Var_Sunday_Ref.current &&
//             Var_Monday_Ref.current &&
//             Var_Tueday_Ref.current &&
//             Var_Wedday_Ref.current &&
//             Var_Thuday_Ref.current &&
//             Var_Friday_Ref.current &&
//             Var_Satday_Ref.current
//         ) {
//             workIngDays.push(1, 2, 3, 4, 5, 6, 7);
//         }
//         else {
//             if (Var_Sunday_Ref.current) {
//                 workIngDays.push(1);
//             }

//             if (Var_Monday_Ref.current) {
//                 workIngDays.push(2);
//             }

//             if (Var_Tueday_Ref.current) {
//                 workIngDays.push(3);
//             }
//             if (Var_Wedday_Ref.current) {
//                 workIngDays.push(4);
//             }
//             if (Var_Thuday_Ref.current) {
//                 workIngDays.push(5);
//             }
//             if (Var_Friday_Ref.current) {
//                 workIngDays.push(6);
//             }
//             if (Var_Satday_Ref.current) {
//                 workIngDays.push(7);
//             }
//         }

//     }
//     // ============================Get Working Details List Api End==================================


//     // ============================Delete Working Details List Api Start=============================

//     const DeleteWorkingList = () => {
//         setisisDisable(true);
//         setisshowalert(!isshowalert)
//         setLoading(true);
//         var DeleteList = {
//             id: Var_DeletId_Ref.current
//         }
//         Axios({
//             method: "DELETE",
//             url: "clinic/deletedocAppointmentSettings",
//             data: DeleteList,
//         })
//             .then((response) => {
//                 setisisDisable(false);
//                 setishiddensuccess(!isshowsuccess);
//                 console.log(response.data.DeleteList);
//                 getworkingDetails();
//                 setLoading(false);

//             })
//             .catch((error) => {
//                 setisisDisable(false);
//                 setLoading(false);
//                 console.log(error);
//             });
//     }
//     // ============================Delete Working Details List Api End=============================


//     // ============================get clinic Wise DOctor Api Start=============================

//     const getClinicWiseDoctor = () => {

//         setLoading(true);
//         var data = {
//             clinic_id: localStorage.getItem("Clinic_id")
//         };
//         Axios
//             .post("clinic/getClinicWiseDoctor", data)
//             .then((response) => {

//                 var filteredData = response.data.data.filter(x => x.specialityid == localStorage.getItem("speciality_Id") && x.doctor_id == localStorage.getItem("Doctor_id"));
//                 setVar_DoctorDetailShow(filteredData[0].doctorName + ' ' + filteredData[0].specialityname);
//                 setLoading(false);
//             })
//             .catch((error) => {
//                 setLoading(false);
//                 console.log(error);
//             });
//     }
//     // ============================get clinic Wise DOctor Api End=============================

//     return (
//         <div>
//             {/* {loading &&
//                 <div className="loader_body">
//                     <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
//                         <CircularProgress />
//                     </Box>
//                 </div>
//             } */}
//             {loading &&
//                 <LinearProgress color="secondary" className="progressBar" sx={{ zIndex: '9999' }} />
//             }
//             {/* ================= WorkingHours view sub header start ================= */}
//             <div className="sub_header">
//                 <div className="sub_header_body">
//                     <div className="back_navigation"
//                         onClick={() => handleOnClick("/workinghours")}
//                     >
//                         <i class="fa fa-angle-left" aria-hidden="true"></i>
//                         <span>VIEW WORKING HOURS</span>
//                     </div>
//                     <div className="flex_grow"></div>
//                     <div className="header_right_contents">
//                         {/* <div>
//                             <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
//                                 <Select
//                                     labelId="demo-select-small-label"
//                                     id="demo-select-small"
//                                     SelectProps={{
//                                         renderValue: (p) => p
//                                     }}
//                                     value={Var_DoctorDetailShow_Ref.current}
//                                     readOnly={true}
//                                     renderValue={(p) => p}

//                                 >
//                                 </Select>
//                             </FormControl>

//                         </div> */}
//                         <div className="right_headers"></div>
//                     </div>
//                 </div>
//             </div>
//             {/* ================= WorkingHours View sub header End ================= */}


//             {/* ================= WorkingHours View list View start ================= */}
//             <div className='content_view'>
//                 {Var_WorkingDetailsData.length == 0 &&
//                     <div style={{  height:"70vh" }}>
//                     <p className="cancel_no_data"> <img src={NotFound} className="cancel_no_data_image" />No Data Found</p>
//                 </div>
//                 }
//                 {Var_WorkingDetailsData.map((responseData, i) => (
//                     <Card className='detail_list_view' key={i}>
//                         <Grid container>
//                             <Grid item xs='10' align='center'>
//                                 <div className='Detailtypes'>
//                                     <div className='Detailcont'>
//                                         <p className='small_txet view_small_txet splClsA'>Specialty</p>
//                                         <p className='text_lab splClsC'>{responseData.speciality_name}</p>
//                                     </div>
//                                     <div className='Detailcont'>
//                                         <p className='small_txet view_small_txet'>Slot Duration <span style={{ fontSize: '13px' }}>(hrs)</span></p>
//                                         <p className='text_lab'>{responseData.slot_duration}</p>
//                                     </div>
//                                     <div className='Detailcont'>
//                                         <p className='small_txet view_small_txet'>No. of Slots</p>
//                                         <p className='text_lab'>{responseData.no_of_slots}</p>
//                                     </div>
//                                     <div className='Detailcont'>
//                                         <p className='small_txet view_small_txet'>Working Hours</p>
//                                         <p className='text_lab' style={{ textTransform: 'lowercase' }}>{Service.conert_24Hto_12HTime(responseData.from_time)} to {Service.conert_24Hto_12HTime(responseData.to_time)}</p>
//                                     </div>
//                                 </div>
//                                 <div className='Detailtypes' style={{ marginTop: '7px' }}>
//                                     <div className='Detailcont'>
//                                         <p className='small_txet view_small_txet splClsA'>Service</p>
//                                         <p className='text_lab splClsC' >{responseData.service_name}</p>
//                                     </div>
//                                     <div className='Detailcont'>
//                                         <p className='small_txet view_small_txet'>Appointment Type</p>
//                                         <p className='text_lab' >{responseData.appointment_type}
//                                             <span>

//                                                 {
//                                                     responseData.appointment_type == "In-Clinic" && <> <img src={Visit} alt='a' className='specialtyimg' /></>
//                                                 }

//                                                 {
//                                                     responseData.appointment_type == "Online" && <> <img src={Online} alt='a' className='specialtyimg' />  </>
//                                                 }

//                                                 {
//                                                     responseData.appointment_type == "Fast Track" && <> <img src={Fasttrack} alt='a' className='specialtyimg' /> </>
//                                                 }

//                                                 {
//                                                     responseData.appointment_type == "In-Clinic/Online" && <> <img src={InclinicOnline} alt='a' className='specialtyimg' /> </>
//                                                 }
//                                                 {/* <img className="image_size" src={responseData.appointment_type == "In-Clinic" ? Visit : responseData.appointment_type == "Online" ? Online : responseData.appointment_type == "In-Clinic/Online" ? Visit : responseData.appointment_type == "Fast Track" ? Fasttrack : ""}></img> */}
//                                             </span>
//                                         </p>
//                                     </div>
//                                     <div className='Detailcont'>
//                                         <p className='small_txet view_small_txet'>Working Days</p>
//                                         <p className='text_lab'>{responseData.days}</p>
//                                     </div>
//                                     <div className='Detailcont'>
//                                     </div>
//                                 </div>
//                             </Grid>
//                             <Grid item xs='2' align='center' style={{ display: responseData.can_edit == 1 ? 'block' : 'none' }}>
//                                 <p className='Action'>Action</p>
//                                 <div className='Action_button' >
//                                     <Button
//                                         className="botton_Add" onClick={() => editWorkinglists(responseData)}>
//                                         Edit
//                                     </Button>
//                                     <Button
//                                         className="botton_Cancel" onClick={() => deleteWorkinglists(responseData.id)}>
//                                         Delete
//                                     </Button>
//                                 </div>
//                             </Grid>
//                         </Grid>
//                     </Card>
//                 ))}
//             </div>
//             {/* ================= WorkingHours View list View End ================= */}

//             {localStorage.getItem("FooterStatus") == 'A' && <div className="layout_footer footer">
//                 <FooterMaster />
//             </div>
//             }
//             {/* ================== Working hours Alert DeleteModel Start================== */}
//             <Dialog
//                 fullWidth={fullWidth}
//                 open={isshowalert}
//                 onClose={setisshowalert}
//                 sx={{
//                     "& .MuiDialog-container": {
//                         "& .MuiPaper-root": {
//                             width: "100%",
//                             maxWidth: "300px",
//                             // height:"330px",
//                             borderRadius: "20px",
//                         },
//                     },
//                 }} >
//                 <DialogContent>
//                     <div className="success_model_body">
//                         <div className="icon">
//                             <img src={alertimg} />
//                         </div>
//                         <div className="success_body_size_alert">ALERT!</div>
//                         <div className="content">Do you want to delete this working hours?</div>
//                         <div className="alert_btn_center">
//                             <button className="alert_no_btn " onClick={() => noAlertClose()}>No</button>
//                             <button className="alert_yes_btn " disabled = {isDisable}  onClick={() => DeleteWorkingList()}>Yes</button>
//                         </div>
//                     </div>
//                 </DialogContent>
//             </Dialog>
//             {/* ================== Working hours Alert DeleteModel End================== */}


//             {/* ================= Working Hours Success Model Start ================= */}
//             <Dialog
//                 fullWidth={fullWidth}
//                 open={isshowsuccess}
//                 onClose={setishiddensuccess}
//                 sx={{
//                     "& .MuiDialog-container": {
//                         "& .MuiPaper-root": {
//                             width: "100%",
//                             maxWidth: "300px",
//                             borderRadius: "20px",
//                         },
//                     },
//                 }}>
//                 <DialogContent>
//                     <div className="success_model_body">
//                         <div className="icon">
//                             <img src={successModelTickImg} />
//                         </div>
//                         <div className="title">SUCCESS!</div>
//                         <div className="content">Working Hours deleted successfully!</div>
//                         <div className="segment_center">
//                             <button className="botton_Cart_Create " onClick={() => okClose()}>OK</button>
//                         </div>
//                     </div>
//                 </DialogContent>
//             </Dialog>
//             {/* ================= Working Hours Success Model End ================= */}

//         </div>
//     )
// }
// export default ViewWorkingHoursComponent;


import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Axios from "axios";
import useStateRef from "react-usestateref";

// Mui meterial component import
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import FormControl from "@mui/material/FormControl";
import { MenuItem } from "@mui/material";
import Button from "@mui/material/Button";
import Select from "@mui/material/Select";
import Dialog, { DialogProps } from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";

import "./ViewWorkingHours.css";
import "./WorkingHoursMaster.css";
import FooterMaster from "../Dashboard/FooterMaster";
import Visit from "../../assets/Doctor_web_Icon/Visit.svg";
import Online from "../../assets/Doctor_web_Icon/Online.svg";
import Fasttrack from "../../assets/Doctor_web_Icon/Fasttrack.svg";
import InclinicOnline from "../../assets/Doctor_web_Icon/InclinicOnline.svg";
import Walkin from "../../assets/Doctor_web_Icon/Walkin.svg";
import HeaderMasterComponent from "../Header/HeaderMaster";

import successModelTickImg from "../../assets/Doctor_web_Icon/success.svg";
import alertimg from "../../assets/Doctor_web_Icon/Delete white Icon.svg";
import Service from "../../helpers/Service";
import NotFound from "../../assets/Doctor_web_Icon/NotFound.svg";
import BackIcon from "../../assets/Doctor_web_Icon/Group 31.svg";

const ViewWorkingHoursComponent = () => {
  let Var_History = useHistory();

  const [
    Var_WorkingDetailsData,
    setVar_WorkingDetailsData,
    Var_WorkingDetailsData_Ref,
  ] = useStateRef([]);
  const [Var_DeleteWorkingList, setVar_DeleteWorkingList] = useStateRef([]);
  const [Var_DaysCount, setVar_DaysCount] = useStateRef([]);
  const [isshowsuccess, setishiddensuccess] = useState(false);
  const [isshowalert, setisshowalert] = useState(false);
  const [Var_DeletId, setVar_DeletId, Var_DeletId_Ref] = useStateRef("");
  const [fullWidth, setFullWidth] = React.useState(true);
  const [Var_Everyday, setVar_Everyday, Var_Everyday_Ref] = useStateRef(false); // defaultChecked
  const [Var_Sunday, setVar_Sunday, Var_Sunday_Ref] = useStateRef(false); // defaultChecked
  const [Var_Monday, setVar_Monday, Var_Monday_Ref] = useStateRef(false); // defaultChecked
  const [Var_Tueday, setVar_Tueday, Var_Tueday_Ref] = useStateRef(false); // defaultChecked
  const [Var_Wedday, setVar_Wedday, Var_Wedday_Ref] = useStateRef(false); // defaultChecked
  const [Var_Thuday, setVar_Thuday, Var_Thuday_Ref] = useStateRef(false); // defaultChecked
  const [Var_Friday, setVar_Friday, Var_Friday_Ref] = useStateRef(false); // defaultChecked
  const [Var_Satday, setVar_Satday, Var_Satday_Ref] = useStateRef(false); // defaultChecked

  const [Var_ServiceId, setVar_ServiceId, Var_ServiceId_Ref] = useStateRef();
  const [Var_showFromTime, setVar_showFromTime, Var_showFromTime_Ref] =
    useStateRef("");
  const [Var_showToTime, setVar_showToTime, Var_showToTime_Ref] =
    useStateRef("");
  const [Var_Slot_Duration, setVar_Slot_Duration, Var_Slot_Duration_Ref] =
    useStateRef("");
  const [appointmentTypeLists, setappointmentTypeLists] = React.useState([]);
  const [Var_SpecialtyId, setVar_SpecialtyId, Var_SpecialtyId_Ref] =
    useStateRef(0);
  const [Var_Servicedata, setVar_Servicedata, Var_Servicedata_Ref] =
    useStateRef([]);
  const [Var_Service_Id, setVar_Service_Id, Var_Service_Id_Ref] =
    useStateRef("");
  const [
    Var_DoctorDetailShow,
    setVar_DoctorDetailShow,
    Var_DoctorDetailShow_Ref,
  ] = useStateRef(null);
  const [loading, setLoading] = useState(true);
  const [verification, setVerification] = useState(
    Var_History.location.state
      ? Var_History.location.state.verification
        ? Var_History.location.state.verification
        : false
      : false
  );
  const [isDisable, setisisDisable] = useState(false);
  const [itemsPerPage,setitemsPerPage,itemsPerPage_Ref] = useStateRef(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [Var_msgContent, setVar_msgContent, Var_msgContent_Ref] =
    useStateRef("");
  // initial function call start
  useEffect(() => {
    getworkingDetails();
    // getClinicWiseDoctor();
    setisisDisable(false);
    setitemsPerPage(getCardsPerRow('.card-grid'));

  }, []);
  // initial function call End

  function getCardsPerRow(containerSelector) {
    const container = document.querySelector(containerSelector);
  
    if (container) {
      // Get the computed style of the grid container
      const style = window.getComputedStyle(container);
  
      // Extract the values of grid-template-columns
      const gridColumnWidth = style.gridTemplateColumns.split(" ").length;
  
      return gridColumnWidth;
    }
  
    return 0;
  }

  // Calculate the number of pages

  const totalPages = Math.ceil(Var_WorkingDetailsData.length / itemsPerPage_Ref.current);

  const currentData = Var_WorkingDetailsData.slice(
    (currentPage - 1) * itemsPerPage_Ref.current,
    currentPage * itemsPerPage_Ref.current
  );

  // Change the page
  const goToPage = (page) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  // navigattion to back start
  const handleOnClick = (path) => {
    Var_History.push("/dashboard" + path);
  };
  // navigattion to back End

  // initial function Close start
  const okClose = () => {
    setishiddensuccess(!isshowsuccess);
  };
  // initial function Close End

  // initial function ALert Close start
  const noAlertClose = () => {
    setisshowalert(!isshowalert);
  };
  // initial function ALert Close End

  // initial Delete Function start
  const deleteWorkinglists = (id) => {
    setVar_DeletId(id);
    setisshowalert(!isshowalert);
  };
  // initial Delete Function End

  // initial Edit Function start
  const editWorkinglists = (data) => {
    handleOnClick("/workinghours/editworkinghours/" + data.id);
  };
  // initial Edit Function End

  // ============================Get Working Details List Api Start==================================
  const getworkingDetails = () => {
    setLoading(true);
    var DoctorServiceBySpecilaity = {
      doctorId: localStorage.getItem("Doctor_id"),
      clinicId: localStorage.getItem("Clinic_id"),
      limit: 100,
      pageno: 1,
    };

    Axios({
      method: "POST",
      url: "doctor/getdocAppointmentSettings",
      data: DoctorServiceBySpecilaity,
    })
      .then((response) => {
        let _response = response.data.data[0].details;
        setLoading(false);
        if (_response && _response.length > 0) {
          _response.forEach((item, index) => {
            if (item.day && item.day.length > 0) {
              let days = "";
              if (
                item.day.includes("1") &&
                item.day.includes("2") &&
                item.day.includes("3") &&
                item.day.includes("4") &&
                item.day.includes("5") &&
                item.day.includes("6") &&
                item.day.includes("7")
              ) {
                days = "All Days";
                console.log(item.day);
              } else {
                if (item.day.includes("1"))
                  days = days + (days.length > 0 ? "," : "") + " Sun";
                if (item.day.includes("2"))
                  days = days + (days.length > 0 ? "," : "") + " Mon";
                if (item.day.includes("3"))
                  days = days + (days.length > 0 ? "," : "") + " Tue";
                if (item.day.includes("4"))
                  days = days + (days.length > 0 ? "," : "") + " Wed";
                if (item.day.includes("5"))
                  days = days + (days.length > 0 ? "," : "") + " Thu";
                if (item.day.includes("6"))
                  days = days + (days.length > 0 ? "," : "") + " Fri";
                if (item.day.includes("7"))
                  days = days + (days.length > 0 ? "," : "") + " Sat";
              }
              item.days = days;
            }
            setLoading(false);
          });
          setLoading(false);
        }

        setVar_WorkingDetailsData(_response);

        countValidation();
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  };

  const countValidation = () => {
    let workIngDays = [];
    for (let i = 0; i < Var_WorkingDetailsData_Ref.current.length; i++) {
      workIngDays.push(Var_WorkingDetailsData_Ref.current[i].days);
    }
    if (
      Var_Sunday_Ref.current &&
      Var_Monday_Ref.current &&
      Var_Tueday_Ref.current &&
      Var_Wedday_Ref.current &&
      Var_Thuday_Ref.current &&
      Var_Friday_Ref.current &&
      Var_Satday_Ref.current
    ) {
      workIngDays.push(1, 2, 3, 4, 5, 6, 7);
    } else {
      if (Var_Sunday_Ref.current) {
        workIngDays.push(1);
      }

      if (Var_Monday_Ref.current) {
        workIngDays.push(2);
      }

      if (Var_Tueday_Ref.current) {
        workIngDays.push(3);
      }
      if (Var_Wedday_Ref.current) {
        workIngDays.push(4);
      }
      if (Var_Thuday_Ref.current) {
        workIngDays.push(5);
      }
      if (Var_Friday_Ref.current) {
        workIngDays.push(6);
      }
      if (Var_Satday_Ref.current) {
        workIngDays.push(7);
      }
    }
  };
  // ============================Get Working Details List Api End==================================

  // ============================Delete Working Details List Api Start=============================

  const DeleteWorkingList = () => {
    setisisDisable(true);
    setisshowalert(!isshowalert);
    setLoading(true);
    var DeleteList = {
      id: Var_DeletId_Ref.current,
    };
    Axios({
      method: "DELETE",
      url: "clinic/deletedocAppointmentSettings",
      data: DeleteList,
    })
      .then((response) => {
        setisisDisable(false);
        setishiddensuccess(true);
        setVar_msgContent("Working Hours deleted successfully.");
        setTimeout(() => {
          setishiddensuccess(false);
        }, 3000);
        getworkingDetails();
        setLoading(false);
      })
      .catch((error) => {
        setisisDisable(false);
        setLoading(false);
        console.log(error);
      });
  };
  // ============================Delete Working Details List Api End=============================

  // ============================get clinic Wise DOctor Api Start=============================

  const getClinicWiseDoctor = () => {
    setLoading(true);
    var data = {
      clinic_id: localStorage.getItem("Clinic_id"),
    };
    Axios.post("clinic/getClinicWiseDoctor", data)
      .then((response) => {
        var filteredData = response.data.data.filter(
          (x) =>
            x.specialityid == localStorage.getItem("speciality_Id") &&
            x.doctor_id == localStorage.getItem("Doctor_id")
        );
        setVar_DoctorDetailShow(
          filteredData[0].doctorName +
            "," +
            " " +
            filteredData[0].specialityname
        );
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  };
  // ============================get clinic Wise DOctor Api End=============================

  return (
    <div>
      {loading && (
        <div className="loader_body">
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100vh",
            }}
          >
            <CircularProgress />
          </Box>
        </div>
      )}
      <div>
        <HeaderMasterComponent />
      </div>

      <div className="Navication-header">
        <div>
          <img src={BackIcon} onClick={() => handleOnClick("/")} />
          <label onClick={() => handleOnClick("/")}>Working Hours</label>
        </div>
      </div>

      {/* ================= WorkingHours View list View start ================= */}
      <div className="workinghours_content">
        <div className="hours-sub-dsn">
          <div className="add-main">
            <div
              className="add-hours-prt"
              onClick={() => handleOnClick("/workinghours")}
            >
              <div className="add-hours-cld">
                <label className="add-icon-label">+</label>
              </div>
            </div>
          </div>
          {Var_WorkingDetailsData.length == 0 && (
            <div style={{ height: "70vh" }}>
              <p className="cancel_no_data">
                {" "}
                <img src={NotFound} className="cancel_no_data_image" />
                No Data Found
              </p>
            </div>
          )}
          <div>
            <div className="card-grid">
              {currentData.map((responseData, i) => (
                <div className="card" key={i}>
                  <div className="card-body">
                    <Grid container>
                      <Grid item xs="5">
                        <label className="sub-title">Specialty</label>{" "}
                      </Grid>
                      <Grid item xs="2" style={{ textAlign: "center" }}>
                        <label className="sub-title">:</label>
                      </Grid>
                      <Grid item xs="5">
                        <label className="sub-value">
                          {" "}
                          {responseData.speciality_name}
                        </label>
                      </Grid>
                    </Grid>
                    <Grid container>
                      <Grid item xs="5">
                        <label className="sub-title">Service</label>{" "}
                      </Grid>
                      <Grid item xs="2" style={{ textAlign: "center" }}>
                        <label className="sub-title">:</label>
                      </Grid>
                      <Grid item xs="5">
                        <label className="sub-value">
                          {responseData.service_name}
                        </label>
                      </Grid>
                    </Grid>
                    <Grid container>
                      <Grid item xs="5">
                        <label className="sub-title">Slot Duration</label>{" "}
                      </Grid>
                      <Grid item xs="2" style={{ textAlign: "center" }}>
                        <label className="sub-title">:</label>
                      </Grid>
                      <Grid item xs="5">
                        <label className="sub-value">
                          {responseData.slot_duration}
                        </label>
                      </Grid>
                    </Grid>
                    <Grid container>
                      <Grid item xs="5">
                        <label className="sub-title">Appointment Type</label>{" "}
                      </Grid>
                      <Grid item xs="2" style={{ textAlign: "center" }}>
                        <label className="sub-title">:</label>
                      </Grid>
                      <Grid item xs="5">
                        <label className="sub-value">
                          {responseData.appointment_type}
                        </label>
                      </Grid>
                    </Grid>
                    <Grid container>
                      <Grid item xs="5">
                        <label className="sub-title">No. of Slots</label>{" "}
                      </Grid>
                      <Grid item xs="2" style={{ textAlign: "center" }}>
                        <label className="sub-title">:</label>
                      </Grid>
                      <Grid item xs="5">
                        <label className="sub-value">
                          {responseData.no_of_slots}
                        </label>
                      </Grid>
                    </Grid>
                    <Grid container>
                      <Grid item xs="5">
                        <label className="sub-title">Working Days</label>{" "}
                      </Grid>
                      <Grid item xs="2" style={{ textAlign: "center" }}>
                        <label className="sub-title">:</label>
                      </Grid>
                      <Grid item xs="5">
                        <label className="sub-value">{responseData.days}</label>
                      </Grid>
                    </Grid>
                    <Grid container>
                      <Grid item xs="5">
                        <label className="sub-title">Working Hours</label>{" "}
                      </Grid>
                      <Grid item xs="2" style={{ textAlign: "center" }}>
                        <label className="sub-title">:</label>
                      </Grid>
                      <Grid item xs="5">
                        <label className="sub-value">
                          {Service.conert_24Hto_12HTime(responseData.from_time)}{" "}
                          - {Service.conert_24Hto_12HTime(responseData.to_time)}
                        </label>
                      </Grid>
                    </Grid>
                    <div
                      style={{
                        display: responseData.can_edit == 1 ? "block" : "none",
                      }}
                    >
                      <div className="action_btn_view">
                        <Button
                          className="botton_Add"
                          onClick={() => editWorkinglists(responseData)}
                        >
                          Edit
                        </Button>
                        <Button
                          className="botton_Cancel"
                          onClick={() => deleteWorkinglists(responseData.id)}
                        >
                          Delete
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            {/* Pagination Controls */}
            <Grid>
              <nav
                aria-label="Page navigation"
                className="pagination_list_show"
              >
                <ul className="pagination page_list_pagination">
                  <li
                    style={{ cursor: "pointer" }}
                    className={`page-item ${
                      currentPage === 1 ? "disabled" : ""
                    }`}
                  >
                    <button
                      className="page-link row_page_details"
                      onClick={() => goToPage(currentPage - 1)}
                    >
                      {"<"}
                    </button>
                  </li>
                  {Array.from({ length: totalPages }, (_, index) => (
                    <li
                      style={{ cursor: "pointer" }}
                      key={index}
                      className={`page-item ${
                        currentPage === index + 1 ? "active" : ""
                      }`}
                    >
                      <button
                        className="page-link row_page_details"
                        onClick={() => goToPage(index + 1)}
                      >
                        {index + 1}
                      </button>
                    </li>
                  ))}
                  <li
                    style={{ cursor: "pointer" }}
                    className={`page-item ${
                      currentPage === totalPages ? "disabled" : ""
                    }`}
                  >
                    <button
                      className="page-link row_page_details"
                      onClick={() => goToPage(currentPage + 1)}
                    >
                      {">"}
                    </button>
                  </li>
                </ul>
              </nav>
            </Grid>
          </div>
        </div>
      </div>
      {/* ================= WorkingHours View list View End ================= */}
      {/* ================== Working hours Alert DeleteModel Start================== */}
      <Dialog
        fullWidth={fullWidth}
        open={isshowalert}
        onClose={setisshowalert}
        className="confirm_model"
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              borderRadius: "20px",
            },
          },
        }}
      >
        <DialogContent>
          <div className="confirmation_model_body">
            <div className="image_division">
              <img className="image_icon" src={alertimg} />
            </div>
            <div className="confirm_header">Are you sure?</div>
            <div className="confirm_content">
              You want to delete this working hours.
            </div>
            <div className="confirm_btn">
              <button
                className="confirm_no_btn "
                onClick={() => noAlertClose()}
              >
                No, Cancel{" "}
              </button>
              <button
                className="confirm_yes_btn "
                disabled={isDisable}
                onClick={() => DeleteWorkingList()}
              >
                Yes, Delete
              </button>
            </div>
          </div>
        </DialogContent>
      </Dialog>
      {/* ================== Working hours Alert DeleteModel End================== */}

      {/* ================= Working Hours Success Model Start ================= */}
      {/* <Dialog
                fullWidth={fullWidth}
                open={isshowsuccess}
                onClose={setishiddensuccess}
                sx={{
                    "& .MuiDialog-container": {
                        "& .MuiPaper-root": {
                            width: "100%",
                            maxWidth: "300px",
                            borderRadius: "20px",
                        },
                    },
                }}>
                <DialogContent>
                    <div className="success_model_body">
                        <div className="icons">
                            <img src={successModelTickImg} />
                        </div>
                        <div className="title">SUCCESS!</div>
                        <div className="content">Working Hours deleted successfully!</div>
                        <div className="segment_center">
                            <button className="botton_Cart_Create " onClick={() => okClose()}>OK</button>
                        </div>
                    </div>
                </DialogContent>
            </Dialog> */}
      {isshowsuccess && (
        <div className="popup-main">
          <div className="pushNotification_body">
            <div className="pushNotification_content">
              <p className="notification_text_head">
                <b>{Var_msgContent_Ref.current}</b>
              </p>
            </div>
            <div className="progress-prt">
              <div className="progress-bar"></div>
            </div>
          </div>
        </div>
      )}
      {/* ================= Working Hours Success Model End ================= */}
    </div>
  );
};
export default ViewWorkingHoursComponent;
