import { useHistory, useParams } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { notification, Spin } from "antd";
import axios from "axios";
import useStateRef from "react-usestateref";
import moment from "moment";

// Mui meterial component import
import {
  InputLabel,
  FormControlLabel,
  Select,
  MenuItem,
  Container,
  Card,
  Grid,
  TextField,
  Button,
  FormControl,
  DialogContent,
  Dialog,
  Radio,
} from "@mui/material";
import FormHelperText from "@mui/material/FormHelperText";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import HeaderMasterComponent from "../Header/HeaderMaster";

import "./Report.css";
import FooterMaster from "../Dashboard/FooterMaster";
import Member from "../../assets/Doctor_web_Icon/Visit.svg";
import Fasttrack from "../../assets/Doctor_web_Icon/Fasttrack.svg";
import Online from "../../assets/Doctor_web_Icon/Online.svg";
import Walkin from "../../assets/Doctor_web_Icon/Walkin.svg";
import successModelTickImg from "../../assets/Doctor_web_Icon/success.svg";
import Service from "../../helpers/Service";
import closeicon from "../../assets/Doctor_Images/closeIcon.png";
import vector from "../../assets/Doctor_web_Icon/Vector.png";
import CryptoJS from "crypto-js";
import MaleIcon from "../../assets/Doctor_Images/male_user.png";
import FemaleIcon from "../../assets/Doctor_Images/female_user.png";
import commenticon from "../../assets/clinicIcon/Group 189.svg";
import prescriptionicon from "../../assets/clinicIcon/Group 17352.svg";
import reporticon from "../../assets/clinicIcon/Group 17353.svg";
import commenttype from "../../assets/clinicIcon/commentboxicon.svg";
import BackIcon from "../../assets/Doctor_Images/Group 31.svg";

const ReportComponent = () => {
  let Var_History = useHistory();
  let { patientId } = useParams();
  let { bookingId } = useParams();
  let { date } = useParams();
  const decryptionKey = "OneMomentLabDicomService"; // Same key used for encryption in Node.js
  // alert(patientId);
  const [fullWidth, setFullWidth] = useState(true);
  const [loading, setLoading] = useState(true);
  var [Var_DateApi, setVar_DateApi, dateRefapi] = useStateRef("");
  var [Var_AppointmentDetail, setVar_AppointmentDetail] = useState([]);
  var [Var_TotalCuont, setVar_TotalCuont] = useState("");
  var [Var_ReportsByClinic, setVar_ReportsByClinic] = useState([]);
  var [PatientInformation, setVar_PatientInformation, PatientInformation_Ref] =
    useStateRef([]);
  var [Var_PatientReport, setVar_PatientReport, Var_PatientReport_Ref] =
    useStateRef([]);
  var [Var_prescriptionDetails, setVar_prescriptionDetails] = useStateRef([]);
  var [Var_gettingComment, set_Var_gettingComment] = useStateRef([]);
  var [Var_addingComment, setVar_addingComment, Var_addingComment_Ref] =
    useStateRef("");
  var [Var_adding, setVar_adding, Var_adding_Ref] = useStateRef([]);
  var [Var_requriedComment, setVar_requriedComment] = useStateRef("");

  const [isOpenViewCommentModel, setisOpenViewCommentModel] = useState(false);
  var [Var_ViewMoreDetails, setVar_ViewMoreDetails, Var_ViewMoreDetails_Ref] =
    useStateRef("");
  const [isOpenCommentModel, setisOpenCommentModel] = useState(false);
  const [isOpenSuccessModel, setisOpenSuccessModel] = useState(false);
  const [isOpenvideoModel, setisOpenvideoModel] = useState(false);
  var [videoData, setvideoData] = useState("");
  const [staticVideoUrl, setstaticVideoUrl] = useState([
    {
      name: "xray",
      data: "https://onemoment.voyagerpacs.com/ZeroFP/LN10DxY-1m7GZd2p-iN",
    },
    {
      name: "mri",
      data: "https://onemoment.voyagerpacs.com/ZeroFP/LN1;kUKUTD8UsPz8fVL",
    },
    {
      name: "dna",
      data: "https://onemoment.voyagerpacs.com/ZeroFP/LN14yMEIwAmDsm-3Hf2",
    },
  ]);
  // const colors = [" #48BFE3", "#0BA95F", "#E3AC3E"];
  const lightColors = [" #48BFE3", "#0BA95F", "#E3AC3E"];

  const colors = ["#48BFE3", "#0BA95F", "#E3AC3E"];
  // const lightColors = ["lightblue", "lightgreen", "lightyellow"];
  const [isDisable, setisisDisable] = useState(false);
  const [Var_IsShowSuccess, setVar_IsShowSuccess] = React.useState(false);
  const [Var_SuccessModelText, setVar_SuccessModelText] = useState("");
  // initial function call start
  useEffect(() => {
    console.log("refresh check");
    console.log("Service.check", Service.check);
    setVar_DateApi(new Date().toLocaleDateString("fr-CA"));
    reportsByClinic();
    getPrescriptionReport();
    getAllAppointment();
    gettingReportComment();
    // insertReportComment();
    patientParticularReport();
    localStorage.setItem("booking_Id", "");
    localStorage.setItem("patient_Id", "");
    localStorage.setItem("Report_Date", "");
    setisisDisable(false);
  }, []);
  // initial function call End

  // navigattion to back start
  const navigateTo = (path) => {
    Var_History.push("/dashboard/appointments" + path);
  };
  // navigattion to back end

  // =============== Get All Appointment Start ==========================================
  const getAllAppointment = () => {
    // var date = date

    setLoading(true);
    var data = {
      clinic_id: localStorage.getItem("Clinic_id"),
      doctor_id: localStorage.getItem("Doctor_id"),
      patient_type: "",
      period: "day",
      search_date: date, //"2023-08-14",
      search_date_to: date, //"2023-08-14",
    };
    axios
      .post("doctor/getAppointmentList", data)
      .then((response) => {
        console.log("Appoinment", response.data.data[0].result);
        var filtereddata = response.data.data[0].result.filter(
          (x) => x.patient_id == patientId && x.booking_id == bookingId
        );
        setVar_AppointmentDetail(filtereddata);
        setVar_TotalCuont(response.data.data[0].result.length);
        console.log("appointment Result", filtereddata);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  };
  // =============== Get All Appointment End ==========================================
  // model controls start
  const okClose = () => {
    // setisOpenCommentModel(!isOpenCommentModel)
    // setisOpenSuccessModel(!isOpenSuccessModel)
    if (Var_addingComment == "") {
      // setVar_requriedComment("Field Required.")
    } else {
      setisOpenCommentModel(!isOpenCommentModel);
      insertReportComment();
    }
  };

  const AddComment = () => {
    setVar_addingComment("");
    setisOpenCommentModel(!isOpenCommentModel);
  };
  // model controls end
  // Reports By Clinic Start
  const reportsByClinic = () => {
    setLoading(true);
    var data = {
      patientId: patientId,
      doctorId: localStorage.getItem("Doctor_id"),
      clinicId: localStorage.getItem("Clinic_id"),
      frmreq: "clinic",
      dateBasedSorting: true,
      dateBasedSortingOrder: "DESC",
      limit: 100,
      pageno: 1,
    };
    axios
      .post("clinic/getReportsByClinic", data)
      .then((response) => {
        console.log("Reports By Clinic ", response.data.data[0].details);
        setVar_ReportsByClinic(response.data.data[0].details);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
    // setisOpenSuccessModel(!isOpenSuccessModel)
  };
  // Reports By Clinic End

  // Get Patient Particular Report start
  const patientParticularReport = () => {
    setLoading(true);
    var data = {
      book_date: date,
      clinic_id: Number(localStorage.getItem("Clinic_id")),
      doctor_id: Number(localStorage.getItem("Doctor_id")),
      patient_id: Number(patientId),
    };
    axios
      .post("doctor/getPatientParticularReport", data)
      .then((response) => {
        debugger;
        console.log("Patient Particular Report", response.data.data[0]);
        setVar_PatientReport(response.data.data[0].patient_report);
        setVar_PatientInformation(response.data.data[0].patient_info);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
    // setisOpenSuccessModel(!isOpenSuccessModel)
  };
  // Get Patient Particular Report End

  // Time conversion start

  const convertTo12HrFormat = (time24) => {
    var timeArr = time24.split(":");
    var hours = parseInt(timeArr[0]);
    var minutes = parseInt(timeArr[1]);
    var suffix = hours >= 12 ? "PM" : "AM";

    hours = hours % 12 || 12;
    var time12 =
      hours + ":" + (minutes < 10 ? "0" + minutes : minutes) + " " + suffix;

    return time12;
  };
  const timeFormat = (time) => {
    let datetime = new Date("1970-01-01T" + time + "Z");
    return datetime.toLocaleTimeString("en-US", {
      timeZone: "UTC",
      hour12: true,
      hour: "numeric",
      minute: "numeric",
    });
  };

  // Time conversion End

  const navigateToLabReportViewPage = (data) => {
    setisOpenvideoModel(true);
    const urlRegex = /^(https?|ftp):\/\/[^\s\/$.?#].[^\s]*$/;
    const decryptedText = urlRegex.test(data.test_result)
      ? data.test_result
      : decrypt(data.test_result);
    setvideoData(decryptedText);

    // let arr=staticVideoUrl.filter((obj)=> obj.name ==data.test_name.toLocaleLowerCase() )
    // if(arr.length>0)
    // {
    //     setvideoData(arr[0]['data'])
    // }
    // else
    // {
    //     setvideoData("")
    // }

    /* data.test_result */
    // setvideoData("https://onemoment.voyagerpacs.com/ZeroFP/LN1KBD4f5MRiAe2M9mL")

    /*  navigateTo("/reportview/" + patientId + "/" + data.test_time + "/" + 2);
          localStorage.setItem("Report_Date", date)
          localStorage.setItem("patient_Id", patientId)
          localStorage.setItem("booking_Id", bookingId)*/
  };

  const decrypt = (encryptedData) => {
    const parts = encryptedData.split(":");
    const iv = CryptoJS.enc.Hex.parse(parts[0]);
    const encrypted = CryptoJS.enc.Hex.parse(parts[1]);
    const cryptoInfo = CryptoJS.AES.encrypt(
      JSON.stringify(encrypted),
      decryptionKey
    );
    const decrypted = CryptoJS.AES.decrypt(
      { ciphertext: encrypted },
      CryptoJS.enc.Utf8.parse(decryptionKey),
      { iv: iv, mode: CryptoJS.mode.CTR, padding: CryptoJS.pad.NoPadding }
    ).toString(CryptoJS.enc.Utf8);

    return decrypted;
  };

  const navigateToClinicReportViewPage = (data) => {
    navigateTo("/reportview/" + data.patient_id + "/" + data.id + "/" + 3);
    localStorage.setItem("Report_Date", date);
    localStorage.setItem("patient_Id", patientId);
    localStorage.setItem("booking_Id", bookingId);
  };

  const navigateToPrescriptionReportViewPage = (data) => {
    navigateTo(
      "/prescription/" +
        patientId +
        "/" +
        data.doctor_booking_id +
        "/" +
        moment(data.appointment_date).format("YYYY-MM-DD") +
        "/" +
        1 +
        "/" +
        "toviewprescription"
    );
    localStorage.setItem("Report_Date", date);
    localStorage.setItem("patient_Id", patientId);
    localStorage.setItem("booking_Id", bookingId);
    localStorage.setItem("Prescription_Date", data.prescript_date);
  };
  // const navigateToPrescriptionReportViewPage = (data) => {
  //
  //     navigateTo("/reportview/" + 0 + "/" + moment(data.prescript_date).format("YYYY-MM-DD") + "/" + 1);
  //     localStorage.setItem("Report_Date", date)
  //     localStorage.setItem("patient_Id", patientId)
  //     localStorage.setItem("booking_Id", bookingId)
  // }

  const isCloseViewModel = () => {
    setisOpenViewCommentModel(!isOpenViewCommentModel);
  };

  const reportView = (value) => {
    setVar_ViewMoreDetails(value);
    setisOpenViewCommentModel(!isOpenViewCommentModel);
  };

  const isCloseModel = () => {
    setisOpenCommentModel(!isOpenCommentModel);
  };

  const isCloseModelsuccess = () => {
    setisOpenSuccessModel(!isOpenSuccessModel);
    setVar_addingComment("");
  };
  const isCloseVideoModel = () => {
    setisOpenvideoModel(!isOpenvideoModel);
  };
  // ====================get prescription details start==============

  const navigateToPrescriptionPage = () => {
    Service.check = "checking navigate data";

    navigateTo(
      "/prescription/" +
        patientId +
        "/" +
        bookingId +
        "/" +
        date +
        "/" +
        "1" +
        "/" +
        "toaddprescription"
    );
  };

  const navigateToUploadReportPage = () => {
    Service.check = "checking navigate data";

    navigateTo(
      "/uploadreport/" +
        patientId +
        "/" +
        bookingId +
        "/" +
        date +
        "/" +
        "touploadreport"
    );
  };

  const getPrescriptionReport = () => {
    // setLoading(true);
    var data = {
      doctorId: localStorage.getItem("Doctor_id"),
      patientId: patientId,
    };
    axios
      .post("doctor/getReportPrescriptionImage", data)
      .then((response) => {
        setVar_prescriptionDetails(response.data.data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  };

  // ====================get prescription details end==============

  const textConvert = (text) => {
    var textContainer = text;

    // Get the full text content of the <p> tag
    const fullText = textContainer;
    const truncatedText = fullText.slice(0, 17) + "...";
    return truncatedText;
    // }
  };

  const textConvertcommand = (text) => {
    var textContainer = text;

    // Get the full text content of the <p> tag
    const fullText = textContainer;
    const truncatedText = fullText.slice(0, 30) + "...";
    return truncatedText;
    // }
  };

  // =======================insert comment report==================
  const insertReportComment = () => {
    setisisDisable(true);
    // setLoading(true);
    var data = {
      doctor_id: localStorage.getItem("Doctor_id"),
      patient_id: patientId,
      comments: Var_addingComment_Ref.current,
    };
    axios
      .post("doctor/addReportComment", data)
      .then((response) => {
        setisisDisable(false);
        setVar_SuccessModelText("Comment added successfully.");
        setVar_IsShowSuccess(true);
        setTimeout(() => {
          setVar_IsShowSuccess(false);
          gettingReportComment();
          // setLoading(false);
        }, 1500);
      })
      .catch((error) => {
        setLoading(false);
        setisisDisable(false);
        console.log(error);
      });
  };
  // =======================insert comment report==================

  // =======================getting comment report start==================
  const gettingReportComment = () => {
    setLoading(true);
    var data = {
      doctor_id: localStorage.getItem("Doctor_id"),
      patient_id: patientId,
    };
    axios
      .post("doctor/getReportComment", data)
      .then((response) => {
        set_Var_gettingComment(response.data.data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  };
  // =======================getting comment report end==================
  const openViewComment = () => {};

  return (
    <div>
      {loading && (
        <div className="loader_body">
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100vh",
            }}
          >
            <CircularProgress />
          </Box>
        </div>
      )}
      <div>
        <div>
          {/* ================= Report sub header start ================= */}
          {/* <div className="sub_header">
                        <div className="sub_header_body">
                            <div className="back_navigation" onClick={() => navigateTo("")}>
                                <i className="fa fa-angle-left" aria-hidden="true"></i>
                                <span>REPORT</span>
                            </div>
                            <div className="flex_grow"></div>
                        </div>
                    </div> */}

          <div>
            <HeaderMasterComponent />
          </div>

          <div>
            <div className="Navication-header">
              <div>
                <img src={BackIcon} onClick={() => navigateTo("")} />
                <label onClick={() => navigateTo("")}>Report</label>
              </div>
            </div>

            {/* ================= Report sub header End ================= */}

            {/* ================= Report list View start ================= */}
            <div className="workinghours_content">
              <div>
                {/*=======================  Pesenti Profile start ================= */}

                <div>
                  {Var_AppointmentDetail.map((item) => (
                    <div
                      className="profile_details_prescription_new"
                      style={{ margin: "0px 15px" }}
                    >
                      <div className="profile_detail_container">
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <img
                            src={
                              item.file_name != "" &&
                              item.file_name != " " &&
                              item.file_name != null
                                ? item.file_name
                                : item.gender.toLowerCase() == "male"
                                ? MaleIcon
                                : FemaleIcon
                            }
                            className="profile_Details"
                            style={{ border: "1px solid var(--app-theme)" }}
                          />
                        </div>
                        <div>
                          <div className="detailsprofile appointment_page_detailsprofile">
                            <p className="name_details_new">Name</p>
                            <p className="profile_name">{item.name}</p>
                            {/* <p className='profile_age'>{item.age > 0 ? item.age + " " + "yrs," : ""} <span> {item.gender}</span></p> */}
                          </div>
                          <div className="detailsprofile appointment_page_detailsprofile">
                            <p className="name_details_new">Date</p>
                            <p className="profile_name">
                              {moment(item.appointment_date).format(
                                "DD-MMM-YY"
                              )}
                            </p>
                            {/* <p className='profile_age'>{item.age > 0 ? item.age + " " + "yrs," : ""} <span> {item.gender}</span></p> */}
                          </div>
                        </div>
                        <div>
                          <div className="detailsprofile appointment_page_detailsprofile">
                            <p className="name_details_new">Patient ID</p>
                            <p className="profile_name">000076354175345</p>
                            {/* <p className='profile_age'>{item.age > 0 ? item.age + " " + "yrs," : ""} <span> {item.gender}</span></p> */}
                          </div>
                          <div className="detailsprofile appointment_page_detailsprofile">
                            <p className="name_details_new">Time</p>
                            <p className="profile_name">
                              {item.appointment_time == null
                                ? 0
                                : convertTo12HrFormat(item.appointment_time)}
                            </p>
                            {/* <p className='profile_age'>{item.age > 0 ? item.age + " " + "yrs," : ""} <span> {item.gender}</span></p> */}
                          </div>
                        </div>
                      </div>

                      {/* <Grid container className="save_details_new_report">
                        <Grid ClassName="save_profile_details"></Grid>
                      </Grid> */}
                    </div>
                  ))}
                </div>

                <div className="comments_container px-1" align="center">
                  {/*=======================  Add new comment start ================= */}
                  <Grid className="prescription_details_start">
                    <div className="comment_details">
                      <u>Comm</u>ents
                    </div>
                    <div
                      className="save_button_details add_comment_details"
                      onClick={() => AddComment()}
                    >
                      Add Comment +
                    </div>
                  </Grid>

                  <div className="stepper-container command-container">
                    {Var_gettingComment.length > 0 && (
                      <div className="scrollable-stepper">
                        <div className="stepper-horizontal">
                          {Var_gettingComment.map((data, index) => (
                            <React.Fragment key={index}>
                              <div className="stepper-step">
                                <div
                                  className="report_name_command"
                                  onClick={() => reportView(data.comments)}
                                >
                                  {data.comments}
                                </div>
                                <div style={{ display: "flex" }}>
                                  <div className="stepper-circle">
                                    <img
                                      className="report_doctor_profile"
                                      src={commenticon}
                                      alt="Profile"
                                      onClick={() => reportView(data.comments)}
                                    />
                                  </div>

                                  {index < Var_gettingComment.length - 1 && (
                                    <div className="stepper-line_horizontal"></div>
                                  )}
                                </div>
                                <div className="stepper-card_comment">
                                  <p className="report_time">
                                    {moment(data.created_on).format(
                                      "DD-MMM-YY"
                                    )}
                                  </p>
                                </div>
                              </div>
                            </React.Fragment>
                          ))}
                        </div>
                      </div>
                    )}
                  </div>

                  {Var_gettingComment.length <= 0 && (
                    <Grid
                      container
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <Grid item>
                        <p className="nodata_found">
                          {" "}
                          No comments available for this patient.{" "}
                        </p>
                      </Grid>
                    </Grid>
                  )}
                </div>

                <div className="comments_container px-1" align="center">
                  {/*=======================  Add new comment start ================= */}
                  <Grid className="prescription_details_start">
                    <div className="comment_details">
                      <u>Presc</u>ription
                    </div>
                    <div
                      className="save_button_details add_comment_details"
                      onClick={() => navigateToPrescriptionPage()}
                    >
                      Add Prescription+
                    </div>
                  </Grid>
                  {/* <Grid
                    container
                    className="save_details_new_report prescrition_add_details_list"
                  >
                    <Grid ClassName="save_profile_details prescription_profile"></Grid>
                  </Grid> */}

                  <div className="stepper-container command-container">
                    {Var_prescriptionDetails.length > 0 && (
                      <div className="scrollable-stepper">
                        <div className="stepper-horizontal">
                          {Var_prescriptionDetails.map((data, index) => (
                            <React.Fragment key={index}>
                              <div className="stepper-step">
                                <p
                                  className="report_name_command_prescription"
                                  onClick={() =>
                                    navigateToPrescriptionReportViewPage(data)
                                  }
                                >
                                  Prescription ID : {data.id}
                                </p>
                                <div style={{ display: "flex" }}>
                                  <div className="stepper-circle">
                                    <img
                                      onClick={() =>
                                        navigateToPrescriptionReportViewPage(
                                          data
                                        )
                                      }
                                      className="report_doctor_profile"
                                      src={prescriptionicon}
                                      alt="Profile"
                                    />
                                  </div>

                                  {index <
                                    Var_prescriptionDetails.length - 1 && (
                                    <div className="stepper-line_horizontal"></div>
                                  )}
                                </div>
                                <div className="stepper-card_comment">
                                  {/* <p className="report_doctor_name">{data.vendor_name}</p> */}
                                  <p className="report_time">
                                    {moment(data.prescript_date).format(
                                      "DD-MMM-YY"
                                    )}
                                  </p>
                                  {/* <p className="report_name_command">{data.comments}</p> */}
                                </div>
                              </div>
                            </React.Fragment>
                          ))}
                        </div>
                      </div>
                    )}
                  </div>

                  {Var_prescriptionDetails.length <= 0 && (
                    <Grid container className="nodata-view">
                      <Grid item>
                        <p className="nodata_found">
                          {" "}
                          No prescription available for this patient.{" "}
                        </p>
                      </Grid>
                    </Grid>
                  )}
                </div>

                <div className="comments_container px-1" align="center">
                  {/*=======================  Add new comment start ================= */}
                  <Grid className="prescription_details_start">
                    <div className="comment_details">
                      <u>Clinic</u>report
                    </div>
                    <div
                      className="save_button_details add_comment_details"
                      onClick={() => navigateToUploadReportPage()}
                    >
                      Upload report +
                    </div>
                  </Grid>

                  {/* <Grid
                    container
                    className="save_details_new_report prescrition_add_details_list"
                  >
                    <Grid ClassName="save_profile_details prescription_profile">
                      <div
                        className="save_button_details add_comment_details"
                        onClick={() => navigateToUploadReportPage()}
                      >
                        Upload report +
                      </div>
                    </Grid>
                  </Grid> */}

                  <div className="stepper-container command-container">
                    {Var_ReportsByClinic.length > 0 && (
                      <div className="scrollable-stepper">
                        <div className="stepper-horizontal">
                          {Var_ReportsByClinic.map((data, index) => (
                            <React.Fragment key={index}>
                              <div className="stepper-step">
                                <p
                                  className="report_name_command_prescription"
                                  onClick={() =>
                                    navigateToClinicReportViewPage(data)
                                  }
                                >
                                  {data.report_name}
                                </p>
                                <div style={{ display: "flex" }}>
                                  <div className="stepper-circle">
                                    <img
                                      onClick={() =>
                                        navigateToClinicReportViewPage(data)
                                      }
                                      className="report_doctor_profile"
                                      src={reporticon}
                                      alt="Profile"
                                    />
                                  </div>

                                  {index < Var_ReportsByClinic.length - 1 && (
                                    <div className="stepper-line_horizontal"></div>
                                  )}
                                </div>
                                <div className="stepper-card_comment">
                                  <p className="report_time">
                                    {moment(data.uploaded_date).format(
                                      "DD-MMM-YY"
                                    )}
                                  </p>
                                </div>
                              </div>
                            </React.Fragment>
                          ))}
                        </div>
                      </div>
                    )}
                    {Var_ReportsByClinic.length <= 0 && (
                      <Grid container className="nodata-view">
                        <Grid item>
                          <p className="nodata_found">
                            No clinic report available for this patient.{" "}
                          </p>
                        </Grid>
                      </Grid>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* ================= Report list View start ================= */}
        </div>

        {/* <div className="layout_footer footer">
                    <FooterMaster />
                </div> */}

        {/* ====================== Comment Entery model start ====================== */}
        <Dialog
          fullWidth={fullWidth}
          open={isOpenCommentModel}
          onClose={setisOpenCommentModel}
          sx={{
            "& .MuiDialog-container": {
              "& .MuiPaper-root": {
                width: "100%",
                maxWidth: "94vh",
                borderRadius: "20px",
                position: "relative",
                overflow: "visible",
              },
            },
          }}
        >
          <DialogContent>
            <div style={{ height: "285px" }}>
              <div
                className="close_mark_contain"
                onClick={() => isCloseModel()}
              >
                <div style={{ fontSize: "18px", color: "var(--app-theme)" }}>
                  Add Comments
                </div>
                <img className="close_icon_contain" src={closeicon} />
              </div>

              <TextField
                placeholder="Comments"
                value={Var_addingComment}
                onChange={(event) => setVar_addingComment(event.target.value)}
                multiline
                rows={7}
                maxRows={4}
                sx={{
                  border: "1px solid #fff",
                  padding: "8.5px 14px",
                  height: "40px",
                  minHeight: 15,
                  minWidth: "-webkit-fill-available",
                }}
              />
            </div>

            <br />
            {Var_addingComment.length > 0 ? (
              <>
                <p className="requried_type"></p>
              </>
            ) : (
              <>
                {" "}
                <p className="requried_type">{Var_requriedComment}</p>
              </>
            )}

            <div className="success_model_body">
              <div className="segment_center AddButt">
                <button
                  className="botton_Cart_Create"
                  disabled={isDisable}
                  onClick={() => okClose()}
                >
                  Add
                </button>
              </div>
            </div>
          </DialogContent>
        </Dialog>

        {/* ====================== Comment Entery model End ====================== */}

        <Dialog
          fullWidth={fullWidth}
          open={isOpenSuccessModel}
          onClose={setisOpenSuccessModel}
          sx={{
            "& .MuiDialog-container": {
              "& .MuiPaper-root": {
                width: "100%",
                maxWidth: "300px",
                borderRadius: "20px",
              },
            },
          }}
        >
          <DialogContent>
            <div className="success_model_body">
              <div className="icon">
                <img src={successModelTickImg} />
              </div>
              <div className="title">success!</div>
              <div className="content">Comment added successfully!</div>
              <div className="segment_center">
                <button
                  className="botton_Cart_Create"
                  onClick={() => isCloseModelsuccess()}
                >
                  OK
                </button>
              </div>
            </div>
          </DialogContent>
        </Dialog>

        {/* Video popup modal start*/}

        <Dialog
          fullWidth={fullWidth}
          open={isOpenvideoModel}
          onClose={setisOpenvideoModel}
          className="video_popup"
          sx={{
            "& .MuiDialog-container": {
              "& .MuiPaper-root": {
                maxWidth: "100%",
                height: "100%",
                margin: "15px 0px",
                width: "100%",
                borderRadius: "20px",
              },
            },
          }}
        >
          <div className="dialog_header">
            <div className="flex_grow"></div>
            <div onClick={() => isCloseVideoModel()}>
              <img src={closeicon} />
            </div>
          </div>
          <DialogContent>
            <div style={{ height: "100%" }}>
              {videoData.includes(".jpeg", ".png", ".jpg") ? (
                <img
                  src={videoData}
                  style={{ height: "100%", width: "100%" }}
                  //sandbox="allow-same-origin allow-scripts"
                />
              ) : videoData.includes(".pdf") ? (
                <div
                  className="pdf_view_div"
                  style={{ height: "100%", textAlign: "center" }}
                >
                  <object
                    data={videoData}
                    type="application/pdf"
                    style={{ width: "75%", height: "100%", margin: "auto" }}
                  ></object>
                </div>
              ) : (
                <div style={{ height: "100%", width: "100%" }}>
                  <iframe src={videoData} className="video_iframe_div"></iframe>
                </div>
              )}
            </div>
          </DialogContent>
        </Dialog>

        {/* Video popup modal end*/}

        {/* View more modal start*/}
        <Dialog
          fullWidth={fullWidth}
          open={isOpenViewCommentModel}
          onClose={setisOpenViewCommentModel}
          sx={{
            "& .MuiDialog-container": {
              "& .MuiPaper-root": {
                width: "100%",
                maxWidth: "50%",
                borderRadius: "20px",
                minHeight: "55%",
              },
            },
          }}
        >
          <div className="dialog_header_rep">
            <div className="dialog_title_rep">VIEW COMMENTS</div>
            <div className="flex_grow"></div>
            <div onClick={() => isCloseViewModel()}>
              <img src={closeicon} />
            </div>
          </div>
          <DialogContent>
            <div className="command-text-container">
              <p style={{ margin: "1.5rem" }}>
                {Var_ViewMoreDetails_Ref.current}
              </p>
            </div>
          </DialogContent>
        </Dialog>
        {/* View more modal end*/}
        {Var_IsShowSuccess && (
          <div className="popup_main">
            <div className="success_Popup_body">
              <div className="success_model_content">
                <p className="success_content_text_head">
                  <b>{Var_SuccessModelText}</b>
                </p>
              </div>
              <div className="progress_prt">
                <div className="progress_bar"></div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
export default ReportComponent;
