// AUTHOR : Dinesh
// CR-DATE: 28-June-2023
// MOD-DATE: 19-July-2023
// DESCRIPTION: Reset Password Update work

import React, { Component, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { NavLink } from "react-router-dom";
import Axios from "axios";
import { notification, Spin } from "antd";
import LinearProgress from '@mui/material/LinearProgress';
import useStateRef from "react-usestateref";

// Mui meterial component import
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { Visibility, VisibilityOff } from "@material-ui/icons";


import "./Login.css";

import HomeImage from "../../assets/Images/log_im.svg";
import Eye from "../../images/eye.svg";
import Logo from "../../assets/Web_Doctor_Icon_new_theme/logo_crop.svg";
import tombutton from "../../assets/Images/tom_journey.svg";

function ResetPasswordComponent() {
    let Var_History = useHistory();

    const [Var_Email, setVar_Email] = useState("");
    const [Var_Password, setVar_Password, Var_Password_Ref] = useStateRef("");
    const [Var_UserId, setVar_UserId] = useState("");
    const [Var_UuId, setVar_UuId, Var_UuId_Ref] = useStateRef("");
    const [Var_ConfirmPassword, setVar_ConfirmPassword, Var_ConfirmPassword_Ref] = useStateRef("");
    const [Var_ConfirmPasswordError, setVar_ConfirmPasswordError] = useState(false);
    const [Var_PasswordError, setVar_PasswordError] = useState(false);
    const [Var_PasswordMatchError, setVar_PasswordMatchError] = useState(false);
    const [Var_Hidden, setVar_Hidden] = useState(true);
    const [Var_HiddenPassword, setVar_HiddenPassword] = useState(true);
    const [loading, setLoading] = useState(false);
    const [Uuid_Load, setUuid_Load] = useState(false);

    // initial function call start
    useEffect(() => {
        setVar_UuId(window.location.href.split("?")[1].split("/")[2]);
        if (Var_UuId_Ref.current && Uuid_Load == false) {
            validateUuid();
            setUuid_Load(true);
            console.log("Uuid_Load");
        }
    })
    // initial function call end

    // navigation method start
    const resetPassword = () => {
        Var_History.push("/forgot");
    };
    const navigateLogin = () => {
        Var_History.push("/");
    };
    // navigation method send

    //reset password validation method start
    const toggleshow = () => {
        setVar_Hidden(!Var_Hidden);
    }
    const toggleshowpassword = () => {
        setVar_HiddenPassword(!Var_HiddenPassword);
    }
    const iconStyle = {
        fontSize: '26px', // Change the size
        color: '#2F887C', // Change the color
        position: 'absolute',
        top: '68px',
        right: '13px',
        cursor: 'pointer',
      };
    const validateUuid = () => {
        try {
            setLoading(true);
            const host = window.location.host;
            const pathName = window.location.pathname.split("/")[1];
            // const uuId = window.location.href.split("?")[1].split("/")[2];
            const dataObj = {
                uuid: Var_UuId_Ref.current,
                host,
                pathname: pathName,
            };

            Axios({
                method: "POST",
                url: "admin/validate_uuid",
                data: dataObj,
            })
                .then((response) => {
                    if (response.data.status === 1) {
                        setVar_Email(response.data.data[0].email);
                        setVar_UserId(response.data.data[0].user_id);
                        setLoading(false);
                    } else {
                        notification.error({
                            message: response.data.msg,
                        });
                        this.resetPassword();
                        setLoading(false);
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        } catch (err) {
            setLoading(false);
            console.log("error", err);
        }
    };
    const checkValidation = () => {
        if (!Var_Password_Ref.current) {
            setVar_PasswordError(true);
        } else if (!Var_ConfirmPassword_Ref.current) {
            setVar_PasswordError(false);
            setVar_ConfirmPasswordError(true);
        } else if (Var_Password_Ref.current !== Var_ConfirmPassword_Ref.current) {
            setVar_PasswordError(false);
            setVar_ConfirmPasswordError(false);
            setVar_PasswordMatchError(true);
        } else {
            setVar_PasswordError(false);
            setVar_ConfirmPasswordError(false);
            setVar_PasswordMatchError(false);
            submitPassword();
        }
    }
    //reset password validation method end

    //reset password method start
    const submitPassword = () => {
        try {
            setLoading(true);
            const host = window.location.host;
            const pathName = window.location.pathname.split("/")[1];
            const dataObj = {
                email: Var_Email,
                uuid: Var_UuId_Ref.current,
                password: Var_Password_Ref.current,
                user_id: Var_UserId,
                host,
                pathname: pathName,
            };

            Axios({
                method: "POST",
                url: "admin/check_nd_reset_password",
                data: dataObj,
            })
                .then((response) => {
                    if (response.data.status === 1) {
                        notification.success({
                            message: response.data.msg,
                        });
                        navigateLogin();
                        setLoading(false);
                    } else {
                        notification.error({
                            message: response.data.msg,
                        });
                        resetPassword();
                        setLoading(false);
                    }
                })
                .catch((error) => {
                    setLoading(false);
                    console.log(error);
                });
        } catch (e) {
            console.log("Reset Password Submit Error: ", e);
        }
    }
    //reset password method end

    return (
        <div>
            {/* {loading &&
                <div className="loader_body">
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                        <CircularProgress />
                    </Box>
                </div>
            } */}
            {/* {loading &&
                <LinearProgress color="secondary" className="progressBar" sx={{ zIndex: '9999' }} />
            } */}

            

            {/* ================= Reset Password page start ================= */}

            <div className="login_BG">
                <div className="login_input_card">
                    <div>
                        <p className="welcome_name">Welcome Back</p>
                        <p className="doctor_name">Reset Password</p>
                        {/* <p className="login_name">Login To See Your Schedules</p> */}
                       
                        <div>
                            <div className="password_container">
                                <label className="password_input_label">Password</label><br />
                                <input className="password_input" type={Var_Hidden ? "password" : "text"}
                                    placeholder="Enter your password"
                                    name="password" autoComplete="true" value={Var_Password}
                                    onChange={(e) => setVar_Password(e.target.value)}></input>
                                {Var_Hidden ? <VisibilityOff onClick={toggleshow} style={iconStyle} /> : <Visibility onClick={toggleshow} style={iconStyle} />}
                                <div style={{display: Var_PasswordError ? "block" : "none",color: "red",textAlign: "left",marginTop: "5px", fontWeight: "bolder"}}>
                                     Please Enter Password
                                </div>
                            </div>
                            <div className="password_container">
                                <label className="password_input_label">Confirm Password</label><br />
                                <input className="password_input" type={Var_HiddenPassword ? "password" : "text"}
                                    placeholder="Enter your confirm password"
                                    name="password" autoComplete="true" value={Var_ConfirmPassword}
                                    onChange={(e) => setVar_ConfirmPassword(e.target.value)}></input>
                                {Var_HiddenPassword ? <VisibilityOff onClick={toggleshowpassword} style={iconStyle} /> : <Visibility onClick={toggleshowpassword} style={iconStyle} />}
                                <div  style={{display: Var_ConfirmPasswordError ? "block" : "none", color: "red",textAlign: "left", marginTop: "5px", fontWeight: "bolder"}}>
                                    Please Enter Confirm Password
                                </div>
                            </div>
                            {Var_PasswordMatchError && <div style={{display: Var_PasswordMatchError ? "block" : "none",color: "red",textAlign: "left",marginTop: "5px", fontWeight: "bolder"}}>
                                Invalid Confirm Password
                            </div>}
                            <div className="login_button_container">
                                <button className="login_button" onClick={() => checkValidation()}>
                                    Submit
                                </button>
                            </div>
                            <div className="login_button_container">
                                <button className="cancel_button" onClick={() => navigateLogin("/")}>
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* ================= Reset Password page end ================= */}
            

        </div>
    )
}
export default ResetPasswordComponent;